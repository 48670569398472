import React from 'react';

import * as NEA from 'fp-ts/NonEmptyArray';
import { ActiveFilter as ActiveFilterType, Filter } from '../../model';

import * as Styled from './ActiveFilter.styles';

interface ActiveFilterItemProps<F extends Filter> {
  activeFilter: ActiveFilterType<F>;
  onDelete: (fn: (filter: F) => F) => void;
}

function ActiveFilterItem<F extends Filter>({ activeFilter, onDelete }: ActiveFilterItemProps<F>) {
  const handleDelete = () => onDelete(activeFilter.onDelete);

  return (
    <Styled.ActiveFilterItem>
      {activeFilter.title}
      <Styled.ActiveFilterDeleteButton onClick={handleDelete} />
    </Styled.ActiveFilterItem>
  );
}

interface ActiveFilterProps<F extends Filter> {
  activeFilter: NEA.NonEmptyArray<ActiveFilterType<F>>;
  onDeleteFilter: (fn: (filter: F) => F) => void;
  onDeleteAllFilter: () => void;
}

function ActiveFilter<F extends Filter>({ activeFilter, onDeleteFilter, onDeleteAllFilter }: ActiveFilterProps<F>) {
  return (
    <Styled.ActiveFilterContainer>
      {activeFilter.map((filter, i) => (
        <ActiveFilterItem key={i} activeFilter={filter} onDelete={onDeleteFilter} />
      ))}

      {activeFilter.length > 1 && (
        <Styled.ActiveFilterDeleteAll>
          Tout supprimer
          <Styled.ActiveFilterDeleteButton onClick={onDeleteAllFilter} />
        </Styled.ActiveFilterDeleteAll>
      )}
    </Styled.ActiveFilterContainer>
  );
}

export default ActiveFilter;
