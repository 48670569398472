import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css, FontSizeKey, FontWeightKey } from 'styled-components';
import { fontSize, fontWeight, primaryColor } from '../utils';
import { shouldForwardPropHelper } from '../utils/props';

export const Link = styled(ReactRouterLink).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    shouldForwardPropHelper(prop, defaultValidatorFn, ['size', 'weight', 'underline']),
})<{
  size?: FontSizeKey;
  weight?: FontWeightKey;
  underline?: boolean;
}>`
  padding: 0;
  background: none;
  border: none;
  font-family: inherit;
  font-weight: ${props => fontWeight[props.weight ?? 'semiBold']};
  color: ${primaryColor()};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  transition: color 0.15s linear;
  cursor: pointer;
  outline: none;

  ${props =>
    props.size &&
    css`
      font-size: ${fontSize[props.size]};
    `};

  &:hover {
    color: ${primaryColor(600)};
  }
`;
