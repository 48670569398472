import React, { FC } from 'react';
import * as Styled from './LoginPage.styles';

import backgroundImage from '@assets/icons/auth/background.svg';
import byCitymagLogo from '@assets/logo/by-citymag.svg';
import LoginForm from './login/LoginForm';
import Seo from '@shared/modules/seo/Seo';

const LoginPage: FC = () => {
  return (
    <Styled.LoginPageContainer>
      <Seo title="Se connecter" />

      <Styled.LoginPageLeft>
        <Styled.LoginPageLeftHelp href="mailto:support@citymag.info" />
        <Styled.LoginPageLeftContent>
          <Styled.LoginPageLeftLogo />

          <Styled.LoginPageLeftDescriptionContainer>
            <Styled.LoginPageLeftImage src={backgroundImage} alt="Background citymag pro" height="auto" />

            <Styled.LoginPageLeftDescription>
              <img src={byCitymagLogo} alt="Citymag" />
              <Styled.LoginPageLeftDescriptionText>
                Scannez les E-billets de vos évènements.
              </Styled.LoginPageLeftDescriptionText>
            </Styled.LoginPageLeftDescription>
          </Styled.LoginPageLeftDescriptionContainer>

          <Styled.LoginPageLeftLinkContainer>
            <Styled.LoginPageLeftLink href="https://citymag.info" target="_blank" rel="noopener noreferrer">
              Découvrir les avantages de la solution Citymag
            </Styled.LoginPageLeftLink>
          </Styled.LoginPageLeftLinkContainer>
        </Styled.LoginPageLeftContent>
      </Styled.LoginPageLeft>

      <Styled.LoginPageRight>
        <Styled.LoginPageRightForm>
          <Styled.LoginPageRightFormContent>
            <LoginForm />
          </Styled.LoginPageRightFormContent>
        </Styled.LoginPageRightForm>
      </Styled.LoginPageRight>
    </Styled.LoginPageContainer>
  );
};

export default LoginPage;
