import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { backgroundColor, mediaMax } from '../utils';
import { renderNullable } from '@shared/utils/render';
import { Title } from './Title';

export const Segment = styled.div<{ noPadding?: boolean; hideOverflow?: boolean }>`
  padding: ${props => (props.noPadding ? '0' : '30px 35px')};
  background: ${backgroundColor()};
  border-radius: 20px;
  overflow: ${props => (props.hideOverflow ? 'hidden' : 'unset')};

@media screen and (${mediaMax.tablet}) {
  padding: ${props => (props.noPadding ? '0' : '15px')}
`;

const FormSegmentContainer = styled.div`
  &:not(:first-child) {
    padding-top: 25px;
  }

  > h3:first-child {
    padding-bottom: 10px;
  }
`;

export interface FormSegmentProps {
  title?: string;
  className?: string;
}

export const FormSegment: FC<PropsWithChildren<FormSegmentProps>> = ({ title, className, children }) => (
  <FormSegmentContainer className={className}>
    {renderNullable(title, title => (
      <Title as="h3" level="h3" color="black">
        {title}
      </Title>
    ))}
    <Segment>{children}</Segment>
  </FormSegmentContainer>
);
