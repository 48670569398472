import React, { FC } from 'react';
import * as Styled from './EventDetailControlBottom.styles';
import { Button, Link } from '@styles/shared';
import { usePlatform } from '@modules/descriptor/context';
import { CityMagPlatform, IOSScanResultLink, IOSScanSuccessPayload } from '@shared/modules/platform/model';
import EventDetailControlScan from '@modules/events/components/detail/control/scan/EventDetailControlScan';
import { useHashedModal } from '@shared/components/modal/hooks';
import { ScanLocalResult } from '@modules/events/model';
import * as O from 'fp-ts/Option';
import * as IO from 'fp-ts/IO';
import { pipe } from 'fp-ts/function';
import { useIOSEvent } from '@shared/modules/platform/hooks';
import { useSummaryContext } from '@modules/events/components/detail/control/context';
import { getScanDisplayDateLast } from '@modules/events/components/detail/utils';

interface EventDetailControlBottomProps {
  handleScanSuccess: (result: string) => void;
  resetLastResult: IO.IO<void>;
  loading: boolean;
  lastResult: O.Option<ScanLocalResult>;
}

const EventDetailControlBottom: FC<EventDetailControlBottomProps> = ({
  handleScanSuccess,
  resetLastResult,
  loading,
  lastResult,
}) => {
  const [isOpen, openModal, closeModal] = useHashedModal('scan');
  const platform = usePlatform();
  const { summary } = useSummaryContext();

  useIOSEvent<IOSScanSuccessPayload>('scanSuccessful', ({ code }) => {
    handleScanSuccess(code);
    openModal();
  });

  const last = pipe(
    getScanDisplayDateLast(summary.event.date),
    O.fold(
      () => '',
      last => `Début ${last}`,
    ),
  );

  const handleScanRequestClose = pipe(
    closeModal,
    IO.chain(() => resetLastResult),
  );

  return (
    <>
      <Styled.EventDetailControlBottomContainer>
        <Button as={Link} to="bookings" level="secondary">
          Réservations
        </Button>
        {platform === CityMagPlatform.IOS ? (
          <Button as="a" href={`${IOSScanResultLink}?total=${summary.total}&scanned=${summary.scanned}&date=${last}`}>
            Scanner
          </Button>
        ) : (
          <Button onClick={openModal}>Scanner</Button>
        )}
      </Styled.EventDetailControlBottomContainer>
      <EventDetailControlScan
        lastResult={lastResult}
        onSuccess={handleScanSuccess}
        isOpen={isOpen}
        onRequestClose={handleScanRequestClose}
        loading={loading}
        handleReloadScan={resetLastResult}
      />
    </>
  );
};
export default EventDetailControlBottom;
