import { HttpStatusCode } from '@core/http';

export const getErrorMessageFromStatus = (status: HttpStatusCode) => {
  switch (status) {
    case HttpStatusCode.FORBIDDEN:
      return "Ce compte n'existe pas. Veuillez utiliser le compte associé à votre accès administrateur Citymag pour accéder à vos évènements.";
    default:
      return 'Une erreur technique est survenue';
  }
};
