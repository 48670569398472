import * as IOE from 'fp-ts/IOEither';
import * as IO from 'fp-ts/IO';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { useEffect, useState } from 'react';
import { sequenceT } from 'fp-ts/Apply';

// sensitivity = how many pixels to scroll
export const useAnimateRefresh = (
  scrollElement: HTMLElement,
  onAction: IO.IO<void>,
  sensitivity: number,
): [number, IO.IO<void>] => {
  const [progress, setProgress] = useState(0);
  const [verticalTouchScrollStart, setVerticalTouchScrollStart] = useState(0);
  const [horizontalTouchScrollStart, setHorizontalTouchScrollStart] = useState(0);

  const touchScroll = (e: TouchEvent) =>
    pipe(
      e.touches.item(0),
      O.fromNullable,
      IOE.fromOption(() => 'no touches detected'),
      IOE.chain(
        IOE.fromPredicate(
          () => scrollElement.scrollTop <= 0,
          () => 'scroll not at top',
        ),
      ),
      IOE.map(({ clientX, clientY }) => ({
        xDiff: horizontalTouchScrollStart - clientX,
        yDiff: verticalTouchScrollStart - clientY,
      })),
      IOE.chain(
        IOE.fromPredicate(
          ({ yDiff, xDiff }) => Math.abs(yDiff) > Math.abs(xDiff),
          () => 'horizontal touch scroll',
        ),
      ),
      IOE.chain(
        IOE.fromPredicate(
          ({ yDiff }) => yDiff < 0,
          () => 'vertical up touch scroll',
        ),
      ),
      IOE.map(({ yDiff }) => setProgress(Math.abs(yDiff) / sensitivity)),
    )();

  const touchStart = (e: TouchEvent) =>
    pipe(
      e.touches.item(0),
      O.fromNullable,
      IOE.fromOption(() => 'no touches detected'),
      IOE.chainIOK(({ clientX, clientY }) =>
        sequenceT(IO.Apply)(
          () => setHorizontalTouchScrollStart(clientX),
          () => setVerticalTouchScrollStart(clientY),
        ),
      ),
    )();

  const touchEnd = () =>
    pipe(
      progress,
      IOE.fromPredicate(
        progress => progress >= 1,
        () => 'not enough touch scroll',
      ),
      IOE.bimap(
        () => setProgress(0),
        sequenceT(IO.Apply)(() => setProgress(1), onAction),
      ),
    )();

  const resetProgress = () => setProgress(0);

  useEffect(() => {
    scrollElement.addEventListener('touchmove', touchScroll, { passive: true });
    scrollElement.addEventListener('touchstart', touchStart, { passive: true });
    scrollElement.addEventListener('touchend', touchEnd, { passive: true });

    return () => {
      scrollElement.removeEventListener('touchmove', touchScroll);
      scrollElement.removeEventListener('touchstart', touchStart);
      scrollElement.removeEventListener('touchend', touchEnd);
    };
  });

  return [progress, resetProgress];
};
