import styled, { css } from 'styled-components';

export const TouchRefreshContainer = styled.div<{ loading: boolean }>`
  position: relative;
  width: 100%;
  transition: height 0.1s ease-out;
  overflow: hidden;

  ${({ loading }) =>
    loading &&
    css`
      > img {
        animation-name: rotating;
      }
    `}

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: translate(-50%, -50%);
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotating {
    from {
      transform: translate(-50%, -50%) rotate(0);
    }

    to {
      transform: translate(-50%, -50%) rotate(1turn);
    }
  }
`;
