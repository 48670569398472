import React, { FC, useState, useEffect } from 'react';
import * as O from 'fp-ts/Option';
import * as IO from 'fp-ts/IO';
import * as Styled from './EventDetailControlScanWeb.styles';
import { renderOptional } from '@shared/utils/render';
import EventDetailControlScanResult from '@modules/events/components/detail/control/scan/result/EventDetailControlScanResult';
import FullModal from '@shared/components/modal/full/FullModal';
import loaderIcon from '@assets/icons/shared/loader.svg';
import { ScanLocalResult } from '@modules/events/model';
import { useSummaryContext } from '@modules/events/components/detail/control/context';
import EventDetailControlScanExit from '@modules/events/components/detail/control/scan/exit/EventDetailControlScanExit';
import EventDetailControlScanProgress from '@modules/events/components/detail/control/scan/progress/EventDetailControlScanProgress';
import { QrReader } from '@shared/modules/scanner/components/QrReader';
import { ViewFinder } from '@shared/modules/scanner/components/view-finder/ViewFinder';
import { Result as ZxingResult } from '@zxing/library';
import { sequenceT } from 'fp-ts/Apply';
import { useCustomPermission } from '@shared/modules/permissions/hooks';
import { pipe } from 'fp-ts/function';
import * as RD from 'fp-ts-remote-data';
import EventDetailControlScanPermissions from '@modules/events/components/detail/control/scan/web/permissions/EventDetailControlScanPermissions';

interface EventDetailControlScanWebProps {
  isOpen: boolean;
  loading: boolean;
  lastResult: O.Option<ScanLocalResult>;
  handleScanSuccess: (result: string) => void;
  handleReloadScan: IO.IO<void>;
  onRequestClose: IO.IO<void>;
}

export type PermissionDisplay = PermissionState | 'loading' | 'not-found';

const EventDetailControlScanWeb: FC<EventDetailControlScanWebProps> = ({
  isOpen,
  loading,
  lastResult,
  handleScanSuccess,
  handleReloadScan,
  onRequestClose,
}) => {
  const { summary, refreshSummary } = useSummaryContext();
  const permission = useCustomPermission('camera');
  const [permissionState, setPermissionState] = useState<PermissionDisplay>('loading');

  const handleScanRequestClose = sequenceT(IO.Apply)(onRequestClose, refreshSummary);

  const onSuccess = (result: ZxingResult) => handleScanSuccess(result.getText());

  const handleError = (error: Error | unknown) => {
    if (error instanceof Error) {
      switch (error.name) {
        case 'NotFoundError':
          //no camera device
          setPermissionState('not-found');
          break;
        case 'NotAllowedError':
          // when custom event query throw error
          setPermissionState('denied');
          break;
      }
    }
  };

  const renderPermission = () => {
    switch (permissionState) {
      case 'granted':
        return (
          <>
            <QrReader
              onSuccess={onSuccess}
              onError={handleError}
              constraints={{ facingMode: 'environment' }}
              ViewFinder={ViewFinder}
              videoId="video"
            />
            <EventDetailControlScanExit onRequestClose={handleScanRequestClose} />
            <EventDetailControlScanProgress scanned={summary.scanned} total={summary.total} date={summary.event.date} />
          </>
        );
      default:
        return (
          <EventDetailControlScanPermissions
            promptPermission={() => setPermissionState('granted')}
            permissionState={permissionState}
            onRequestClose={onRequestClose}
          />
        );
    }
  };

  useEffect(() => {
    pipe(
      permission,
      RD.fold(
        () => setPermissionState('loading'),
        ({ state }) => setPermissionState(state),
        () => setPermissionState('granted'),
      ),
    );
  }, [permission]);

  return (
    <FullModal isOpen={isOpen}>
      {renderOptional(
        lastResult,
        result => (
          <>
            <EventDetailControlScanResult result={result} handleReloadScan={handleReloadScan} />
            <EventDetailControlScanExit onRequestClose={onRequestClose} status={result.status} />
            <EventDetailControlScanProgress scanned={summary.scanned} total={summary.total} date={summary.event.date} />
          </>
        ),
        renderPermission,
      )}
      {loading && <Styled.EventDetailControlScanLoader src={loaderIcon} alt="Loading" />}
    </FullModal>
  );
};
export default EventDetailControlScanWeb;
