import React, { useEffect, useState } from 'react';
import { Filter, FilterChildren } from '../../model';
import Modal from '../../../../components/modal/Modal';

import * as Styled from './FiltersModal.styles';
import { Button } from '@styles/shared';

interface FiltersModalProps<F extends Filter> {
  isOpen: boolean;
  filter: F;
  onClose: () => void;
  onFilter: (filter: F) => void;
  children: FilterChildren<F>;
}

function FiltersModal<F extends Filter>({ isOpen, filter, onClose, onFilter, children }: FiltersModalProps<F>) {
  const [currentFilter, setCurrentFilter] = useState<F>(() => filter);

  useEffect(() => {
    setCurrentFilter(filter);
  }, [filter]);

  const handleApplyFilter = () => {
    onFilter(currentFilter);
    onClose();
  };

  return (
    <Modal title="Filtrer par" isOpen={isOpen} onRequestClose={onClose} width={670}>
      {children({ filter: currentFilter, onChange: setCurrentFilter })}

      <Styled.FiltersModalActions>
        <Button onClick={handleApplyFilter}>Appliquer</Button>
      </Styled.FiltersModalActions>
    </Modal>
  );
}

export default FiltersModal;
