import React, { FC, useEffect } from 'react';
import { CalendarEventId, defaultScanBookingsFilter } from '@modules/events/model';
import Page from '@layout/page/Page';
import * as ScanEventService from '@modules/events/service';
import * as Styled from './EventDetailBookings.styles';
import { defaultKeyMapper, useRange, VirtualizedTable } from '@shared/modules/range';
import EventDetailBookingsRow from '@modules/events/components/detail/bookings/row/EventDetailBookingsRow';
import { Filters, searchFilterParser } from '@shared/modules/filter';
import * as TE from 'fp-ts/TaskEither';
import { identity } from 'fp-ts/function';
import TouchRefresh from '@shared/modules/refresh/TouchRefresh';
import EventDetailBookingsHeader from '@modules/events/components/detail/bookings/header/EventDetailBookingsHeader';
import { useSummaryContext } from '@modules/events/components/detail/control/context';

interface EventDetailBookingsProps {
  id: CalendarEventId;
}

const EventDetailBookings: FC<EventDetailBookingsProps> = ({ id }) => {
  const { summary, refreshSummary } = useSummaryContext();
  const scrollElement = document.getElementById('booking-list');

  const { range, handleLoadMore, filter, defaultFilter, handleFilter, handleRefreshIndex } = useRange(
    ScanEventService.getScanCalendarEventBookingItems(id),
    defaultScanBookingsFilter,
    true,
  );

  const handleRefresh = TE.tryCatch(() => handleRefreshIndex(0), identity);

  useEffect(() => {
    const page = document.getElementById('page-scroller');
    page?.setAttribute('style', 'overflow: hidden');
  });

  return (
    <Page>
      <EventDetailBookingsHeader id={id} image={summary.event.image} refreshSummary={refreshSummary} />
      <Styled.EventDetailBookingsContent>
        <Styled.VirtualizedTableContainer>
          <Filters
            filter={filter}
            defaultFilter={defaultFilter}
            parser={searchFilterParser}
            search
            onFilter={handleFilter}
            placeholder="Nom, Prénom, Catégorie, Numéro"
          />
          {scrollElement && <TouchRefresh element={scrollElement} handleRefresh={handleRefresh} />}
          <VirtualizedTable
            range={range}
            loadMore={handleLoadMore}
            keyMapper={defaultKeyMapper}
            scrollId="booking-list"
          >
            {({ id, user, tickets }) => (
              <EventDetailBookingsRow
                id={id}
                user={user}
                tickets={tickets}
                prices={summary.prices}
                handleRefresh={handleRefresh}
              />
            )}
          </VirtualizedTable>
        </Styled.VirtualizedTableContainer>
      </Styled.EventDetailBookingsContent>
    </Page>
  );
};
export default EventDetailBookings;
