import React, { FC } from 'react';
import * as IO from 'fp-ts/IO';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import EventDetailControlScanResult from '@modules/events/components/detail/control/scan/result/EventDetailControlScanResult';
import FullModal from '@shared/components/modal/full/FullModal';
import { ScanLocalResult } from '@modules/events/model';
import { useSummaryContext } from '@modules/events/components/detail/control/context';
import EventDetailControlScanExit from '@modules/events/components/detail/control/scan/exit/EventDetailControlScanExit';
import EventDetailControlScanProgress from '@modules/events/components/detail/control/scan/progress/EventDetailControlScanProgress';
import { IOSScanResultLink } from '@shared/modules/platform/model';
import { getScanDisplayDateLast } from '@modules/events/components/detail/utils';

interface EventDetailControlScanIosProps {
  isOpen: boolean;
  result: ScanLocalResult;
  handleReloadScan: IO.IO<void>;
  onRequestClose: IO.IO<void>;
}

const EventDetailControlScanIos: FC<EventDetailControlScanIosProps> = ({
  isOpen,
  result,
  handleReloadScan,
  onRequestClose,
}) => {
  const { summary } = useSummaryContext();

  const last = pipe(
    getScanDisplayDateLast(summary.event.date),
    O.fold(
      () => '',
      last => `Début ${last}`,
    ),
  );

  const onReloadScan = pipe(
    onRequestClose,
    IO.chain(() => handleReloadScan),
    IO.chain(() => () => {
      const link = document.createElement('a');
      link.href = `${IOSScanResultLink}?total=${summary.total}&scanned=${summary.scanned}&date=${last}`;
      link.click();
    }),
  );

  return (
    <FullModal isOpen={isOpen}>
      <EventDetailControlScanResult result={result} handleReloadScan={onReloadScan} />
      <EventDetailControlScanExit onRequestClose={onRequestClose} />
      <EventDetailControlScanProgress scanned={summary.scanned} total={summary.total} date={summary.event.date} />
    </FullModal>
  );
};
export default EventDetailControlScanIos;
