import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './EventDetailBookingsHeader.styles';
import { Title } from '@styles/shared';
import { BucketResource } from '@shared/modules/bucket/model';
import { CalendarEventId } from '@modules/events/model';
import { renderNullable } from '@shared/utils/render';
import placeholder from '@assets/images/placeholder.svg';
import Imgix from 'react-imgix';
import * as IO from 'fp-ts/IO';
import { sequenceT } from 'fp-ts/Apply';

interface EventDetailBookingsHeaderProps {
  id: CalendarEventId;
  image: BucketResource;
  refreshSummary: IO.IO<void>;
}

const EventDetailBookingsHeader: FC<EventDetailBookingsHeaderProps> = ({ id, image, refreshSummary }) => {
  const navigate = useNavigate();

  const handleBackLink = sequenceT(IO.Apply)(refreshSummary, () => navigate(`/events/${id}`));

  return (
    <Styled.EventDetailBookingsHeaderContainer>
      <Styled.EventDetailBookingsHeaderMask />

      <Styled.EventDetailBookingsHeaderBackground>
        {renderNullable(
          image,
          image => (
            <Imgix src={image.url} sizes="100vw" />
          ),
          () => (
            <img src={placeholder} alt="placeholder" />
          ),
        )}
      </Styled.EventDetailBookingsHeaderBackground>
      <Styled.EventDetailBookingsHeaderBackLink onClick={handleBackLink} />
      <Title level="h5" color="white" style={{ paddingLeft: 13 }}>
        Réservations
      </Title>
    </Styled.EventDetailBookingsHeaderContainer>
  );
};
export default EventDetailBookingsHeader;
