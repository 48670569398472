import React, { FC } from 'react';

import { Helmet } from 'react-helmet-async';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

const TITLE_PREFIX = 'Citymag';

export interface SeoProps {
  title?: string;
  viewport?: string;
}

const Seo: FC<SeoProps> = ({ title, viewport }) => {
  const metaTitle = pipe(
    O.fromNullable(title),
    O.fold(
      () => TITLE_PREFIX,
      t => `${TITLE_PREFIX} - ${t}`,
    ),
  );

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="viewport" content={viewport ?? 'width=device-width, initial-scale=1.0'} />
    </Helmet>
  );
};

export default Seo;
