import React, { FC, useEffect, useState } from 'react';
import * as Styled from './EventDetailControl.styles';
import EventDetailControlHeader from '@modules/events/components/detail/control/header/EventDetailControlHeader';
import { CalendarEventId } from '@modules/events/model';
import EventDetailControlBottom from '@modules/events/components/detail/control/bottom/EventDetailControlBottom';
import EventDetailControlBody from '@modules/events/components/detail/control/body/EventDetailControlBody';
import { renderOptional } from '@shared/utils/render';
import { Paragraph } from '@styles/shared';
import { getScanDisplayDateLast, getScanDisplayDateStart } from '../utils';
import clockIcon from '@assets/icons/shared/clock.svg';
import Page from '@layout/page/Page';
import TouchRefresh from '@shared/modules/refresh/TouchRefresh';
import * as ScanEventService from '@modules/events/service';
import { useSummaryContext } from '@modules/events/components/detail/control/context';
import { useSendQrResult } from '@modules/events/components/detail/control/hooks';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';

interface EventDetailControlProps {
  id: CalendarEventId;
}

const EventDetailControl: FC<EventDetailControlProps> = ({ id }) => {
  const { summary, refreshSummary } = useSummaryContext();
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>();

  const start = getScanDisplayDateStart(summary.event.date);

  const last = getScanDisplayDateLast(summary.event.date);

  const { lastResult, resetLastResult, handleScanSuccess, loading } = useSendQrResult(
    ScanEventService.scanCalendarEvent(id),
  );

  useEffect(() => {
    const scrollElement = document.getElementById('page-scroller');
    setScrollElement(scrollElement);
  }, []);

  return (
    <Page>
      <Styled.EventDetailControlContainer>
        <EventDetailControlHeader
          title={summary.event.title}
          date={summary.event.date}
          address={summary.event.address}
          image={summary.event.image}
        />
        <Styled.EventDetailControlBodyTop>
          {renderOptional(sequenceS(O.Apply)({ start, last }), ({ start, last }) => (
            <>
              <div>
                <img src={clockIcon} alt="Horaire" />
                <Paragraph size="medium" color="tertiary" colorKey={500}>
                  Début le {start}
                </Paragraph>
              </div>
              <Paragraph size="medium" color="tertiary" colorKey={500}>
                {last}
              </Paragraph>
            </>
          ))}
        </Styled.EventDetailControlBodyTop>
        {scrollElement && <TouchRefresh element={scrollElement} handleRefresh={refreshSummary} />}
        <EventDetailControlBody summary={summary} />
        <EventDetailControlBottom
          lastResult={lastResult}
          handleScanSuccess={handleScanSuccess}
          resetLastResult={resetLastResult}
          loading={loading}
        />
      </Styled.EventDetailControlContainer>
    </Page>
  );
};
export default EventDetailControl;
