import { ScanCalendarEventRangeItem } from '@modules/events/model';
import React, { FC } from 'react';
import * as A from 'fp-ts/Array';

import * as Styled from './CalendarEventGrid.styles';
import CalendarEventCard from './card/CalendarEventCard';
import { pipe } from 'fp-ts/function';

interface CalendarEventGridProps {
  calendar: Array<ScanCalendarEventRangeItem>;
}

const CalendarEventGrid: FC<CalendarEventGridProps> = ({ calendar }) => (
  <Styled.CalendarEventGridContainer>
    {pipe(
      calendar,
      A.map(({ event, source }) => <CalendarEventCard key={event.id} source={source} event={event} />),
    )}
  </Styled.CalendarEventGridContainer>
);

export default CalendarEventGrid;
