export type CalendarDate = CalendarSimpleDate | CalendarDateTime | CalendarDateRange | CalendarDateTimeRange;

export enum CalendarDateType {
  Date = 'date',
  DateTime = 'date-time',
  DateRange = 'date-range',
  DateTimeRange = 'date-time-range',
}

export interface CalendarSimpleDate {
  type: CalendarDateType.Date;
  // format: dd/MM/yyyy
  value: string;
}

export interface CalendarDateTime {
  type: CalendarDateType.DateTime;
  // format: dd/MM/yyyy HH:mm
  value: string;
}

export interface CalendarDateRange {
  type: CalendarDateType.DateRange;
  // format: dd/MM/yyyy
  start: string;
  end: string;
}

export interface CalendarDateTimeRange {
  type: CalendarDateType.DateTimeRange;
  // format : dd/MM/yyyy HH:mm
  start: string;
  end: string;
}
