import { createGlobalStyle } from 'styled-components';
import { fontSize, primaryColor, secondaryColor, whiteColor } from '@styles/utils';

export const ToastsStyles = createGlobalStyle`
  .Toastify__toast-container {
    z-index: 100000 !important;
    
    .citymag-toast {
      min-height: 50px;
      padding: 15px 20px;
      border-radius: 10px;
      font-size: ${fontSize.medium};
      color: ${whiteColor};
      
      .Toastify__toast-icon svg {
        fill: ${whiteColor};
      }
      
      &.Toastify__toast--success {
        background: ${primaryColor(500)};
      }
      
      &.Toastify__toast--error {
        background: ${secondaryColor(400)};
      }
    }
  }
`;
