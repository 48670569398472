import styled, { DefaultTheme, FlattenInterpolation, ThemeProps, css } from 'styled-components';
import { fontSize, rgba, secondaryColor } from '@styles/utils';

import { MessageLevel } from './Message';

import errorIcon from '@assets/icons/messages/error.svg';
import successIcon from '@assets/icons/messages/success.svg';

const messageLevelStyles: Record<MessageLevel, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  success: css`
    border-color: ${secondaryColor()};

    span {
      background: url(${successIcon}) no-repeat center center ${secondaryColor()};
    }

    p {
      background-color: ${rgba(secondaryColor(), 0.05)};
      border: 1px solid ${secondaryColor()};
    }
  `,
  error: css`
    border-color: ${secondaryColor(400)};

    span {
      background: url(${errorIcon}) no-repeat center center ${secondaryColor(400)};
    }

    p {
      background-color: ${rgba(secondaryColor(400), 0.1)};
      border: 1px solid ${secondaryColor()};
    }
  `,
};

export const MessageContainer = styled.div<{ level: MessageLevel }>`
  display: flex;
  margin-bottom: 15px;

  ${props => messageLevelStyles[props.level]};

  span {
    flex: 0 0 50px;
    border-radius: 10px 0 0 10px;
  }

  p {
    flex: 1 1 auto;
    padding: 10px 15px;
    font-size: ${fontSize.small};
    border-radius: 0 10px 10px 0;
  }
`;
