import React, { FC, useState } from 'react';

import { FacebookLogin, FacebookLoginError, FacebookLoginResponse } from '@citymag/react-oauth';

import { pipe } from 'fp-ts/function';

import * as AuthService from '../../../service';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as Styled from '../SocialSignIn.styles';

import { renderOptional } from '@shared/utils/render';
import { useOAuthConfig } from '@modules/auth/hooks';
import { Profile } from '@modules/auth/model';
import { CityMagPlatform, IOSAuthenticationSuccessFullPayload, IOSInternalLinks } from '@shared/modules/platform/model';
import { usePlatform } from '@modules/descriptor/context';
import { useIOSEvent } from '@shared/modules/platform/hooks';
import { getErrorMessageFromStatus } from '@modules/auth/utils';

interface FacebookSignInProps {
  onSuccess: (profile: Profile) => void;
  onError: (error: string | null) => void;
}

const FacebookSignIn: FC<FacebookSignInProps> = ({ onSuccess, onError }) => {
  const { oauthKeys } = useOAuthConfig();

  const platform = usePlatform();

  const [loading, setLoading] = useState<boolean>(false);

  const handleAuthenticate = (token: string) => {
    setLoading(true);

    return pipe(
      AuthService.authenticateSocial('facebook', { token }),
      TE.fold(
        err => T.fromIO(() => onError(getErrorMessageFromStatus(err.status))),
        profile => T.fromIO(() => onSuccess(profile)),
      ),
      T.chainIOK(() => () => setLoading(false)),
    );
  };

  // Event for IOS native Oauth
  useIOSEvent<IOSAuthenticationSuccessFullPayload>('authenticationSuccessful', payload => {
    if (payload.provider === 'facebook') {
      handleAuthenticate(payload.token)();
    }
  });

  const handleSuccess = (response: FacebookLoginResponse) => {
    onError(null);

    handleAuthenticate(response.accessToken)();
  };

  const handleError = (error: FacebookLoginError) => {
    console.error(error);
    onError('Impossible de se connecter avec Facebook');
  };

  return renderOptional(oauthKeys.facebook, id =>
    platform === CityMagPlatform.IOS ? (
      <Styled.SocialSignInButton
        as="a"
        href={IOSInternalLinks.FacebookAuthentication}
        level="secondary"
        loading={loading}
        provider="facebook"
        style={{ backgroundPositionX: 7 }}
      >
        Se connecter avec Facebook
      </Styled.SocialSignInButton>
    ) : (
      <FacebookLogin
        appId={id}
        onSuccess={handleSuccess}
        onError={handleError}
        loginOptions={{ scope: 'public_profile,email' }}
      >
        {onClick => (
          <Styled.SocialSignInButton
            level="secondary"
            provider="facebook"
            onClick={onClick}
            loading={loading}
            style={{ backgroundPositionX: 7 }}
          >
            Continuer avec Facebook
          </Styled.SocialSignInButton>
        )}
      </FacebookLogin>
    ),
  );
};

export default FacebookSignIn;
