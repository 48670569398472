import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './EventDetailControlHeader.styles';
import { EventDetailControlHeaderBackLink } from './EventDetailControlHeader.styles';
import { Paragraph, Title } from '@styles/shared';
import { Address } from '@shared/model';
import { CalendarDate } from '@shared/modules/calendar/model';
import { getDisplayDate } from '@shared/utils/calendar';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import { renderNullable, renderOptional } from '@shared/utils/render';
import calendarIcon from '@assets/icons/shared/calendar.svg';
import pinIcon from '@assets/icons/shared/pin.svg';
import { BucketResource } from '@shared/modules/bucket/model';
import placeholder from '@assets/images/placeholder.svg';
import Imgix from 'react-imgix';

interface EventDetailControlHeaderProps {
  title: string;
  date: CalendarDate;
  address: Address;
  image: BucketResource;
}

const EventDetailControlHeader: FC<EventDetailControlHeaderProps> = ({ title, date, address, image }) => {
  const navigate = useNavigate();

  const handleBackLink = () => navigate('/events');

  const secondLine = pipe([address.postalCode, address.city], A.map(O.fromNullable), A.compact, NEA.fromArray);

  return (
    <Styled.EventDetailControlHeaderContainer>
      <Styled.EventDetailControlHeaderMask />
      <Styled.EventDetailControlHeaderBackground>
        {renderNullable(
          image,
          image => (
            <Imgix src={image.url} sizes="100vw" />
          ),
          () => (
            <img src={placeholder} alt="placeholder" />
          ),
        )}
      </Styled.EventDetailControlHeaderBackground>
      <EventDetailControlHeaderBackLink onClick={handleBackLink} />
      <div style={{ paddingLeft: 20 }}>
        <Title level="h5" color="white">
          {title}
        </Title>
        <Styled.EventDetailControlHeaderInfos>
          <div>
            <img src={calendarIcon} alt="Date" />
            <Paragraph color="white" size="medium">
              {getDisplayDate(date).full}
            </Paragraph>
          </div>
          <div>
            <img src={pinIcon} alt="Adresse" />
            <ul>
              {address.firstLine && (
                <li>
                  <Paragraph color="white" size="medium">
                    {address.firstLine}
                    {O.isSome(secondLine) && ','}
                  </Paragraph>
                </li>
              )}
              {renderOptional(secondLine, secondLine => (
                <li>
                  <Paragraph color="white" size="medium">
                    {secondLine.join(' ')}
                  </Paragraph>
                </li>
              ))}
            </ul>
          </div>
        </Styled.EventDetailControlHeaderInfos>
      </div>
    </Styled.EventDetailControlHeaderContainer>
  );
};
export default EventDetailControlHeader;
