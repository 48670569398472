import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { fontSize, fontWeight, mediaMax, primaryColor, rgba, secondaryColor, tertiaryColor } from '../../utils';
import { Paragraph } from '../Paragraph';
import { useFormElementEvent } from './shared';
import removeIcon from '@assets/icons/shared/cross-invert.svg';
import { usePlatform } from '@modules/descriptor/context';
import { CityMagPlatform } from '@shared/modules/platform/model';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const platform = usePlatform();

    return <InnerInput ref={ref} platform={platform} {...props} />;
  },
);

interface InnerInputProps extends InputProps {
  platform?: CityMagPlatform;
}

export const InnerInput = styled.input.attrs(props => ({
  type: props.type ?? 'text',
}))<InnerInputProps>`
  display: block;
  height: 41px;
  width: 100%;
  padding: 0 15px;
  margin: 0;
  border: 1px solid ${tertiaryColor(200)};
  border-radius: 10px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: ${({ platform }) => (platform === CityMagPlatform.IOS ? '16px' : fontSize.standard)};
  color: ${tertiaryColor(600)};
  line-height: 1.71;
  outline: none;
  transition: all 0.1s linear;
  background: rgba(255, 255, 255, 0.5);

  &::placeholder {
    font-family: inherit;
    font-size: ${fontSize.standard};
    font-style: italic;
    color: ${tertiaryColor(300)};
  }

  &:focus {
    outline: none;
    border-color: ${primaryColor(400)};
  }

  ${props =>
    props.disabled &&
    css`
      background: ${rgba(tertiaryColor(100), 0.3)};
      cursor: not-allowed;
    `};

  ${props =>
    props.error &&
    css`
      border-color: ${secondaryColor(400)};
      color: ${secondaryColor(400)};

      &::placeholder {
        color: ${secondaryColor(400)};
        opacity: 0.5;
      }

      &:focus {
        border-color: ${secondaryColor(400)};
      }
    `};
`;

const DecreaseCharactersNumberContainer = styled.div`
  width: 100%;
  margin-top: 4px;
  padding-left: 5px;
  text-align: left;
  font-style: italic;
  font-size: ${fontSize.extraSmall};
  color: ${tertiaryColor(400)};
`;

const DecreaseCharactersNumber = styled.span`
  display: inline-block;
  width: 15px;
`;

const LengthRestrictedInputContainer = styled.div`
  @media screen and (${mediaMax.large}) {
    margin-bottom: 10px;
  }
`;

interface LengthRestrictedInputProps {
  maxLength: number;
  value?: string | null;
}

export const LengthRestrictedInput: FC<
  LengthRestrictedInputProps & React.InputHTMLAttributes<HTMLInputElement> & InputProps
> = ({ maxLength, value, ...props }) => {
  return (
    <LengthRestrictedInputContainer>
      <Input maxLength={maxLength} value={value} {...props} />
      <DecreaseCharactersNumberContainer>
        Caractères restants :{' '}
        <DecreaseCharactersNumber>
          {value ? (maxLength > value.length ? maxLength - value.length : 0) : maxLength}
        </DecreaseCharactersNumber>
      </DecreaseCharactersNumberContainer>
    </LengthRestrictedInputContainer>
  );
};

export const LengthRestrictedTextArea: FC<
  LengthRestrictedInputProps & React.TextareaHTMLAttributes<HTMLTextAreaElement> & InputProps
> = ({ maxLength, value, ...props }) => {
  return (
    <LengthRestrictedInputContainer>
      <TextArea maxLength={maxLength} value={value} {...props} />
      <DecreaseCharactersNumberContainer>
        Caractères restants :{' '}
        <DecreaseCharactersNumber>
          {value ? (maxLength > value.length ? maxLength - value.length : 0) : maxLength}
        </DecreaseCharactersNumber>
      </DecreaseCharactersNumberContainer>
    </LengthRestrictedInputContainer>
  );
};

export const TextArea = styled.textarea<InputProps>`
  display: block;
  height: 40px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 85px;
  padding: 10px 15px;
  margin: 0;
  border: 1px solid ${tertiaryColor(200)};
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
  font-family: inherit;
  font-size: ${fontSize.medium};
  transition: all 0.1s linear;

  &::placeholder {
    font-family: inherit;
    font-size: ${fontSize.medium};
    font-style: italic;
    color: ${tertiaryColor(400)};
  }

  &:focus {
    outline: none;
    border-color: ${primaryColor(400)};
  }

  ${props =>
    props.error &&
    css`
      border-color: ${secondaryColor(400)};
      color: ${secondaryColor(400)};

      &::placeholder {
        color: ${secondaryColor(400)};
        opacity: 0.5;
      }

      &:focus {
        border-color: ${secondaryColor(400)};
      }
    `};
`;

const LargeInputContainer = styled.div<{
  active: boolean;
  focused: boolean;
  error: boolean;
  handleDelete?: () => void;
}>`
  position: relative;
  overflow: hidden;

  > label {
    position: absolute;
    left: 15px;
    top: 13px;
    right: 14px;
    height: 24px;
    font-size: ${fontSize.medium};
    font-style: ${props => (props.active ? 'unset' : 'italic')};
    color: ${props => (props.error ? secondaryColor(400) : tertiaryColor(400))};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transform-origin: left top;
    transform: ${props => (props.active ? 'translate3d(0, -8px, 0) scale(0.8)' : 'none')};
    transition: all 0.25s linear;
    z-index: 2;
  }

  > input {
    position: relative;
    height: 50px;
    padding: 12px 15px 0;
    z-index: 1;
    background: none;

    ${({ handleDelete }) => handleDelete && 'padding-right: 32px;'}
  }
`;

const RemoveIcon = styled.i`
  content: url(${removeIcon});
  position: absolute;
  z-index: 10;
  right: 12px;
  top: 19px;
  width: 13px;
  height: 13px;
  cursor: pointer;
`;

const LargeInputError = styled.p`
  margin-top: 5px;
  font-size: ${fontSize.small};
  color: ${secondaryColor(400)};
`;

export type LargeInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder'> & {
  label: string;
  error?: string | boolean;
  handleDelete?: () => void;
};

export const LargeInput: FC<LargeInputProps> = ({
  label,
  className,
  id,
  value,
  error,
  onFocus,
  onBlur,
  handleDelete,
  ...inputProps
}) => {
  const { active, focused, handleBlur, handleFocus } = useFormElementEvent(value, onFocus, onBlur);

  return (
    <LargeInputContainer
      active={active}
      focused={focused}
      error={!!error}
      className={className}
      handleDelete={handleDelete}
    >
      <label htmlFor={id}>{label}</label>
      <Input id={id} value={value} onFocus={handleFocus} onBlur={handleBlur} error={!!error} {...inputProps} />
      {handleDelete && value && <RemoveIcon onClick={handleDelete} />}
      {typeof error === 'string' && <LargeInputError>{error}</LargeInputError>}
    </LargeInputContainer>
  );
};

export const BigInput = styled(Input)`
  height: 80px;
  font-size: ${fontSize.h2};
  font-weight: ${fontWeight.semiBold};

  &::placeholder {
    font-size: ${fontSize.h2};
    font-weight: ${fontWeight.semiBold};
  }
`;

const MoneyInputContainer = styled.div<{ active: boolean; focused: boolean; error: boolean }>`
  height: 50px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${props => (props.error ? secondaryColor(400) : tertiaryColor(200))};
  border-radius: 10px;

  > p {
    margin-left: 7px;
    justify-content: center;
  }

  > label {
    width: 88px;
    position: absolute;
    left: 15px;
    top: 13px;
    right: 14px;
    height: 24px;
    font-size: ${fontSize.extraSmall};
    font-style: ${props => (props.active ? 'unset' : 'italic')};
    color: ${props => (props.error ? secondaryColor(400) : tertiaryColor(400))};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transform-origin: left top;
    transform: ${props => (props.active ? 'translate3d(0, -8px, 0) scale(0.8)' : 'none')};
    transition: all 0.25s linear;
    z-index: 0;
    border-right: green;
  }

  > input {
    display: block;
    height: 50px;
    width: 70%;
    padding: 12px 15px 0;
    margin: 0;
    border: none;
    border-right: 1px solid ${tertiaryColor(200)};
    box-sizing: border-box;
    font-family: inherit;
    font-size: ${fontSize.medium};
    color: ${({ theme }) => theme.colors.black};
    line-height: 1.71;
    outline: none;
    transition: all 0.1s linear;
    background: rgba(255, 255, 255, 0.5);

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::placeholder {
      font-family: inherit;
      font-size: ${fontSize.medium};
      font-style: italic;
      color: ${tertiaryColor(400)};
    }

    &:focus {
      outline: none;
      border-color: ${primaryColor(400)};
    }
  }
`;

const MoneyInputError = styled.p`
  margin-top: 5px;
  font-size: ${fontSize.small};
  color: ${secondaryColor(400)};
`;

export type MoneyInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder'> & {
  label: string;
  error?: string | boolean;
};

export const MoneyInput: FC<MoneyInputProps> = ({
  label,
  className,
  id,
  value,
  error,
  onFocus,
  onBlur,
  ...inputProps
}) => {
  const { active, focused, handleBlur, handleFocus } = useFormElementEvent(value, onFocus, onBlur);

  return (
    <MoneyInputContainer active={active} focused={focused} error={!!error} className={className}>
      <label htmlFor={id}>{label}</label>
      <input id={id} value={value} onFocus={handleFocus} onBlur={handleBlur} {...inputProps} />
      <Paragraph size="large" weight="semiBold" color="tertiary" colorKey={500}>
        €
      </Paragraph>
      {typeof error === 'string' && <MoneyInputError>{error}</MoneyInputError>}
    </MoneyInputContainer>
  );
};
