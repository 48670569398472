import * as React from 'react';
import * as Styled from './QrReader.styles';
import { useQrReader } from '../hooks';
import { QrReaderProps } from '../types';
import { constVoid, pipe, apply } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as IOE from 'fp-ts/IOEither';
import { Result } from '@zxing/library';
import { Helmet } from 'react-helmet-async';

export const QrReader: React.FC<QrReaderProps> = ({
  constraints,
  ViewFinder,
  scanDelay,
  onSuccess,
  onError,
  videoId,
  className,
}) => {
  const handleSteppedSuccess = (result: Result) =>
    pipe(
      O.fromNullable(onSuccess),
      IOE.fromOption(constVoid),
      IOE.map(apply(result)),
      IOE.chain(() =>
        pipe(
          controls.current,
          IOE.fromOption(constVoid),
          IOE.chainIOK(({ stop }) => stop),
        ),
      ),
    )();

  const controls = useQrReader({
    constraints,
    scanDelay,
    onSuccess: handleSteppedSuccess,
    onError,
    videoId,
  });

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <Styled.QrReaderContainer className={className}>
        <Styled.QrReaderContent>
          {!!ViewFinder && <ViewFinder />}
          <Styled.QrReaderVideo id={videoId} facingMode={constraints.facingMode} muted />
        </Styled.QrReaderContent>
      </Styled.QrReaderContainer>
    </>
  );
};

QrReader.displayName = 'QrReader';
QrReader.defaultProps = {
  constraints: {
    facingMode: 'user',
  },
  videoId: 'video',
  scanDelay: 500,
};
