import React, { FC } from 'react';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '@styles/global';
import theme from '@styles/theme';
import ToastsProvider from '@shared/components/toasts/ToastsProvider';
import { HelmetProvider } from 'react-helmet-async';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import RootRoutes from 'routes';
import { AuthContextProvider } from '@modules/auth/context';
import { ImgixProvider } from 'react-imgix';
import { DescriptorProvider } from '@modules/descriptor/context';
import PreventInstallPrompt from '@shared/components/pwa/PreventInstallPrompt';

export const history = createBrowserHistory();

const App: FC = () => {
  return (
    <ImgixProvider imgixParams={{ auto: 'compress,format' }} disableLibraryParam>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ToastsProvider />
        <HelmetProvider>
          <HistoryRouter history={history}>
            <AuthContextProvider>
              <DescriptorProvider>
                <RootRoutes />
              </DescriptorProvider>
            </AuthContextProvider>
            <PreventInstallPrompt />
          </HistoryRouter>
        </HelmetProvider>
      </ThemeProvider>
    </ImgixProvider>
  );
};

export default App;
