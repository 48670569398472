import { darken } from 'polished';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled, { css, DefaultTheme, FlattenInterpolation, keyframes, ThemeProps } from 'styled-components';

import checkIcon from '@assets/icons/buttons/check.svg';
import createIcon from '@assets/icons/buttons/create.svg';
import publishIcon from '@assets/icons/buttons/publish.svg';
import removeIcon from '@assets/icons/buttons/remove.svg';
import saveIcon from '@assets/icons/buttons/save.svg';
import parametersIcon from '@assets/icons/buttons/parameters.svg';
import { fontSize, fontWeight, mediaMax, primaryColor, secondaryColor, tertiaryColor, whiteColor } from '../utils';
import { shouldForwardPropHelper } from '../utils/props';
import questionIcon from '@assets/icons/shops/question.svg';
import exportIcon from '@assets/icons/survey/export.svg';

export type ButtonLevel = 'primary' | 'secondary';

export interface ButtonProps {
  level?: ButtonLevel;
  disabled?: boolean;
  loading?: boolean;
  type?: 'submit' | 'reset' | 'button';
}

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const styleByLevel: Record<ButtonLevel, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  primary: css`
    background: ${primaryColor()};
    color: ${whiteColor};

    &:hover {
      background-color: ${props => darken(0.04, primaryColor()(props))};
    }
  `,
  secondary: css`
    border: 3px solid ${primaryColor(500)};
    background: transparent;
    color: ${primaryColor()};

    &:hover {
      background-color: ${props => darken(0.04, primaryColor(400)(props))};
    }
  `,
};

const buttonStyle = css<Required<ButtonProps>>`
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 100px;
  font-family: inherit;
  font-size: ${fontSize.standard};
  font-weight: ${fontWeight.semiBold};
  text-align: center;
  outline: none;
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
  -webkit-appearance: none;
  transition: all 0.15s linear;

  ${props => styleByLevel[props.level]}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: ${props.level === 'primary' ? 0.5 : 0.9};
    `};

  ${props =>
    props.loading &&
    css`
      position: relative;
      pointer-events: none;
      color: transparent;
      user-select: none;

      &:before {
        opacity: 1;
        border-radius: 50%;
        display: block;
        animation: ${spinnerAnimation} 1.2s linear infinite;
        content: '';
        position: absolute;
        height: 14px;
        width: 14px;
        top: calc(50% - (18px / 2));
        left: calc(50% - (18px / 2));
        border: 2px solid ${props.level === 'primary' ? primaryColor(400) : primaryColor()};
        border-top-color: transparent;
      }
    `}
`;

function getRequiredProps<P extends ButtonProps>(props: P): Required<ButtonProps> {
  return {
    level: props.level ?? 'primary',
    disabled: !!props.disabled || !!props.loading,
    loading: !!props.loading,
    type: props.type ?? 'button',
  };
}

export const Button = styled.button
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      shouldForwardPropHelper(prop, defaultValidatorFn, ['level', 'loading']),
  })
  .attrs(getRequiredProps)`
  ${buttonStyle};
`;

export const LinkButton = styled(({ level, disabled, loading, ...rest }: LinkProps & ButtonProps) => (
  <Link {...rest} />
)).attrs(getRequiredProps)`
  ${buttonStyle};
`;

export const CreateButton = styled(Button)`
  padding-left: 40px;
  background-image: url(${createIcon});
  background-repeat: no-repeat;
  background-position: 15px center;

  @media screen and (${mediaMax.mobile}) {
    background-image: none;
    padding: 10px;
  }
`;

export const SaveButton = styled(Button)`
  padding-left: 40px;
  background-image: url(${saveIcon});
  background-repeat: no-repeat;
  background-position: 15px center;

  @media screen and (${mediaMax.mobile}) {
    background-image: none;
    padding: 10px;
  }
`;

export const PositiveButton = styled(Button)<{ noIcon?: boolean }>`
  padding-left: 38px;
  background-image: url(${checkIcon});
  background-repeat: no-repeat;
  background-position: 10px center;
  ${props =>
    props.noIcon &&
    css`
      background-image: none;
      padding-left: 20px;
    `}

  @media screen and (${mediaMax.mobile}) {
    background-image: none;
    padding: 10px;
  }
`;

export const NegativeButton = styled(Button)<{ noIcon?: boolean }>`
  padding-left: 38px;
  background: url(${removeIcon}) no-repeat 10px center ${secondaryColor(400)};

  &:hover {
    background-color: ${props => darken(0.04, secondaryColor(400)(props))};
  }
  ${props =>
    props.noIcon &&
    css`
      background-image: none;
      padding-left: 20px;
    `}

  @media screen and (${mediaMax.mobile}) {
    background-image: none;
    padding: 0;
  }
`;

export const PublishButton = styled(Button)`
  padding-left: 38px;
  background-image: url(${publishIcon});
  background-repeat: no-repeat;
  background-position: 10px center;

  @media screen and (${mediaMax.mobile}) {
    background-image: none;
    padding: 10px;
  }
`;

export const GreyButton = styled(Button)`
  background: ${tertiaryColor(100)};
  color: ${tertiaryColor(500)};

  &:hover {
    background: ${props => darken(0.04, tertiaryColor(100)(props))};
  }

  @media screen and (${mediaMax.mobile}) {
    padding: 10px;
  }
`;

export const ParametersButton = styled(Button)`
  padding-left: 38px;
  background-image: url(${parametersIcon});
  background-repeat: no-repeat;
  background-position: 10px center;

  @media screen and (${mediaMax.mobile}) {
    background-image: none;
    padding: 10px;
  }
`;

export const HowItWorksButton = styled(Button)`
  margin-right: 10px;
  padding-left: 40px;
  background-image: url(${questionIcon});
  background-repeat: no-repeat;
  background-position: 15px center;

  @media screen and (${mediaMax.mobile}) {
    background-image: none;
    padding: 10px;
  }
`;

export const CsvExportButton = styled(Button)`
  padding-left: 44px;
  background-image: url(${exportIcon});
  background-repeat: no-repeat;
  background-position: 12px center;

  @media screen and (${mediaMax.mobile}) {
    background-image: none;
    padding: 10px;
  }
`;
