import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

import { useFetchTask } from '@core/http/hooks';
import { renderHttpRemoteData } from '@shared/utils/render';
import { CalendarEventId, ScanCalendarEventSummary } from '@modules/events/model';
import { HttpTask } from '@core/http';
import * as ScanEventService from '@modules/events/service';

export interface SummaryContextValue {
  summary: ScanCalendarEventSummary;
  refreshSummary: HttpTask<ScanCalendarEventSummary>;
}

const SummaryContext = createContext<SummaryContextValue | null>(null);

interface SummaryProviderProps {
  id: CalendarEventId;
}

export const SummaryProvider: FC<PropsWithChildren<SummaryProviderProps>> = ({ id, children }) => {
  const [summary, refreshSummary] = useFetchTask(ScanEventService.getScanCalendarEventSummary, id);

  return renderHttpRemoteData(summary, summary => (
    <SummaryContext.Provider value={{ summary, refreshSummary }}>{children}</SummaryContext.Provider>
  ));
};

export const useSummaryContext = () => {
  const context = useContext(SummaryContext);

  if (!context) {
    throw new Error('useSummaryContext was used outside of its Provider');
  }

  return context;
};
