import React, { FC, memo } from 'react';
import { Paragraph } from '@styles/shared';
import * as Styled from './CalendarEventCard.styles';
import { CalendarEventDetail, ScanCalendarSource } from '@modules/events/model';
import { getDisplayDate } from '@shared/utils/calendar';
import pinIcon from '@assets/icons/shared/pin-blue.svg';
import generateSizes from 'html-sizes';
import { useImageBreakPoints } from '@shared/modules/bucket/hooks';
import BucketImage from '@shared/modules/bucket/components/images/BucketImage';
import Imgix from 'react-imgix';
import { renderNullable } from '@shared/utils/render';
import { useNavigate } from 'react-router-dom';

interface CalendarEventCardProps {
  source: ScanCalendarSource;
  event: CalendarEventDetail;
}

const CalendarEventCard: FC<CalendarEventCardProps> = ({ source, event }) => {
  const navigate = useNavigate();
  const { imageMediaMax } = useImageBreakPoints();

  const sizes = generateSizes({
    [`(max-width: 480px)`]: 'calc(100vw - 2 * 21px)',
    [imageMediaMax('tablet')]: 'calc(50vw - 2 * 21px - 16px)',
    default: '302px',
  });

  const handleCardNavigate = () => {
    navigate(`/events/${event.id}`);
  };

  return (
    <Styled.CalendarEventCardContainer onClick={handleCardNavigate}>
      <Styled.CalendarEventCardTop>
        <BucketImage image={event.image} sizes={sizes} ratio={302 / 168} />
      </Styled.CalendarEventCardTop>
      <Styled.CalendarEventCardBottom>
        <Styled.CalendarEventCardBottomContent>
          <Styled.CalendarEventCardSource>
            <Styled.CalendarEventCardSourceLogo>
              {renderNullable(source.logo, logo => (
                <Imgix src={logo} width={34} height={34} imgixParams={{ fit: 'fill', 'fill-color': 'white' }} />
              ))}
            </Styled.CalendarEventCardSourceLogo>
            <Paragraph size="small" color="tertiary" colorKey={600}>
              {source.name}
            </Paragraph>
          </Styled.CalendarEventCardSource>
          <Paragraph size="large" weight="bold" color="tertiary" colorKey={500} style={{ paddingBottom: 10 }}>
            {event.title}
          </Paragraph>
          <Paragraph size="small" color="tertiary" colorKey={500}>
            {getDisplayDate(event.date).full}
          </Paragraph>
        </Styled.CalendarEventCardBottomContent>
        <Styled.CalendarEventCardBottomAddressWrapper>
          <img src={pinIcon} alt="Adresse" />
          <Styled.CalendarEventCardBottomAddress>
            <Paragraph size="small">{event.address.firstLine}</Paragraph>
            <Paragraph size="small">{`${event.address.postalCode} ${event.address.city}`}</Paragraph>
          </Styled.CalendarEventCardBottomAddress>
        </Styled.CalendarEventCardBottomAddressWrapper>
      </Styled.CalendarEventCardBottom>
    </Styled.CalendarEventCardContainer>
  );
};

export default memo(CalendarEventCard);
