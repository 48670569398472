import { DEFAULT_RANGE_SIZE, Range, RangeCursor, RangeResult } from './model';
import { HttpRange, HttpRemoteData, HttpTask } from '@core/http';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { pipe } from 'fp-ts/function';

import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import { toast } from 'react-toastify';
import debouncePromise from 'debounce-promise';
import { Filter } from '../filter';

import * as RD from 'fp-ts-remote-data';

export function useRange<R, F extends Filter = {}>(
  task: (cursor: RangeCursor, filter?: F) => HttpRange<R, F>,
  defaultFilter: F,
  hideError?: boolean,
) {
  const [range, setRange] = useState<Range<R, F>>(() => Range.default());
  const [filter, setFilter] = useState<F>(() => defaultFilter);

  const fetchRange = useCallback(
    (cursor: RangeCursor = {}, filter?: F, disableMerge?: boolean) =>
      pipe(
        task(cursor, filter),
        T.chainIOK(result =>
          pipe(
            result,
            EI.fold(
              () => () => {
                if (!hideError) {
                  toast.error('Une erreur technique est survenue');
                }
                setRange(old => old.setLoading(false));
              },
              range => () => setRange(old => (disableMerge ? range : old.merge(range))),
            ),
          ),
        ),
      )(),
    [task, hideError],
  );

  const debouncedFetchRange = useMemo(() => debouncePromise(fetchRange, 150), [fetchRange]);

  const handleLoadMore = useCallback(
    (cursor: RangeCursor) => debouncedFetchRange(cursor, filter),
    [debouncedFetchRange, filter],
  );

  const handleRefreshIndex = useCallback(
    (index: number) => {
      const startIndex = Math.max(0, index - DEFAULT_RANGE_SIZE / 2);

      return fetchRange(
        {
          startIndex,
          endIndex: startIndex + DEFAULT_RANGE_SIZE - 1,
        },
        filter,
        true,
      );
    },
    [fetchRange, filter],
  );

  const handleFilter = useCallback(
    (filter: F) => {
      setFilter(filter);
      setRange(old => old.setLoading());

      return fetchRange({}, filter);
    },
    [fetchRange],
  );

  return {
    range,
    filter,
    defaultFilter,
    handleLoadMore,
    handleFilter,
    handleRefreshIndex,
    setRange,
  };
}

export function useSubRange<
  S extends Object,
  K extends Extract<keyof S, string>,
  F extends Filter = {},
  R = S[K] extends RangeResult<infer R, F> ? R : never,
  E = unknown,
>(
  key: S[K] extends RangeResult<R, F> ? K : never,
  task: (cursor: RangeCursor, filter?: F) => HttpTask<S, E>,
  defaultFilter: F,
  hideError?: boolean,
) {
  const [data, setData] = useState<HttpRemoteData<S, E>>(RD.pending);

  const fetchSubRange = useCallback(
    (cursor: RangeCursor, filter?: F) => {
      return pipe(
        task(cursor, filter),
        T.chainIOK(res => () => {
          setData(RD.fromEither(res));

          return pipe(
            res,
            EI.map(res => Range.fromRangeResult(res[key] as any as RangeResult<R, F>)),
          );
        }),
      );
    },
    [key, task],
  );

  const { handleLoadMore, ...rangeProps } = useRange(fetchSubRange, defaultFilter, hideError);

  useEffect(() => {
    handleLoadMore({});
  }, [handleLoadMore]);

  return {
    ...rangeProps,
    handleLoadMore,
    data,
  };
}
