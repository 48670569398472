import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, PropsWithChildren } from 'react';
import { HttpError } from '@core/http';
import { renderOptional } from '../../utils/render';
import * as Styled from './Message.styles';

export type MessageLevel = 'error' | 'success';

export interface MessageProps {
  level: MessageLevel;
  className?: string;
}

export const Message: FC<PropsWithChildren<MessageProps>> = ({ level, className, children }) => (
  <Styled.MessageContainer level={level} className={className}>
    <span />
    <p>{children}</p>
  </Styled.MessageContainer>
);

export type HttpErrorMessageBuilder<E = unknown> = (error: HttpError<E>) => string | null;

const defaultErrorMessage = 'Une erreur technique est survenue';

export interface HttpErrorMessageProps<E = unknown> {
  error: O.Option<HttpError<E>>;
  labelBuilder?: HttpErrorMessageBuilder<E>;
  className?: string;
}

export function HttpErrorMessage<E = unknown>({ error, labelBuilder, className }: HttpErrorMessageProps<E>) {
  const message = pipe(
    error,
    O.map(err =>
      pipe(
        O.fromNullable(labelBuilder),
        O.chainNullableK(builder => builder(err)),
        O.getOrElse(() => defaultErrorMessage),
      ),
    ),
  );

  return renderOptional(message, message => (
    <Message level="error" className={className}>
      {message}
    </Message>
  ));
}

export default Message;
