import { useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import { getConfigValue } from '@shared/utils/config';
import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';
import { OAuthProvider } from '@modules/auth/model';

const oAuthConfigKeys: Record<OAuthProvider, string> = {
  google: 'VITE_GOOGLE_SIGNIN_CLIENT_ID',
  facebook: 'VITE_FACEBOOK_SIGNIN_APP_ID',
  apple: 'VITE_APPLE_SIGNIN_CLIENT_ID',
  'apple-web': 'APPLE_SIGNIN_CLIENT_ID',
};

export function useOAuthConfig() {
  const oauthKeys = useMemo(
    () =>
      pipe(
        oAuthConfigKeys,
        R.mapWithIndex((provider, key) => getConfigValue(key)),
      ),
    [],
  );

  return {
    oauthKeys,
    isOAuthConfigured: R.some(O.isSome)(oauthKeys),
  };
}
