import React, { FC } from 'react';
import * as IO from 'fp-ts/IO';
import * as O from 'fp-ts/Option';
import { ScanLocalResult } from '@modules/events/model';
import { usePlatform } from '@modules/descriptor/context';
import { CityMagPlatform } from '@shared/modules/platform/model';
import EventDetailControlScanIos from '@modules/events/components/detail/control/scan/ios/EventDetailControlScanIos';
import EventDetailControlScanWeb from '@modules/events/components/detail/control/scan/web/EventDetailControlScanWeb';
import { renderOptional } from '@shared/utils/render';

interface EventDetailControlScanProps {
  lastResult: O.Option<ScanLocalResult>;
  onSuccess: (result: string) => void;
  onRequestClose: IO.IO<void>;
  handleReloadScan: IO.IO<void>;
  isOpen: boolean;
  loading: boolean;
}

const EventDetailControlScan: FC<EventDetailControlScanProps> = ({
  lastResult,
  onSuccess,
  onRequestClose,
  handleReloadScan,
  isOpen,
  loading,
}) => {
  const platform = usePlatform();

  return platform === CityMagPlatform.IOS ? (
    renderOptional(lastResult, result => (
      <EventDetailControlScanIos
        result={result}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        handleReloadScan={handleReloadScan}
      />
    ))
  ) : (
    <EventDetailControlScanWeb
      lastResult={lastResult}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      handleScanSuccess={onSuccess}
      handleReloadScan={handleReloadScan}
      loading={loading}
    />
  );
};

export default EventDetailControlScan;
