import React, { FC, useState } from 'react';
import { AppleLogin, AppleLoginError, AppleLoginResponse } from '@citymag/react-oauth';
import * as Styled from '../SocialSignIn.styles';

import { useOAuthConfig } from '../../../hooks';

import { OAuthProvider, Profile } from '../../../model';
import { pipe } from 'fp-ts/function';
import * as AuthService from '../../../service';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { renderOptional } from '@shared/utils/render';
import { usePlatform } from '@modules/descriptor/context';
import { CityMagPlatform, IOSAuthenticationSuccessFullPayload, IOSInternalLinks } from '@shared/modules/platform/model';
import { useIOSEvent } from '@shared/modules/platform/hooks';
import { getErrorMessageFromStatus } from '@modules/auth/utils';

interface AppleSignInProps {
  onSuccess: (profile: Profile) => void;
  onError: (error: string | null) => void;
}

const AppleSignIn: FC<AppleSignInProps> = ({ onSuccess, onError }) => {
  const { oauthKeys } = useOAuthConfig();

  const platform = usePlatform();

  const [loading, setLoading] = useState<boolean>(false);

  const handleAuthenticate = (
    provider: Extract<OAuthProvider, 'apple' | 'apple-web'>,
    token: string,
    firstName?: string,
    lastName?: string,
  ) => {
    setLoading(true);

    return pipe(
      AuthService.authenticateSocial(provider, { token, firstName, lastName }),
      TE.fold(
        err => T.fromIO(() => onError(getErrorMessageFromStatus(err.status))),
        profile => T.fromIO(() => onSuccess(profile)),
      ),
      T.chainIOK(() => () => setLoading(false)),
    );
  };

  // Event for IOS native Oauth
  useIOSEvent<IOSAuthenticationSuccessFullPayload>('authenticationSuccessful', payload => {
    if (payload.provider === 'apple') {
      // Use apple provider for IOS
      handleAuthenticate('apple', payload.token, payload.user?.firstname, payload.user?.lastname)();
    }
  });

  const handleSuccess = (res: AppleLoginResponse) => {
    handleAuthenticate('apple-web', res.authorization.id_token, res.user?.name.firstName, res.user?.name.lastName)();
  };

  const handleError = (reason: AppleLoginError) => {
    console.error(reason);
    if (reason !== 'popup_closed_by_user') {
      onError('Impossible de se connecter avec Apple');
    }
  };

  return renderOptional(oauthKeys.apple, id =>
    platform === CityMagPlatform.IOS ? (
      <Styled.SocialSignInButton
        as="a"
        href={IOSInternalLinks.AppleAuthentication}
        level="secondary"
        provider="apple"
        loading={loading}
        style={{ backgroundPositionY: 7 }}
      >
        Continuer avec Apple
      </Styled.SocialSignInButton>
    ) : (
      <AppleLogin
        clientId={id}
        redirectURI={window.location.origin}
        onSuccess={handleSuccess}
        onError={handleError}
        config={{ scope: 'name email' }}
      >
        {onClick => (
          <Styled.SocialSignInButton
            level="secondary"
            provider="apple"
            onClick={onClick}
            loading={loading}
            style={{ backgroundPositionY: 7 }}
          >
            Continuer avec Apple
          </Styled.SocialSignInButton>
        )}
      </AppleLogin>
    ),
  );
};

export default AppleSignIn;
