import React, { FC } from 'react';
import { Routes } from '@core/router';
import { Route } from 'react-router-dom';
import EventListPage from '@modules/events/components/list/ListPage';
import EventScanDetailRoutes from '@modules/events/components/detail/routes';
import { Profile } from '@modules/auth/model';

interface EventScanRoutesParams {
  profile: Profile;
}

const EventScanRoutes: FC<EventScanRoutesParams> = ({ profile }) => {
  return (
    <Routes>
      <Route path="/" element={<EventListPage profile={profile} />} />
      <Route path="/:id/*" element={<EventScanDetailRoutes />} />
    </Routes>
  );
};

export default EventScanRoutes;
