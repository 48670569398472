import React, { FC } from 'react';
import { useRange, VirtualizedGrid } from '@shared/modules/range';
import * as ScanEventService from '../../service';
import { defaultScanCalendarEventFilter } from '@modules/events/model';
import Page from '@layout/page/Page';
import CalendarEventGrid from './grid/CalendarEventGrid';
import CalendarEventGridHeader from '@modules/events/components/list/header/CalendarEventGridHeader';
import { Profile } from '@modules/auth/model';
import { Title } from '@styles/shared';
import * as Styled from './ListPage.styles';
import * as TE from 'fp-ts/TaskEither';
import TouchRefresh from '@shared/modules/refresh/TouchRefresh';
import { identity } from 'fp-ts/function';

interface ListPageParams {
  profile: Profile;
}

const ListPage: FC<ListPageParams> = ({ profile }) => {
  const element = document.getElementById('page-scroller');

  const { range, handleLoadMore, handleRefreshIndex } = useRange(
    ScanEventService.getScanCalendarEventItems,
    defaultScanCalendarEventFilter,
    true,
  );

  const handleRefresh = TE.tryCatch(() => handleRefreshIndex(0), identity);

  return (
    <Page>
      <CalendarEventGridHeader profile={profile} />
      <Styled.ListPageContainer>
        {element && <TouchRefresh element={element} handleRefresh={handleRefresh} />}
        <Title level="h2">Évènements</Title>
        <VirtualizedGrid range={range} loadMore={handleLoadMore} chunkSize={6}>
          {calendar => <CalendarEventGrid calendar={calendar} />}
        </VirtualizedGrid>
      </Styled.ListPageContainer>
    </Page>
  );
};
export default ListPage;
