import React, { FC } from 'react';
import { CalendarEventBookingTicketScanStatus, ticketScanStatusLabel } from '@modules/events/model';
import * as Styled from './EventDetailBookingTicketStatus.styles';

interface EventDetailBookingTicketStatusParams {
  status: CalendarEventBookingTicketScanStatus;
}

const EventDetailBookingTicketStatus: FC<EventDetailBookingTicketStatusParams> = ({ status }) => {
  return (
    <Styled.EventDetailBookingTicketStatusContainer status={status}>
      {ticketScanStatusLabel[status]}
    </Styled.EventDetailBookingTicketStatusContainer>
  );
};

export default EventDetailBookingTicketStatus;
