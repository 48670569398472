import { tertiaryColor } from '@styles/utils';
import styled from 'styled-components';
import { Button } from '@styles/shared';

export const CalendarEventCardContainer = styled(Button)`
  display: grid;
  grid-template-rows: minmax(1fr, 168px) 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 302px;
  border-radius: 20px;
  padding: 0;
  box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.08);

  background: transparent;
  :hover {
    background: transparent;
  }

  :active {
    transform: scale(0.98);
  }
`;

export const CalendarEventCardTop = styled.div`
  grid-row: 1;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
`;

export const CalendarEventCardBottom = styled.div`
  grid-row: 2;
  height: 100%;
  padding-bottom: 15px;
`;

export const CalendarEventCardBottomContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 14px;

  p {
    line-height: 1;
    text-align: start;
  }
`;

export const CalendarEventCardSource = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

export const CalendarEventCardSourceLogo = styled.div`
  width: 35px;
  height: 35px;
  margin-right: 5px;
  border: 1px solid ${tertiaryColor(100)};
  border-radius: 4px;
  overflow: hidden;
`;

export const CalendarEventCardBottomAddressWrapper = styled.div`
  position: relative;
  display: flex;
  border-top: 1px solid ${tertiaryColor(100)};
  padding-left: 22px;
  padding-top: 15px;

  > img {
    margin-right: 6px;
  }
`;

export const CalendarEventCardBottomAddress = styled.div`
  padding-top: 4px;

  p {
    color: #0c379d;
    text-align: start;

    :first-child {
      margin-bottom: 4px;
    }
  }
`;
