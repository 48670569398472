import React, { FC } from 'react';
import { Route, Navigate } from 'react-router-dom';

import { Routes } from '@core/router';
import { Profile } from '@modules/auth/model';
import Layout from '@layout/Layout';
import { useAuthContext } from '@modules/auth/context';
import { renderOptional } from '@shared/utils/render';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import LoginPage from '@modules/auth/components/LoginPage';
import EventScanRoutes from '@modules/events/routes';

interface PrivateRoutesProps {
  profile: Profile;
}

const PrivateRoutes: FC<PrivateRoutesProps> = ({ profile }) => (
  <Layout>
    <Routes>
      <Route path="/events/*" element={<EventScanRoutes profile={profile} />} />
      <Route path="/*" element={<Navigate to="/events" />} />
    </Routes>
  </Layout>
);

const RootRoutes: FC = () => {
  const { profile } = useAuthContext();

  return (
    <SentryProvider profile={profile}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="*"
          element={
            <>
              {renderOptional(
                profile,
                profile => (
                  <PrivateRoutes profile={profile} />
                ),
                () => (
                  <Navigate to="/login" />
                ),
              )}
            </>
          }
        />
      </Routes>
    </SentryProvider>
  );
};

export default RootRoutes;
