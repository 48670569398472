import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { backgroundColor } from '@styles/utils';
import * as IO from 'fp-ts/IO';

const SwitchStyle = styled.label`
  position: relative;
  display: inline-block;
  width: 54px;
  height: 31px;
  outline: none;

  > input {
    opacity: 0;
    width: 0;
    height: 0;
    outline: none;
  }

  > span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, #7e7e7e, #c9c9c9);
    transition: 0.4s;
    border-radius: 15px;

    &:before {
      position: absolute;
      content: '';
      height: 22px;
      width: 22px;
      top: 50%;
      transform: translateY(-50%);
      left: 5px;
      background-color: ${backgroundColor(400)};
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
    }
  }

  input:checked + span {
    background-image: linear-gradient(to bottom, #5bc6b2, #b3e8d5);

    &:before {
      transform: translateX(22px) translateY(-50%);
    }
  }

  input:disabled + span {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export type SwitchProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface DynamicSwitchProps {
  isChecked: IO.IO<boolean>;
}

export const Switch: FC<SwitchProps & DynamicSwitchProps> = ({ id, type, className, isChecked, ...rest }) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(isChecked());
  }, [isChecked]);

  return (
    <SwitchStyle htmlFor={id} className={className}>
      <input type="checkbox" checked={checked} id={id} {...rest} />
      <span />
    </SwitchStyle>
  );
};
