import styled from 'styled-components';

export const DirtyFormCloseModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;

  > button:first-child {
    margin-right: 20px;
    text-decoration: underline;

    :hover {
      background: transparent;
    }
  }
`;
