import styled from 'styled-components';
import { mediaMax } from '@styles/utils';

export const CalendarEventGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 22px;
  justify-items: center;
  margin-top: 21px;

  @media screen and (${mediaMax.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
