import React, { FC, useState } from 'react';
import { useSendTask } from '@core/http/hooks';
import FullModal from '@shared/components/modal/full/FullModal';
import * as IO from 'fp-ts/IO';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import * as B from 'fp-ts/boolean';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as ScanEventService from '@modules/events/service';
import * as Styled from './EventDetailBookingTicketModal.styles';
import {
  BatchCheckInBody,
  BatchCheckInResult,
  CalendarEventBookingTarget,
  CalendarEventBookingTicketId,
  CalendarEventBookingTicketScanStatus,
  CalendarEventRegistrationPrice,
  ScanCalendarEventBookingTicket,
  ScanResultAlreadyScanned,
} from '@modules/events/model';
import { Button, Paragraph, Switch } from '@styles/shared';
import { constVoid, pipe } from 'fp-ts/function';
import { useBooleanState } from '@shared/hooks/boolean';
import { sequenceT } from 'fp-ts/Apply';
import { toast } from 'react-toastify';
import { DirtyFormCloseModal } from '@shared/modules/form/dirty/DirtyFormCloseModal';
import { renderOptional } from '@shared/utils/render';
import EventDetailBookingTicketStatus from '@modules/events/components/detail/bookings/status/EventDetailBookingTicketStatus';

interface EventDetailBookingTicketModalParams {
  user: CalendarEventBookingTarget;
  tickets: Array<ScanCalendarEventBookingTicket>;
  prices: Array<CalendarEventRegistrationPrice>;
  isOpen: boolean;
  onRequestClose: IO.IO<void>;
  handleRefresh: TE.TaskEither<unknown, void>;
}

const EventDetailBookingTicketModal: FC<EventDetailBookingTicketModalParams> = ({
  user,
  tickets,
  prices,
  isOpen,
  onRequestClose,
  handleRefresh,
}) => {
  const [errorDisplay, showError, hideError] = useBooleanState();
  const [isDirtyOpen, openDirtyModal, closeDirtyModal] = useBooleanState();

  const [formState, setFormState] = useState<Array<BatchCheckInBody>>([]);
  const [alreadyScannedTickets, setAlreadyScannedTickets] = useState<Array<ScanResultAlreadyScanned>>([]);

  const [loading, sendRequest] = useSendTask(ScanEventService.checkInTicketBatch, {
    hideError: true,
  });

  const swapStatus = (status: CalendarEventBookingTicketScanStatus) => {
    switch (status) {
      case CalendarEventBookingTicketScanStatus.Generated:
        return CalendarEventBookingTicketScanStatus.Checked;
      default:
        return CalendarEventBookingTicketScanStatus.Generated;
    }
  };

  const addTicket = (id: CalendarEventBookingTicketId, status: CalendarEventBookingTicketScanStatus) =>
    pipe(IO.of(formState), IO.map(A.append({ id, status })), IO.map(setFormState));

  const removeTicket = (id: CalendarEventBookingTicketId) =>
    pipe(IO.of(formState), IO.map(A.filter(ticket => ticket.id !== id)), IO.map(setFormState));

  const handleSwitch = (id: CalendarEventBookingTicketId, status: CalendarEventBookingTicketScanStatus) =>
    pipe(
      formState,
      A.findFirst(ticket => id === ticket.id),
      O.fold(
        () => addTicket(id, swapStatus(status)),
        () => removeTicket(id),
      ),
    );

  const statusCheckedValue = (status: CalendarEventBookingTicketScanStatus) =>
    status !== CalendarEventBookingTicketScanStatus.Generated;

  const isChecked = (id: CalendarEventBookingTicketId, status: CalendarEventBookingTicketScanStatus) => () =>
    pipe(
      formState,
      A.findFirst(ticket => id === ticket.id),
      O.fold(
        () => statusCheckedValue(status),
        ({ status }) => statusCheckedValue(status),
      ),
    );

  const isDisabled = () => pipe(formState, A.isEmpty);

  const handleCheckAlreadyScanned = ({ alreadyScanned }: BatchCheckInResult) =>
    pipe(
      handleRefresh,
      T.chainIOK(() =>
        pipe(
          A.isEmpty(alreadyScanned),
          B.fold(
            () =>
              sequenceT(IO.Apply)(
                () => setAlreadyScannedTickets(alreadyScanned),
                () =>
                  toast.success('Certains tickets sélectionnés ont été contrôlés entre temps', {
                    position: 'bottom-right',
                  }),
              ),
            () =>
              sequenceT(IO.Apply)(onRequestClose, () =>
                toast.success('Modifications enregistrés', { position: 'bottom-right' }),
              ),
          ),
          IO.chain(() => resetForm),
        ),
      ),
    );

  const handleSubmit = pipe(
    formState,
    A.map(({ id }) => id),
    TE.tryCatchK(sendRequest, constVoid),
    TE.chainIOK(E.fold(() => showError, handleCheckAlreadyScanned)),
  );

  const resetForm = () => setFormState([]);

  const handleTicketModalClose = () => pipe(formState, A.isNonEmpty, B.fold(onRequestClose, openDirtyModal));

  const isAlreadyScanned = (id: CalendarEventBookingTicketId) =>
    pipe(
      alreadyScannedTickets,
      A.some(({ ticketId }) => id === ticketId),
    );

  return (
    <>
      <FullModal isOpen={isOpen}>
        <Styled.EventDetailBookingTicketModalWrapper>
          <Styled.EventDetailBookingTicketModalContainer>
            <Styled.EventDetailBookingTicketModalCloseButton onClick={handleTicketModalClose} />
            <Paragraph color="tertiary" colorKey={600} style={{ paddingLeft: 15 }}>
              Réservation
            </Paragraph>
            <Paragraph size="large" color="tertiary" colorKey={500} weight="bold" style={{ paddingLeft: 15 }}>
              {user.firstName} {user.lastName}
            </Paragraph>
            <Styled.EventDetailBookingTicketModalContent>
              {pipe(
                tickets,
                A.map(ticket => ({ ...ticket, isAlreadyScanned: isAlreadyScanned(ticket.id) })),
                A.map(({ id, priceId, code, status, scannedAt, scannedBy, isAlreadyScanned }) => (
                  <Styled.EventDetailBookingTicketModalContentTicket key={id}>
                    {renderOptional(
                      pipe(
                        prices,
                        A.findFirst(({ id }) => priceId === id),
                      ),
                      ({ title }) => (
                        <Paragraph size="large" color="tertiary" colorKey={500} weight="bold">
                          {title}
                        </Paragraph>
                      ),
                    )}
                    <Styled.EventDetailBookingTicketModalContentTicketCode isAlreadyScanned={isAlreadyScanned}>
                      N°{code}
                    </Styled.EventDetailBookingTicketModalContentTicketCode>
                    <div>
                      <div style={{ display: 'flex' }}>
                        <EventDetailBookingTicketStatus status={status} />
                        {isAlreadyScanned && (
                          <Styled.EventDetailBookingTicketModalContentAlreadyScannedStatus>
                            Déjà contrôlé
                          </Styled.EventDetailBookingTicketModalContentAlreadyScannedStatus>
                        )}
                      </div>
                      <Switch
                        onChange={handleSwitch(id, status)}
                        isChecked={isChecked(id, status)}
                        disabled={status !== CalendarEventBookingTicketScanStatus.Generated}
                      />
                    </div>
                    {renderOptional(
                      pipe(
                        status,
                        O.fromPredicate(status => status !== CalendarEventBookingTicketScanStatus.Generated),
                        O.chain(() =>
                          pipe(
                            O.fromNullable(scannedAt),
                            O.map(date => `Le ${date.split(' ').join(' à ')}`),
                            A.of,
                            A.append(O.fromNullable(scannedBy)),
                            A.compact,
                            NEA.fromArray,
                          ),
                        ),
                      ),
                      atAndBy => (
                        <Paragraph size="small" color="tertiary" colorKey={600} style={{ paddingTop: 13 }}>
                          {atAndBy.join(' par ')}
                        </Paragraph>
                      ),
                    )}
                  </Styled.EventDetailBookingTicketModalContentTicket>
                )),
              )}
            </Styled.EventDetailBookingTicketModalContent>
          </Styled.EventDetailBookingTicketModalContainer>
          {errorDisplay && (
            <Styled.EventDetailBookingTicketModalErrorContainer>
              <Paragraph color="secondary" colorKey={500}>
                Un problème est survenu
              </Paragraph>
              <Button onClick={hideError} />
            </Styled.EventDetailBookingTicketModalErrorContainer>
          )}
          <Styled.EventDetailBookingTicketModalFooter>
            <Button onClick={handleSubmit} disabled={isDisabled()} loading={loading}>
              Valider
            </Button>
          </Styled.EventDetailBookingTicketModalFooter>
        </Styled.EventDetailBookingTicketModalWrapper>
      </FullModal>
      <DirtyFormCloseModal
        isOpen={isDirtyOpen}
        closeDirtyModal={closeDirtyModal}
        closeTicketsModal={onRequestClose}
        resetForm={resetForm}
      />
    </>
  );
};

export default EventDetailBookingTicketModal;
