import {
  CityMagPlatform,
  iosPlatformCode,
  iosUserAgentCodeRegex,
  twaPackageName,
  twaReferrerCodeRegex,
} from '@shared/modules/platform/model';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as RNEA from 'fp-ts/ReadonlyNonEmptyArray';
import * as S from 'fp-ts/string';
import { flow, pipe } from 'fp-ts/function';

const isIOS = (userAgent: string) =>
  pipe(
    userAgent.match(iosUserAgentCodeRegex),
    O.fromNullable,
    O.chain(A.head),
    O.map(flow(S.split('/'), RNEA.last)),
    O.exists(S.includes(iosPlatformCode)),
  );

const isTWA = (referrer: string) =>
  pipe(
    referrer.match(twaReferrerCodeRegex),
    O.fromNullable,
    O.chain(A.head),
    O.map(flow(S.split('://'), RNEA.last)),
    O.exists(S.includes(twaPackageName)),
  );

export const getCurrentPlatform = (): CityMagPlatform => {
  if (isIOS(navigator.userAgent)) {
    return CityMagPlatform.IOS;
  } else if (isTWA(document.referrer)) {
    return CityMagPlatform.TWA;
  }
  return CityMagPlatform.WEB;
};
