import styled from 'styled-components';
import { backgroundColor } from '@styles/utils';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const LayoutContent = styled.div`
  flex: 1 1 auto;
  height: 100%;
  background: ${backgroundColor(400)};
  overflow-y: auto;
`;
