import React, { FC, useState } from 'react';
import { GoogleLogin, GoogleLoginResponse } from '@citymag/react-oauth';

import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';

import * as AuthService from '../../../service';
import * as T from 'fp-ts/Task';

import * as Styled from '../SocialSignIn.styles';
import { renderOptional } from '@shared/utils/render';
import { useOAuthConfig } from '@modules/auth/hooks';
import { Profile } from '@modules/auth/model';
import { CityMagPlatform, IOSAuthenticationSuccessFullPayload, IOSInternalLinks } from '@shared/modules/platform/model';
import { usePlatform } from '@modules/descriptor/context';
import { useIOSEvent } from '@shared/modules/platform/hooks';
import { getErrorMessageFromStatus } from '@modules/auth/utils';

interface GoogleSignInProps {
  onSuccess: (profile: Profile) => void;
  onError: (error: string | null) => void;
}

const GoogleSignIn: FC<GoogleSignInProps> = ({ onSuccess, onError }) => {
  const platform = usePlatform();

  const [loading, setLoading] = useState<boolean>(false);

  const { oauthKeys } = useOAuthConfig();

  const handleAuthenticate = (token: string) => {
    setLoading(true);

    return pipe(
      AuthService.authenticateSocial('google', { token }),
      TE.fold(
        err => T.fromIO(() => onError(getErrorMessageFromStatus(err.status))),
        profile => T.fromIO(() => onSuccess(profile)),
      ),
      T.chainIOK(() => () => setLoading(false)),
    );
  };

  // Event for IOS native Oauth
  useIOSEvent<IOSAuthenticationSuccessFullPayload>('authenticationSuccessful', payload => {
    if (payload.provider === 'google') {
      handleAuthenticate(payload.token)();
    }
  });

  const handleSuccess = (response: GoogleLoginResponse) => {
    onError(null);

    handleAuthenticate(response.credential)();
  };

  const handleScriptLoadFailure = () => {
    onError('Impossible de se connecter avec Google');
  };

  return renderOptional(oauthKeys.google, id =>
    platform === CityMagPlatform.IOS ? (
      <Styled.SocialSignInButton
        as="a"
        href={IOSInternalLinks.GoogleAuthentication}
        level="secondary"
        loading={loading}
        provider="google"
      >
        Se connecter avec Google
      </Styled.SocialSignInButton>
    ) : (
      <GoogleLogin
        clientId={id}
        onSuccess={handleSuccess}
        onScriptError={handleScriptLoadFailure}
        options={{ context: 'signin' }}
        styles={{ shape: 'circle', width: 280, text: 'continue_with', logo_alignment: 'left' }}
      />
    ),
  );
};

export default GoogleSignIn;
