import { FC, useEffect } from 'react';

//warn: beforeinstallprompt feature has been removed from the spec (https://github.com/w3c/manifest/issues/835)
const PreventInstallPrompt: FC = () => {
  useEffect(() => {
    //hack: preventDefault() prevents prompt display
    const handleInstallPrompt = (e: Event) => e.preventDefault();
    window.addEventListener('beforeinstallprompt', handleInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
    };
  }, []);

  return null;
};

export default PreventInstallPrompt;
