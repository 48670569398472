import styled from 'styled-components';

import filterIcon from '@assets/icons/filter/filter.svg';
import { backgroundColor, fontSize, fontWeight, mediaMax } from '@styles/utils';

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 71px;
  background-color: ${backgroundColor(100)};
`;

export const FiltersContent = styled.div`
  display: flex;
  align-items: center;

  @media screen and ${mediaMax.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FiltersButton = styled.button`
  padding: 0 0 0 35px;
  margin: 0;
  width: 85px;
  flex: 0 0 85px;
  height: 35px;
  font: inherit;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.semiBold};
  text-align: left;
  background: url(${filterIcon}) no-repeat top 1px left;
  border: none;
  cursor: pointer;
  outline: none;

  @media screen and ${mediaMax.mobile} {
    width: 100%;
    height: auto;
    flex: auto;
    padding: 10px 0 10px 40px;
    text-align: left;
    background: url(${filterIcon}) no-repeat top 5px left;
  }
`;

export const FiltersActiveContainer = styled.div`
  flex: 1 1 auto;
`;
