import styled, { css } from 'styled-components';
import { backgroundColor, fontSize, fontWeight, secondaryColor, tertiaryColor } from '@styles/utils';
import closeIcon from '@assets/icons/buttons/close.svg';
import { lighten } from 'polished';
import { Paragraph } from '@styles/shared';
import { ModalCloseButton } from '@shared/components/modal/Modal.styles';

export const EventDetailBookingTicketModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EventDetailBookingTicketModalContainer = styled.div`
  position: relative;
  flex: 1 auto;
  height: 100%;
  padding: 48px 20px 20px;
  overflow-y: auto;
`;

export const EventDetailBookingTicketModalFooter = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background-color: ${backgroundColor(400)};
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.08);

  > button {
    display: block;
    margin: auto;
  }
`;

export const EventDetailBookingTicketModalContent = styled.ul`
  margin-top: 28px;
  padding: 5px 16px 15px;
  border: 1px solid ${tertiaryColor(100)};
  border-radius: 20px;

  > li:not(:first-child) {
    border-top: 1px solid ${tertiaryColor(100)};
  }
`;

export const EventDetailBookingTicketModalContentTicket = styled.li`
  padding: 16px 0;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const EventDetailBookingTicketModalContentTicketCode = styled(Paragraph)<{ isAlreadyScanned: boolean }>`
  ${({ isAlreadyScanned }) =>
    isAlreadyScanned &&
    css`
      color: ${secondaryColor(500)};
    `}
`;

export const EventDetailBookingTicketModalContentAlreadyScannedStatus = styled.span`
  display: flex;
  align-items: center;
  width: fit-content;
  min-height: 24px;
  padding: 0 10px;
  border-radius: 4px;
  color: ${secondaryColor(500)};
  background-color: ${props => lighten(0.2, secondaryColor(500)(props))};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
  margin-left: 5px;
`;

export const EventDetailBookingTicketModalErrorContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 0 12px 0 17px;
  background-color: ${secondaryColor(500)};

  > button {
    width: 28px;
    height: 28px;
    border: 1px solid ${backgroundColor(400)};
    border-radius: 50%;
    background: url(${closeIcon}) no-repeat center rgba(255, 255, 255, 0.2);
    background-size: 9px 9px;

    :hover {
      background: url(${closeIcon}) no-repeat center;
    }
  }
`;

export const EventDetailBookingTicketModalCloseButton = styled(ModalCloseButton)`
  top: 15px;
  right: 15px;
`;
