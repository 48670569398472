import React, { CSSProperties, FC, useLayoutEffect } from 'react';
import * as Styled from './CalendarEventGridHeader.styles';
import { Button, Paragraph } from '@styles/shared';
import { Profile } from '@modules/auth/model';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';

import citymagLogo from '@assets/logo/citymag-light.svg';
import { useBooleanState } from '@shared/hooks/boolean';
import { useOutsideClickHandler } from '@shared/hooks/events';
import { useAuthContext } from '@modules/auth/context';
import { getLegalLink } from '@modules/legal/utils';
import { LegalPageUid } from '@modules/legal/model';

interface CalendarEventGridHeaderProps {
  profile: Profile;
}

const CalendarEventGridHeader: FC<CalendarEventGridHeaderProps> = ({ profile }) => {
  const [popperOpen, openPopper, closePopper] = useBooleanState();

  const [inputRef, popperRef] = useOutsideClickHandler<HTMLDivElement, HTMLDivElement>(closePopper);

  const { logout } = useAuthContext();

  const { styles, attributes, forceUpdate } = usePopper(inputRef.current, popperRef.current, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-5, 10],
        },
      },
      {
        name: 'flip',
      },
      {
        name: 'preventOverflow',
      },
    ],
  });

  useLayoutEffect(() => {
    if (popperOpen && forceUpdate) {
      forceUpdate();
    }
  }, [popperOpen, forceUpdate]);

  const popperStyle: CSSProperties = popperOpen
    ? { ...styles.popper, zIndex: 1000, boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.1)', borderRadius: 15 }
    : { display: 'none' };

  return (
    <>
      <Styled.CalendarEventGridHeaderContainer>
        <Styled.CalendarEventGridHeaderLogo src={citymagLogo} />
        <div ref={inputRef}>
          <Styled.CalendarEventGridHeaderRight onClick={openPopper}>
            <Paragraph color="white" size="medium" weight="medium">
              {profile.firstName} {profile.lastName}
            </Paragraph>
          </Styled.CalendarEventGridHeaderRight>
        </div>
      </Styled.CalendarEventGridHeaderContainer>
      {createPortal(
        <div ref={popperRef} style={popperStyle} className="icon-picker-popper" {...attributes.popper}>
          {popperOpen ? (
            <Styled.CalendarEventGridHeaderPopperContainer>
              <Paragraph weight="bold">
                {profile.firstName} {profile.lastName}
              </Paragraph>
              <div>
                <a href={getLegalLink(LegalPageUid.LegalNotice)} target="_blank" rel="noopener noreferrer">
                  Mentions Légales
                </a>
                <a href={getLegalLink(LegalPageUid.PrivacyPolicy)} target="_blank" rel="noopener noreferrer">
                  Politique de Confidentialité
                </a>
              </div>
              <Button onClick={logout}>Se déconnecter</Button>
            </Styled.CalendarEventGridHeaderPopperContainer>
          ) : null}
        </div>,
        document.body,
      )}
    </>
  );
};
export default CalendarEventGridHeader;
