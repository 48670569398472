import styled from 'styled-components';
import { Button } from '@styles/shared';
import backIcon from '@assets/icons/nav/back-light.svg';

export const EventDetailBookingsHeaderContainer = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  isolation: isolate;
  width: 100%;
  height: 73px;
  display: flex;
  align-items: center;
  padding: 20px 10px 20px 17px;
  overflow: hidden;

  p {
    line-height: 1;
  }
`;

export const EventDetailBookingsHeaderMask = styled.div`
  position: absolute;
  z-index: -5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
`;

export const EventDetailBookingsHeaderBackground = styled.div`
  > img {
    position: absolute;
    z-index: -10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
  }
`;

export const EventDetailBookingsHeaderBackLink = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 0;
  border-radius: 50%;
  background: url(${backIcon}) no-repeat center;

  :hover {
    background: url(${backIcon}) no-repeat center;
  }
`;
