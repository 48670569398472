import React, { PureComponent, ReactNode } from 'react';
import { DEFAULT_RANGE_SIZE, VirtualizedGridProps } from '../model';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Index,
  IndexRange,
  InfiniteLoader,
  List,
  ListRowProps,
  WindowScroller,
} from 'react-virtualized';
import { PAGE_SCROLLER_ID } from '@layout/page/Page';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import { pipe } from 'fp-ts/function';
import { renderOptional } from '@shared/utils/render';
import isEqual from 'lodash.isequal';
import { Paragraph } from '@styles/shared';

const MIN_ROW_HEIGHT = 45;

export class VirtualizedGrid<T> extends PureComponent<VirtualizedGridProps<T>> {
  private readonly cache: CellMeasurerCache;

  private scrollElement: HTMLElement | null = null;

  private listRef: List | null = null;

  constructor(props: VirtualizedGridProps<T>) {
    super(props);

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: MIN_ROW_HEIGHT,
    });
  }

  componentDidMount() {
    this.scrollElement = document.getElementById(PAGE_SCROLLER_ID);

    this.loadMoreRows({ startIndex: 0, stopIndex: Math.ceil(DEFAULT_RANGE_SIZE / this.props.chunkSize) });
  }

  componentDidUpdate(prevProps: Readonly<VirtualizedGridProps<T>>) {
    if (!isEqual(prevProps.range.filter, this.props.range.filter)) {
      this.listRef?.scrollToRow(0);
    }

    if (prevProps.range.items !== this.props.range.items) {
      this.cache.clearAll();
      this.listRef?.forceUpdateGrid();
    }
  }

  private getChunk = (index: number): O.Option<Array<T>> => {
    return pipe(
      NEA.range(
        index * this.props.chunkSize,
        Math.min((index + 1) * this.props.chunkSize - 1, this.props.range.total - 1),
      ),
      NEA.traverse(O.Applicative)(index => this.props.range.get(index)),
    );
  };

  private isRowLoaded = ({ index }: Index): boolean => O.isSome(this.getChunk(index));

  private loadMoreRows = ({ startIndex, stopIndex }: IndexRange) => {
    return this.props.loadMore({
      startIndex: startIndex * this.props.chunkSize,
      endIndex: Math.max(startIndex + 1, stopIndex) * this.props.chunkSize - 1,
    });
  };

  private rowRenderer = (props: ListRowProps): ReactNode => {
    const chunk = this.getChunk(props.index);

    return (
      <CellMeasurer key={props.key} cache={this.cache} columnIndex={0} rowIndex={props.index} parent={props.parent}>
        {({ registerChild }) => (
          <div ref={registerChild as any} style={props.style}>
            {renderOptional(chunk, chunk => this.props.children(chunk))}
          </div>
        )}
      </CellMeasurer>
    );
  };

  private noRowsRenderer = (): JSX.Element => {
    if (this.props.range.loading) {
      return <></>;
    }

    if (this.props.emptyMessage) {
      return <div style={{ height: '10000px' }}>{this.props.emptyMessage}</div>;
    }

    return (
      <Paragraph size="small" color="tertiary" colorKey={500} weight="bold" style={{ marginTop: 20 }}>
        Aucune donnée à afficher
      </Paragraph>
    );
  };

  private registerListRef = (infiniteLoaderRegister: (ref: List | null) => void) => (ref: List | null) => {
    infiniteLoaderRegister(ref);
    this.listRef = ref;
  };

  render() {
    const { range, chunkSize } = this.props;

    const total = Math.ceil(range.total / chunkSize);

    const minimumBatchSize = Math.floor(DEFAULT_RANGE_SIZE / chunkSize);

    return !range.loading && range.total === 0 ? (
      this.noRowsRenderer()
    ) : (
      <InfiniteLoader
        rowCount={total}
        loadMoreRows={this.loadMoreRows}
        isRowLoaded={this.isRowLoaded}
        minimumBatchSize={minimumBatchSize}
        threshold={15}
      >
        {({ onRowsRendered, registerChild }) => (
          <WindowScroller scrollElement={this.scrollElement ?? window}>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    ref={this.registerListRef(registerChild)}
                    deferredMeasurementCache={this.cache}
                    autoHeight
                    rowCount={total}
                    rowHeight={this.cache.rowHeight}
                    estimatedRowSize={400}
                    width={width}
                    height={height}
                    onRowsRendered={onRowsRendered}
                    onScroll={onChildScroll}
                    rowRenderer={this.rowRenderer}
                    isScrolling={isScrolling}
                    scrollTop={scrollTop}
                    style={{ outline: 'none' }}
                  />
                )}
              </AutoSizer>
            )}
          </WindowScroller>
        )}
      </InfiniteLoader>
    );
  }
}
