import styled from 'styled-components';
import { backgroundColor } from '@styles/utils';

export const ViewFinderContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 237px;
  max-height: 237px;
  border-radius: 15px;
  box-shadow: 0 0 0 max(100vw, 100vh) rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

export const ViewFinderTop = styled.div`
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 72px;

  :before {
    content: '';
    position: absolute;
    left: -10px;
    width: 73px;
    height: 100%;
    border-top: 4px solid ${backgroundColor(400)};
    border-left: 4px solid ${backgroundColor(400)};
    border-radius: 4px 2px 0 2px;
  }

  :after {
    content: '';
    position: absolute;
    right: -10px;
    width: 73px;
    height: 100%;
    border-top: 4px solid ${backgroundColor(400)};
    border-right: 4px solid ${backgroundColor(400)};
    border-radius: 2px 4px 2px 0;
  }
`;

export const ViewFinderBottom = styled.div`
  position: absolute;
  bottom: -6px; // bottom - border size
  left: 0;
  width: 100%;
  height: 72px;

  :before {
    content: '';
    position: absolute;
    left: -10px;
    width: 73px;
    height: 100%;
    border-bottom: 4px solid ${backgroundColor(400)};
    border-left: 4px solid ${backgroundColor(400)};
    border-radius: 2px 0 2px 4px;
  }

  :after {
    content: '';
    position: absolute;
    right: -10px;
    width: 73px;
    height: 100%;
    border-bottom: 4px solid ${backgroundColor(400)};
    border-right: 4px solid ${backgroundColor(400)};
    border-radius: 0 2px 4px 2px;
  }
`;
