import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import * as O from 'fp-ts/Option';
import { constVoid } from 'fp-ts/function';

export interface LayoutBack {
  title: string;
  to: string;
}

export interface LayoutContextValue {
  back: O.Option<LayoutBack>;
  setBack: Dispatch<SetStateAction<O.Option<LayoutBack>>>;
}

export const LayoutContext = createContext<LayoutContextValue>({
  back: O.none,
  setBack: constVoid,
});

export const LayoutContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [back, setBack] = useState<O.Option<LayoutBack>>(O.none);

  const ctx: LayoutContextValue = useMemo(() => ({ back, setBack }), [back]);

  return <LayoutContext.Provider value={ctx}>{children}</LayoutContext.Provider>;
};

export function useLayoutContext(): LayoutContextValue {
  return useContext(LayoutContext);
}

export function useBackLink(title: string, to: string) {
  const { setBack } = useLayoutContext();

  useEffect(() => {
    setBack(O.some({ title, to }));

    return () => {
      setBack(O.none);
    };
  }, [setBack, title, to]);
}
