import { BackgroundColor, DefaultTheme, PrimaryColor, SecondaryColor, TertiaryColor } from 'styled-components';

const primary: PrimaryColor = {
  300: '#e5faf7',
  400: '#c5f3ed',
  500: '#06c9b2',
  600: '#00aa96',
};

const secondary: SecondaryColor = {
  400: '#ff9ea0',
  500: '#ff6969',
  600: '#d74b3e',
};

const tertiary: TertiaryColor = {
  100: '#e8e9ea',
  200: '#cacbce',
  300: '#95969e',
  400: '#5b627c',
  500: '#3e4662',
  600: '#2f354b',
};

const backgroundColors: BackgroundColor = {
  100: '#fbf6f0',
  200: '#f3f3f3',
  300: '#fdfdfd',
  400: '#ffffff',
};

const theme: DefaultTheme = {
  colors: {
    primary,
    secondary,
    tertiary: tertiary,
    background: backgroundColors,
    white: '#ffffff',
    black: '#000000',
  },
  font: {
    family: "'Montserrat', sans-serif",
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    sizes: {
      h1: '30px',
      h2: '24px',
      h3: '20px',
      h4: '19px',
      h5: '16px',
      large: '16px',
      standard: '14px',
      medium: '12px',
      small: '11px',
      extraSmall: '10px',
      ultraSmall: '9px',
    },
  },
  breakpoints: {
    large: 1280,
    desktop: 960,
    tablet: 760,
    mobile: 560,
  },
  spacing: {
    xs: '4px',
    s: '8px',
    m: '12px',
  },
};

export default theme;
