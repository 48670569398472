import { pipe } from 'fp-ts/function';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as O from 'fp-ts/Option';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import Seo, { SeoProps } from '@shared/modules/seo/Seo';
import { renderNullable, renderOptional } from '@shared/utils/render';
import * as Styled from './Page.styles';
import Tabs, { Tab } from './tabs/Tabs';

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface PageProps {
  title?: ReactNode;
  tabs?: Array<Tab>;
  seoTitle?: string;
  bottom?: ReactNode;
  full?: boolean;
  viewport?: string;
}

const Page: FC<PropsWithChildren<PageProps>> = ({ title, tabs, seoTitle, bottom, full, viewport, children }) => {
  const seo = useMemo<SeoProps>(
    () =>
      pipe(
        O.fromNullable(seoTitle),
        O.alt(() =>
          pipe(
            O.some(title),
            O.filterMap(title => (typeof title === 'string' ? O.some(title) : O.none)),
          ),
        ),
        O.fold(
          () => ({}),
          title => ({ title }),
        ),
      ),
    [title, seoTitle],
  );

  const titleValue = O.fromNullable(title);

  const tabList = pipe(O.fromNullable(tabs), O.chain(NEA.fromArray));

  return (
    <Styled.PageContainer>
      <Seo {...seo} viewport={viewport} />

      {O.isSome(titleValue) || O.isSome(tabList) ? (
        <Styled.PageTop>
          <Styled.PageTopContent>
            {renderOptional(titleValue, title => (
              <Styled.PageTopTitle as={typeof title !== 'string' ? 'div' : undefined}>{title}</Styled.PageTopTitle>
            ))}

            {renderOptional(tabList, tabs => (
              <Tabs tabs={tabs} />
            ))}
          </Styled.PageTopContent>
        </Styled.PageTop>
      ) : (
        <div />
      )}

      <Styled.PageContentWrapper id={PAGE_SCROLLER_ID} full={full}>
        <Styled.PageContent full={full}>{children}</Styled.PageContent>
      </Styled.PageContentWrapper>

      {renderNullable(bottom, bottom => (
        <Styled.PageBottom>{bottom}</Styled.PageBottom>
      ))}
    </Styled.PageContainer>
  );
};

export * from './hooks';

export default Page;
