import React, { FC, PropsWithChildren, Suspense } from 'react';
import { Route as ReactRoute, Routes as RoutesRouter } from 'react-router-dom';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import ErrorPage from '@shared/components/error-page/ErrorPage';

export const Routes: FC<PropsWithChildren> = ({ children }) => (
  <Suspense fallback={<DebouncedLineLoader />}>
    <RoutesRouter>
      {children}
      <ReactRoute path="*" element={<ErrorPage />} />
    </RoutesRouter>
  </Suspense>
);
