import React, { FC } from 'react';
import { Routes, useIdParam } from '@core/router';
import { Route } from 'react-router-dom';
import { CalendarEventId } from '@modules/events/model';
import { renderNullable } from '@shared/utils/render';
import EventDetailControl from '@modules/events/components/detail/control/EventDetailControl';
import EventDetailBookings from '@modules/events/components/detail/bookings/EventDetailBookings';
import { SummaryProvider } from '@modules/events/components/detail/control/context';

const EventScanDetailRoutes: FC = () => {
  const id = useIdParam<CalendarEventId>();
  return renderNullable(id, id => <EventScanDetailRoutesInner id={id} />);
};

interface EventScanDetailRoutesInnerProps {
  id: CalendarEventId;
}

const EventScanDetailRoutesInner: FC<EventScanDetailRoutesInnerProps> = ({ id }) => {
  return (
    <SummaryProvider id={id}>
      <Routes>
        <Route path="/" element={<EventDetailControl id={id} />} />
        <Route path="/bookings" element={<EventDetailBookings id={id} />} />
      </Routes>
    </SummaryProvider>
  );
};

export default EventScanDetailRoutes;
