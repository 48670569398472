import axios from 'axios';

import { v4 as uuid } from 'uuid';
import { stringifyQueries } from '@shared/utils/queries';
import { TRANSACTION_ID_HEADER_KEY } from '@core/http/model';

export const defaultAxiosInstance = axios.create({
  paramsSerializer: stringifyQueries,
  withCredentials: true,
  baseURL: '/scan',
});

defaultAxiosInstance.interceptors.request.use(config => ({
  ...config,
  headers: {
    ...config.headers,
    [TRANSACTION_ID_HEADER_KEY]: uuid(),
  },
}));
