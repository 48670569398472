import { useState, useEffect } from 'react';
import { CustomPermissionName } from '@shared/modules/permissions/model';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';
import * as RD from 'fp-ts-remote-data';
import { flow, pipe } from 'fp-ts/function';

export const useCustomPermission = (name: CustomPermissionName) => {
  const [permission, setPermission] = useState<RD.RemoteData<Error, PermissionStatus>>(RD.pending);

  useEffect(() => {
    const controller = new AbortController();

    pipe(
      TE.tryCatch(() => navigator.permissions.query({ name: name as PermissionName }), E.toError),
      TE.chainFirstIOK(status => () => {
        status.addEventListener(
          'change',
          function () {
            setPermission(RD.success(this));
          },
          { passive: true, signal: controller.signal },
        );
      }),
      T.map(flow(RD.fromEither, setPermission)),
    )();

    return () => controller.abort();
  }, [name]);

  return permission;
};
