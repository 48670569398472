import { useEffect } from 'react';

export function useHideScrollBar(hide?: boolean | null) {
  useEffect(() => {
    if (hide) {
      document.body.classList.add('hide-scrollbar');
      document.documentElement.classList.add('hide-scrollbar');
    } else {
      document.body.classList.remove('hide-scrollbar');
      document.documentElement.classList.remove('hide-scrollbar');
    }

    return () => {
      document.body.classList.remove('hide-scrollbar');
      document.documentElement.classList.remove('hide-scrollbar');
    };
  }, [hide]);
}
