import styled, { css } from 'styled-components';

import { Title } from '@styles/shared';
import { backgroundColor, mediaMax, tertiaryColor } from '@styles/utils';

export const PageContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr auto;
`;

export const PageTop = styled.div`
  padding: 0 20px;
  background: ${backgroundColor()};
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.04);
  min-width: 0;
`;

export const PageTopContent = styled.div`
  max-width: 1150px;
  margin: 0 auto;
`;

export const PageTopTitle = styled(Title)`
  padding: 15px 0 10px;
`;

export const PageContentWrapper = styled.div<{ full?: boolean }>`
  padding: 25px 0 0;
  overflow-x: hidden;
  overflow-y: auto;

  ${props =>
    props.full &&
    css`
      padding: 0;
    `}
`;

export const PageContent = styled.div<{ full?: boolean }>`
  display: grid;
  min-width: 0;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1000px;

  @media screen and (${mediaMax.tablet}) {
    padding: 0;
  }

  ${props =>
    props.full &&
    css`
      padding: 0;
      margin: 0;
      max-width: none;
    `}
`;

export const PageBottom = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 30px;
  background: ${backgroundColor()};
  border-top: 1px solid ${tertiaryColor(100)};

  @media screen and (${mediaMax.mobile}) {
    padding: 8px 20px;
  }
`;
