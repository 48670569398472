import React from 'react';

import { FieldName, FieldErrors } from 'react-hook-form';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import { Paragraph } from '@styles/shared';
import { FieldValues } from 'react-hook-form/dist/types/fields';

interface FormErrorMessageProps<T extends FieldValues> {
  errors: FieldErrors<T>;
  name: FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>;
}

function FormErrorMessage<T extends FieldValues>({ errors, name }: FormErrorMessageProps<T>) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <Paragraph color="secondary" size="small">
          {message}
        </Paragraph>
      )}
    />
  );
}

export default FormErrorMessage;
