import styled, { css, keyframes } from 'styled-components';
import { backgroundColor, fontSize, mediaMax, rgba, tertiaryColor } from '@styles/utils';
import { shouldForwardPropHelper } from '@styles/utils/props';

export const VirtualizedTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid ${tertiaryColor(200)};
  border-radius: 20px;
  overflow: hidden;

  @media screen and (${mediaMax.tablet}) {
    border-radius: 0;
    border: none;
  }
`;

export const VirtualizedTableContent = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

const loadingPlaceholder = keyframes`
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
`;

export const VirtualizedTableRowWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    shouldForwardPropHelper(prop, defaultValidatorFn, ['loading', 'hover']),
})<{ loading?: boolean; hover?: boolean }>`
  background: ${backgroundColor(400)};
  font-size: ${fontSize.medium};
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${tertiaryColor(100)};
  }

  ${props =>
    props.loading &&
    css`
      overflow: hidden;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        background-image: linear-gradient(
          to right,
          ${rgba(tertiaryColor(100), 0.1)} 5%,
          ${rgba(tertiaryColor(100), 0.5)} 15%,
          ${rgba(tertiaryColor(100), 0.3)} 70%,
          ${rgba(tertiaryColor(100), 0.2)} 10%
        );
        background-size: 200%;
        transform: translateX(0);
        animation: 2s ${loadingPlaceholder} ease-in-out infinite;
      }
    `}

  ${props =>
    props.hover &&
    css`
      transition: background 0.15s linear;

      &:hover {
        background: ${rgba(tertiaryColor(100), 0.3)};
        cursor: pointer;
      }
    `}
`;

export const VirtualizedTableNoRow = styled(VirtualizedTableRowWrapper)`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  will-change: unset;
`;
