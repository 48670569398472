import { useEffect, useRef } from 'react';
import { BrowserQRCodeReader, IScannerControls } from '@zxing/browser';
import { UseQrReaderHook } from './types';
import { isMediaDevicesSupported } from './utils';
import { constVoid, identity, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import * as IOE from 'fp-ts/IOEither';
import * as IO from 'fp-ts/IO';

export const useQrReader: UseQrReaderHook = ({
  scanDelay: delayBetweenScanAttempts,
  constraints: video,
  onSuccess = constVoid,
  onError = constVoid,
  videoId,
}) => {
  const controlsRef = useRef<O.Option<IScannerControls>>(O.none);

  const callbackDecodeToTask = (codeReader: BrowserQRCodeReader, video: MediaTrackConstraints, videoId?: string) =>
    TE.tryCatch(
      () =>
        codeReader.decodeFromConstraints({ video }, videoId, (result, error) => {
          pipe(
            O.fromNullable(result),
            IOE.fromOption(constVoid),
            IOE.map(onSuccess),
            IOE.alt(() => pipe(O.fromNullable(error), IOE.fromOption(constVoid), IOE.map(onError))),
          )();
        }),
      identity,
    );

  useEffect(() => {
    const codeReader = new BrowserQRCodeReader(undefined, {
      delayBetweenScanAttempts,
    });

    pipe(
      codeReader,
      TE.fromPredicate(
        isMediaDevicesSupported,
        () =>
          new Error(
            'MediaDevices API has no support for your browser. You can fix this by running "npm i webrtc-adapter"',
          ),
      ),
      TE.chain(codeReader =>
        pipe(
          video,
          TE.fromPredicate(
            (video): video is MediaTrackConstraints => !!video,
            () => new Error('Video constraints are undefined'),
          ),
          TE.chain(video => callbackDecodeToTask(codeReader, video, videoId)),
          TE.chainIOK(controls =>
            pipe(
              IO.of(O.some(controls)),
              IO.map(controls => {
                controlsRef.current = controls;
                return controls;
              }),
            ),
          ),
        ),
      ),
      TE.mapLeft(onError),
    )();

    return () => {
      pipe(
        controlsRef.current,
        IOE.fromOption(constVoid),
        IOE.map(controls => controls.stop()),
      )();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlsRef, delayBetweenScanAttempts, videoId]);

  return controlsRef;
};
