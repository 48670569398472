import styled from 'styled-components';
import { backgroundColor, fontSize, fontWeight, tertiaryColor } from '@styles/utils';

export const VirtualizedTableContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const VirtualizedTableHeader = styled.div`
  display: grid;
  align-items: center;
  padding: 15px 25px 15px 20px;
  background: ${backgroundColor()};
  border-bottom: 1px solid ${tertiaryColor(100)};
  font-size: ${fontSize.extraSmall};
  font-weight: ${fontWeight.bold};
  color: ${tertiaryColor(300)};
  text-transform: uppercase;
`;

export const VirtualizedTableRow = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  padding: 15px 25px 15px 20px;
`;
