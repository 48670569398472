import React, { FC } from 'react';
import Modal from '@shared/components/modal/Modal';
import { Button, Link, Paragraph } from '@styles/shared';
import * as IO from 'fp-ts/IO';
import * as Styled from './DirtyFormCloseModal.styles';
import { sequenceT } from 'fp-ts/Apply';

interface DirtyFormCloseModalProps {
  isOpen: boolean;
  closeDirtyModal: IO.IO<void>;
  closeTicketsModal: IO.IO<void>;
  resetForm: IO.IO<void>;
}

export const DirtyFormCloseModal: FC<DirtyFormCloseModalProps> = ({
  isOpen,
  closeDirtyModal,
  closeTicketsModal,
  resetForm,
}) => {
  const title = (
    <Paragraph size="large" weight="bold" color="tertiary" colorKey={500}>
      Quitter sans valider ?
    </Paragraph>
  );

  const handleQuit = sequenceT(IO.Apply)(closeDirtyModal, closeTicketsModal, resetForm);

  return (
    <Modal isOpen={isOpen} title={title} onRequestClose={closeDirtyModal}>
      <Paragraph size="large" weight="bold" color="tertiary" colorKey={500} style={{ paddingBottom: 5 }}>
        Quitter sans valider ?
      </Paragraph>
      <Paragraph size="large" color="tertiary" colorKey={600}>
        Vos changements ne seront pas enregistrés.
      </Paragraph>
      <Styled.DirtyFormCloseModalButtons>
        <Link as={Button} onClick={closeDirtyModal}>
          Annuler
        </Link>
        <Button onClick={handleQuit}>Quitter sans valider</Button>
      </Styled.DirtyFormCloseModalButtons>
    </Modal>
  );
};
