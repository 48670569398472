import { useEffect, useRef } from 'react';
import { logSentryMessage } from '@shared/modules/sentry/utils';
import { IOSCustomEvent, IOSEventType } from '@shared/modules/platform/model';
import * as JSON from 'fp-ts/Json';
import * as IOE from 'fp-ts/IOEither';
import * as E from 'fp-ts/Either';
import * as IO from 'fp-ts/IO';
import { sequenceT } from 'fp-ts/Apply';
import { pipe, constVoid } from 'fp-ts/function';

export function useIOSEvent<T = unknown>(type: IOSEventType, handler: (data: T) => void) {
  const savedHandler = useRef<(data: T) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const handleEvent = (e: IOSCustomEvent) =>
      pipe(
        JSON.parse(e.detail),
        IOE.fromEither,
        IOE.chainW((data: any) => pipe(data, IOE.tryCatchK(savedHandler.current ?? constVoid, E.toError))),
        IOE.getOrElseW(handleError),
      )();

    const handleError = (e: unknown) =>
      sequenceT(IO.Apply)(
        () => console.log(e),
        () =>
          logSentryMessage('[ios] error during ios event processing', 'error', {
            event: e,
            error: e,
          }),
      );

    window.addEventListener(type, handleEvent);

    return () => {
      window.removeEventListener(type, handleEvent);
    };
  }, [type]);
}
