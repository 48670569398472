import styled, { css } from 'styled-components';
import { backgroundColor, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';

export const EventDetailControlBodyContainer = styled.div`
  width: 100%;
`;

export const EventDetailControlBodyList = styled.div`
  width: 100%;
  border-radius: 15px;
  background-color: ${backgroundColor(100)};
  padding: 14px 0;

  span {
    font-size: 16px;
    font-weight: ${fontWeight.bold};
    padding: 0 3px;
  }
`;

export const EventDetailControlBodyListHead = styled.div<{ free: boolean }>`
  width: 100%;
  padding: 0 18px 18px;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    padding: 0 7px 9px;
  }

  p {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  ${({ free }) =>
    !free &&
    css`
      border-bottom: 2px solid ${backgroundColor(400)};
    `}
`;

export const EventDetailControlBodyProgressBarEmpty = styled.div`
  position: relative;
  width: 100%;
  height: 14px;
  background-color: ${backgroundColor(400)};
  border-radius: 7px;
`;

export const EventDetailControlBodyProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${primaryColor(500)};
  border-radius: 7px;
`;

export const EventDetailControlBodyListContent = styled.ul`
  padding: 20px 18px 0;

  > li:not(:last-child) {
    border-bottom: 1px solid ${tertiaryColor(100)};
  }
`;

export const EventDetailControlBodyListElement = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  p {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  > p {
    flex: 0 0 auto;
    padding-left: 10px;
  }
`;
