import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { capitalizeFirstLetter } from './string';
import { sequenceT } from 'fp-ts/Apply';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';
import { CalendarDate, CalendarDateType } from '@shared/modules/calendar/model';

function getMonth(date: Date): string {
  return capitalizeFirstLetter(formatDate(date, 'MMM'));
}

function getDayNumber(date: Date): string {
  return formatDate(date, 'dd');
}

function getDateRangeFullDate(start: Date, end: Date): string {
  if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
    return `Du ${formatDate(start, 'dd')} au ${formatDate(end, 'dd MMMM')}`;
  } else {
    return `Du ${formatDate(start, 'dd MMMM')} au ${formatDate(end, 'dd MMMM')}`;
  }
}

interface CalendarDisplayDateItem {
  day: string;
  month: string;
}

interface CalendarDisplayDate {
  start: CalendarDisplayDateItem;
  end: O.Option<CalendarDisplayDateItem>;
  full: string;
}

const defaultDisplayDate: CalendarDisplayDate = {
  start: {
    day: '',
    month: '',
  },
  end: O.none,
  full: '',
};

function getDateTimeRangeFullDate(start: Date, end: Date): string {
  if (
    start.getDay() === end.getDay() &&
    start.getMonth() === end.getMonth() &&
    start.getFullYear() === end.getFullYear()
  ) {
    return capitalizeFirstLetter(`${formatDate(start, "EEEE dd MMM 'de' HH:mm")} à ${formatDate(end, 'HH:mm')}`);
  } else {
    return `Du ${formatDate(start, 'dd MMMM HH:mm')} au ${formatDate(end, 'dd MMMM HH:mm')}`;
  }
}

export const getDisplayDate = (d: CalendarDate) => {
  switch (d.type) {
    case CalendarDateType.Date:
      return pipe(
        parseDate(d.value, DateFormat.Local),
        O.map(d => ({
          start: {
            day: getDayNumber(d),
            month: getMonth(d),
          },
          end: O.none,
          full: capitalizeFirstLetter(formatDate(d, 'EEEE dd MMMM')),
        })),
        O.getOrElse(() => defaultDisplayDate),
      );
    case CalendarDateType.DateTime:
      return pipe(
        parseDate(d.value, DateFormat.LocalDateTime),
        O.map(d => ({
          start: {
            day: getDayNumber(d),
            month: getMonth(d),
          },
          end: O.none,
          full: capitalizeFirstLetter(formatDate(d, 'EEEE dd MMMM à HH:mm')),
        })),
        O.getOrElse(() => defaultDisplayDate),
      );
    case CalendarDateType.DateRange:
      return pipe(
        sequenceT(O.Apply)(parseDate(d.start, DateFormat.Local), parseDate(d.end, DateFormat.Local)),
        O.map(([start, end]) => {
          const startDisplay = {
            day: getDayNumber(start),
            month: getMonth(start),
          };

          const endDisplay = pipe(
            {
              day: getDayNumber(end),
              month: getMonth(end),
            },
            O.fromPredicate(d => d.day !== startDisplay.day || d.month !== startDisplay.month),
          );

          return {
            start: startDisplay,
            end: endDisplay,
            full: getDateRangeFullDate(start, end),
          };
        }),
        O.getOrElse(() => defaultDisplayDate),
      );
    case CalendarDateType.DateTimeRange:
      return pipe(
        sequenceT(O.Apply)(parseDate(d.start, DateFormat.LocalDateTime), parseDate(d.end, DateFormat.LocalDateTime)),
        O.map(([start, end]) => {
          const startDisplay = {
            day: getDayNumber(start),
            month: getMonth(start),
          };

          const endDisplay = pipe(
            {
              day: getDayNumber(end),
              month: getMonth(end),
            },
            O.fromPredicate(d => d.day !== startDisplay.day || d.month !== startDisplay.month),
          );

          return {
            start: startDisplay,
            end: endDisplay,
            full: getDateTimeRangeFullDate(start, end),
          };
        }),
        O.getOrElse(() => defaultDisplayDate),
      );
  }
};
