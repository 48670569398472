import styled from 'styled-components';
import { fontSize, secondaryColor } from '@styles/utils';

import deleteIcon from '@assets/icons/label/delete.svg';
import deleteRedIcon from '@assets/icons/label/delete-red.svg';

export const ActiveFilterContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 5px;
`;

export const ActiveFilterItem = styled.span`
  position: relative;
  padding: 2px 22px 2px 10px;
  margin: 3px 2px;
  background: #eaeaea;
  border: 1px solid rgba(181, 181, 181, 0.42);
  border-radius: 100px;
  font-size: ${fontSize.extraSmall};
`;

export const ActiveFilterDeleteButton = styled.button`
  position: absolute;
  top: calc(50% - 10px);
  right: 0;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: url(${deleteIcon}) no-repeat top 6px center;
  cursor: pointer;
`;

export const ActiveFilterDeleteAll = styled(ActiveFilterItem)`
  border-color: ${secondaryColor(400)};
  background-color: rgba(235, 113, 107, 0.1);
  color: ${secondaryColor(400)};
  font-style: italic;

  > button {
    background-image: url(${deleteRedIcon});
  }
`;
