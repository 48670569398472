import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';
import listIcon from '@assets/icons/buttons/list.svg';
import qrIcon from '@assets/icons/buttons/qr.svg';

export const EventDetailControlBottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 18px 12px 16px;

  border-top: 1px solid ${tertiaryColor(100)};

  //scan web and ios
  > button,
  > a:not(:first-child) {
    :before {
      content: '';
      margin-right: 15px;
      min-width: 23px;
      height: 23px;
      background: url(${qrIcon}) no-repeat center;
    }
  }

  // to bookings
  > a:first-child {
    margin-right: 10px;
    border: 3px solid ${primaryColor(500)};

    :before {
      content: '';
      margin-right: 15px;
      min-width: 25px;
      height: 23px;
      background: url(${listIcon}) no-repeat center top;
    }
  }

  //all
  > a,
  > button {
    display: flex;
    align-items: center;
    height: 59px;
    border-radius: 31px;
    font-size: ${fontSize.large};
    font-weight: ${fontWeight.bold};
    padding: 20px 22px;
  }
`;
