import { BreakPoints, useTheme } from 'styled-components';
import { mediaMax, mediaMin } from '@styles/utils';

export function useImageBreakPoints() {
  const theme = useTheme();

  const imageMediaMin = (breakpoint: Extract<keyof BreakPoints, string>) => `(${mediaMin[breakpoint]({ theme })})`;
  const imageMediaMax = (breakpoint: Extract<keyof BreakPoints, string>) => `(${mediaMax[breakpoint]({ theme })})`;

  return { imageMediaMin, imageMediaMax };
}
