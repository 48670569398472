import React, { FC } from 'react';
import * as Styled from './EventDetailControlScanProgress.styles';
import { Paragraph } from '@styles/shared';
import { renderOptional } from '@shared/utils/render';
import clockIcon from '@assets/icons/shared/clock.svg';
import { CalendarDate } from '@shared/modules/calendar/model';
import { getScanDisplayDateLast } from '@modules/events/components/detail/utils';

interface EventDetailControlScanProgressProps {
  scanned: number;
  total: number;
  date: CalendarDate;
}

const EventDetailControlScanProgress: FC<EventDetailControlScanProgressProps> = ({ scanned, total, date }) => {
  const last = getScanDisplayDateLast(date);

  return (
    <Styled.EventDetailControlScanProgressContainer>
      <div>
        <Paragraph size="small" color="tertiary" colorKey={600}>
          <span>{scanned}</span> / {total}
        </Paragraph>
        {renderOptional(last, last => (
          <div>
            <img src={clockIcon} alt="Horaire" />
            <Paragraph size="medium" color="tertiary" colorKey={500}>
              Début {last}
            </Paragraph>
          </div>
        ))}
      </div>
      <Styled.EventDetailControlScanProgressBarEmpty>
        <Styled.EventDetailControlScanProgressBar style={{ width: `${(scanned * 100) / total}%` }} />
      </Styled.EventDetailControlScanProgressBarEmpty>
    </Styled.EventDetailControlScanProgressContainer>
  );
};
export default EventDetailControlScanProgress;
