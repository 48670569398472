import { ParsedQuery } from 'query-string';
import { ReactNode } from 'react';
import { getSearchQuery } from './utils';

export type Filter = Record<string, string | number | boolean | Array<string> | Array<number> | null>;

export type SearchFilter = Filter & {
  search: string | null;
};

export const defaultSearchFilter: SearchFilter = {
  search: null,
};

export type FilterQueryParser<F extends Filter> = (query: ParsedQuery) => F;

export const searchFilterParser: FilterQueryParser<SearchFilter> = query => ({
  search: getSearchQuery(query),
});

export interface FilterModalProps<F extends Filter> {
  filter: F;
  onChange: (filter: F) => void;
}

export type FilterChildren<F extends Filter> = (props: FilterModalProps<F>) => ReactNode;

export interface ActiveFilter<F extends Filter> {
  title: string;
  onDelete: (filter: F) => F;
}

export type ActiveFilters<F extends Filter> = Array<ActiveFilter<F>>;

export type ActiveFiltersGetter<F extends Filter> = (filter: F) => ActiveFilters<F>;
