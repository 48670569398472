import React, { FC } from 'react';
import { BucketResource, BucketResourceDisplayStrategy, BucketResourceType, imgixParamsByStrategy } from '../../model';

import * as Styled from './BucketImage.styles';
import Imgix, { SharedImgixAndSourceProps } from 'react-imgix';

import placeholder from '@assets/images/placeholder.svg';
import { renderOptional } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';

import * as O from 'fp-ts/Option';

interface BucketImageProps extends Omit<SharedImgixAndSourceProps, 'src'> {
  image?: BucketResource | null;
  ratio?: number;
  strategy?: BucketResourceDisplayStrategy;
  className?: string;
}

const BucketImage: FC<BucketImageProps> = ({ image, ratio, strategy = 'crop', className, imgixParams, ...rest }) => {
  const img = pipe(
    O.fromNullable(image),
    O.filter(image => BucketResourceType.Image === image.type),
  );

  return (
    <Styled.BucketImageContainer ratio={ratio} className={className}>
      {renderOptional(
        img,
        image => (
          <Imgix
            src={image.url}
            {...rest}
            imgixParams={{ ...imgixParamsByStrategy[strategy], ...imgixParams, ar: `${ratio?.toFixed(2)}:1` }}
            srcSetOptions={{ minWidth: 300, maxWidth: 2000 }}
          />
        ),
        () => (
          <img src={placeholder} alt="placeholder" />
        ),
      )}
    </Styled.BucketImageContainer>
  );
};

export default BucketImage;
