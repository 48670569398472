import React, { FC, useState } from 'react';
import { Button, FormField, Input, Title } from '@styles/shared';
import * as Styled from './LoginForm.styles';
import { AuthenticateParams, authenticateSchema, Profile } from '@modules/auth/model';
import { HttpStatusCode } from '@core/http';
import { useSendTask } from '@core/http/hooks';
import * as AuthService from '../../service';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';
import { renderNullable, renderOptional } from '@shared/utils/render';
import FacebookSignIn from '@modules/auth/components/social/facebook/FacebookSignIn';
import GoogleSignIn from '@modules/auth/components/social/google/GoogleSignIn';
import AppleSignIn from '@modules/auth/components/social/apple/AppleSignIn';
import { useOAuthConfig } from '@modules/auth/hooks';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Message from '@shared/components/message/Message';
import { useAuthContext } from '@modules/auth/context';
import { useNavigate } from 'react-router-dom';
import * as IO from 'fp-ts/IO';
import FormErrorMessage from '@styles/shared/form/FormErrorMessage';

const LoginForm: FC = () => {
  // const [isOpen, openModal, closeModal] = useHashedModal('forgot-password');
  const [oAuthError, setOAuthError] = useState<string | null>(null);
  const { isOAuthConfigured } = useOAuthConfig();
  const [loading, send, error] = useSendTask(AuthService.authenticate, { hideError: true });
  const { updateProfile } = useAuthContext();
  const navigateTo = useNavigate();

  const onSubmit = (params: AuthenticateParams) =>
    pipe(
      () => send(params),
      TE.chainIOK(profile =>
        pipe(
          () => updateProfile(profile),
          IO.chain(() => () => navigateTo('/events')),
        ),
      ),
    )();

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<AuthenticateParams>({
    resolver: zodResolver(authenticateSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleSocialSubmit = (profile: Profile) =>
    pipe(
      () => updateProfile(profile),
      IO.chain(() => () => navigateTo('/events')),
    )();

  return (
    <>
      <div>
        <Title level="h2">Se connecter</Title>

        <Styled.LoginFormDescription color="tertiary" colorKey={600}>
          Connectez-vous avec les identifiants de votre compte Citymag.
        </Styled.LoginFormDescription>

        <Styled.LoginFormInputs onSubmit={handleSubmit(onSubmit)} noValidate>
          {renderOptional(error, error => (
            <Message level="error">
              {HttpStatusCode.UNAUTHORIZED === error.status
                ? 'Email ou mot de passe incorrect.'
                : 'Une erreur technique est survenue.'}
            </Message>
          ))}

          {renderNullable(oAuthError, error => (
            <Message level="error">{error}</Message>
          ))}

          {isOAuthConfigured ? (
            <Styled.LoginOauthContainer>
              <FacebookSignIn onSuccess={handleSocialSubmit} onError={setOAuthError} />
              <AppleSignIn onSuccess={handleSocialSubmit} onError={setOAuthError} />
              <GoogleSignIn onSuccess={handleSocialSubmit} onError={setOAuthError} />

              <Styled.LoginOauthSeparator>
                <span>Ou</span>
              </Styled.LoginOauthSeparator>
            </Styled.LoginOauthContainer>
          ) : null}

          <FormField label="Adresse e-mail">
            <Input
              {...register('email')}
              type="email"
              placeholder="Ex : monadresseemail@citymag.info"
              error={!!errors.email}
            />
            <FormErrorMessage errors={errors} name="email" />
          </FormField>

          <FormField label="Mot de passe">
            <Input type="password" {...register('password')} placeholder="Mot de passe" error={!!errors.password} />
          </FormField>

          {/*<Styled.LoginFormForgotPasswordContainer>*/}
          {/*  <Link as={Button} onClick={openModal} size="standard" weight="medium" underline>*/}
          {/*    Mot de passe oublié ?*/}
          {/*  </Link>*/}
          {/*</Styled.LoginFormForgotPasswordContainer>*/}

          <Styled.LoginFormActions>
            <Button type="submit" loading={loading}>
              Se connecter
            </Button>
          </Styled.LoginFormActions>
        </Styled.LoginFormInputs>
      </div>
      {/*<ForgotPasswordModal isOpen={isOpen} onRequestClose={closeModal} />*/}
    </>
  );
};

export default LoginForm;
