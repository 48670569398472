import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { configureSentry } from '@shared/modules/sentry/utils';

import ReactModal from 'react-modal';

import { registerSW } from 'virtual:pwa-register';

import '@styles/css';

ReactModal.setAppElement('#root');

configureSentry();

registerSW();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
