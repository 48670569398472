import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import * as Styled from './Tabs.styles';

export interface Tab {
  title: string;
  to: string;
  exact?: boolean;
}

interface TabsProps {
  tabs: Array<Tab>;
}

const Tabs: FC<TabsProps> = ({ tabs }) => {
  return (
    <Styled.TabsContainer>
      {tabs.map((tab, i) => (
        <li key={i}>
          <NavLink to={tab.to} end={tab.exact}>
            {tab.title}
          </NavLink>
        </li>
      ))}
    </Styled.TabsContainer>
  );
};

export default Tabs;
