import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import { ToastsStyles } from './ToastsProvider.styles';

interface ToastsProviderProps {}

const ToastsProvider: FC<ToastsProviderProps> = () => (
  <>
    <ToastsStyles />
    <ToastContainer
      toastClassName="citymag-toast"
      hideProgressBar={true}
      newestOnTop
      autoClose={3000}
      closeButton={false}
    />
  </>
);

export default ToastsProvider;
