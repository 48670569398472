import { httpService, HttpTask } from '@core/http';
import { AuthenticateParams, OAuthParams, OAuthProvider, Profile } from '@modules/auth/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

export function getProfile(): HttpTask<Profile> {
  return httpService.get<Profile>('/profile');
}

export function authenticate(params: AuthenticateParams): HttpTask<Profile> {
  return pipe(httpService.post('/authenticate', params), TE.chain(getProfile));
}

export function logout(): HttpTask {
  return httpService.post('/logout');
}

export function authenticateSocial(provider: OAuthProvider, params: OAuthParams): HttpTask<Profile> {
  return pipe(httpService.post(`/authenticate/${provider}`, params), TE.chain(getProfile));
}
