import styled from 'styled-components';

export const EventDetailControlScanLoader = styled.img`
  position: fixed;
  bottom: 25%;
  left: 50%;
  width: 30px;
  height: 30px;

  animation: 3s linear rotating infinite;

  @keyframes rotating {
    from {
      transform: translate(-50%, -50%) rotate(0turn);
    }
    to {
      transform: translate(-50%, -50%) rotate(1turn);
    }
  }
`;
