import { ScanResultStatus } from '@modules/events/model';
import { backgroundColor } from '@styles/utils';
import styled from 'styled-components';

export const EventDetailControlScanResultContainer = styled.div<{ status: ScanResultStatus }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: calc(100% - 75px);
  padding-bottom: 32px;
  cursor: pointer;

  > img:last-child {
    animation: 1s linear translating infinite alternate;

    @keyframes translating {
      from {
        transform: translateY(0) rotate(-2deg);
      }

      to {
        transform: translateY(5px) rotate(2deg);
      }
    }
  }

  background-color: ${({ status, theme }) => {
    switch (status) {
      case ScanResultStatus.Success:
        return theme.colors.primary[400];
      case ScanResultStatus.AlreadyScanned:
        return '#ffe5c4';
      default:
        return '#ffe9e9';
    }
  }};
`;

export const EventDetailControlScanResultContinue = styled.div`
  display: flex;
  align-items: center;
  background-color: ${backgroundColor(400)};
  border-radius: 12px;
  height: 31px;
  padding: 0 26px;

  p {
    line-height: 1;
  }
`;

export const EventDetailControlScanResultTicket = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    line-height: 1.5;
  }

  > div {
    position: absolute;
    left: 62px;

    :first-child {
      top: 18px;
    }

    :last-child {
      bottom: 16px;
    }
  }
`;
