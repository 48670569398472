import styled from 'styled-components';
import { mediaMax } from '@styles/utils';
import closeIcon from '@assets/icons/modal/close.svg';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background: url(${closeIcon}) no-repeat center center;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const ModalContent = styled.div`
  flex: 1 1 auto;
`;

export const ModalActions = styled.div`
  padding-top: 25px;
  text-align: center;

  > button {
    margin: 0 7px;

    @media screen and (${mediaMax.mobile}) {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
`;
