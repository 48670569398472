import { ParsedQuery } from 'query-string';
import { useLocation, useParams } from 'react-router-dom';

import { parseQueries } from '@shared/utils/queries';

export function useIdParam<I extends string>(key: string = 'id'): I | undefined {
  return useParams<{ [key: string]: I }>()[key];
}

export function useTokenParam<I extends string>(): I | undefined {
  return useParams<{ token: I }>().token;
}

export function useQuery(name: string): string | null {
  return new URLSearchParams(useLocation().search).get(name);
}

export function useQueries(): ParsedQuery {
  return parseQueries(useLocation().search);
}
