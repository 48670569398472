import { useState } from 'react';
import { ScanLocalResult, ScanResultAlreadyScanned, ScanResultStatus, ScanResultSuccess } from '@modules/events/model';
import { HttpError, HttpStatusCode, HttpTask } from '@core/http';
import * as IO from 'fp-ts/IO';
import * as IOE from 'fp-ts/IOEither';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { sequenceT } from 'fp-ts/Apply';
import { flow, pipe, constVoid } from 'fp-ts/function';
import { useSummaryContext } from '@modules/events/components/detail/control/context';
import useSound from '@shared/hooks/sound';
import successSound from '@assets/sounds/success.mp3';
import { useSendTask } from '@core/http/hooks';

interface ScanResultCache {
  scanResult: string;
  serverResult: ScanResultSuccess;
}

interface SendQrReaderResult {
  lastResult: O.Option<ScanLocalResult>;
  handleScanSuccess: (result: string) => void;
  resetLastResult: IO.IO<void>;
  loading: boolean;
}

export const useSendQrResult = (
  task: (params: string) => HttpTask<ScanResultSuccess, ScanResultAlreadyScanned>,
): SendQrReaderResult => {
  const { refreshSummary } = useSummaryContext();
  const [lastResult, setLastResult] = useState<O.Option<ScanLocalResult>>(O.none);
  const [successCache, setSuccessCache] = useState<O.Option<ScanResultCache>>(O.none);
  const { replay } = useSound(successSound, {});

  const [loading, sendRequest] = useSendTask(task, {
    hideError: true,
  });

  const handleServerAlreadyScanned = (serverResult: ScanResultAlreadyScanned) =>
    sequenceT(IO.Apply)(
      () => secureVibrate([100, 100, 100, 100, 100]),
      () => setLastResult(O.some({ result: serverResult, status: ScanResultStatus.AlreadyScanned })),
    );

  const handleServerNotFound = sequenceT(IO.Apply)(
    () => secureVibrate([200, 100, 200]),
    () => setLastResult(O.some({ status: ScanResultStatus.NotFound })),
  );

  const handleServerSuccess = (serverResult: ScanResultSuccess, scanResult: string) =>
    sequenceT(IO.Apply)(
      () => secureVibrate(200),
      () => setLastResult(O.some({ result: serverResult, status: ScanResultStatus.Success })),
      () => setSuccessCache(O.some({ serverResult, scanResult })),
      replay,
      refreshSummary,
    );

  const handleServerError = (error: HttpError) =>
    pipe(
      error,
      IOE.fromPredicate(
        (error): error is HttpError<ScanResultAlreadyScanned> => error.status === HttpStatusCode.CONFLICT,
        handleServerNotFound,
      ),
      IOE.chain(({ data }) =>
        pipe(data, IOE.fromOption(handleServerNotFound), IOE.chainIOK(handleServerAlreadyScanned)),
      ),
    );

  const handleUncachedScan = (scanResultKey: string) =>
    pipe(
      () => sendRequest(scanResultKey),
      T.chainIOK(
        flow(
          IOE.fromEither,
          IOE.foldW(
            error => handleServerError(error),
            successResult => handleServerSuccess(successResult, scanResultKey),
          ),
        ),
      ),
    );

  const handleScanSuccess = (scanResult: string) =>
    pipe(
      scanResult,
      IOE.fromPredicate(() => !loading, constVoid),
      IOE.chainW(key =>
        pipe(
          successCache,
          IOE.fromOption(() => key),
          IOE.chain(
            IOE.fromPredicate(
              ({ scanResult }) => key === scanResult,
              () => key,
            ),
          ),
          IOE.chainIOK(({ serverResult }) => handleServerAlreadyScanned(serverResult)),
          IOE.swap,
        ),
      ),
      TE.fromIOEither,
      TE.chainTaskK(handleUncachedScan),
    )();

  const resetLastResult = () => setLastResult(O.none);

  return { handleScanSuccess, lastResult, resetLastResult, loading };
};

// vibrate is undefined on ios
const secureVibrate = (pattern: VibratePattern) => {
  if (navigator.vibrate) {
    navigator.vibrate(pattern);
  }
};
