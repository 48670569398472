import { Button } from '@styles/shared';
import { ScanResultStatus } from '@modules/events/model';
import { backgroundColor, fontSize, fontWeight, primaryColor, secondaryColor } from '@styles/utils';
import styled, { css } from 'styled-components';

const getExitStatusStyle = (status: ScanResultStatus) => {
  switch (status) {
    case ScanResultStatus.AlreadyScanned:
      return css`
        border: 2px solid #fd8d00;
        color: #fd8d00;

        > svg {
          fill: #fd8d00;
          stroke: #fd8d00;
        }
      `;
    case ScanResultStatus.NotFound:
      return css`
        border: 2px solid ${secondaryColor(600)};
        color: ${secondaryColor(600)};

        > svg {
          fill: ${secondaryColor(600)};
          stroke: ${secondaryColor(600)};
        }
      `;
    case ScanResultStatus.Success:
      return css`
        border: 2px solid ${primaryColor(500)};
        color: ${primaryColor(500)};

        > svg {
          fill: ${primaryColor(500)};
          stroke: ${primaryColor(500)};
        }
      `;
  }
};

export const EventDetailControlScanExitContainer = styled(Button)<{ status?: ScanResultStatus }>`
  position: fixed;
  z-index: 10;
  top: 17px;
  right: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 39px;
  padding: 0 12px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid ${backgroundColor(400)};

  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.bold};
  
  ${({ status }) =>
    status
      ? getExitStatusStyle(status)
      : css`
          border: 2px solid ${backgroundColor(400)};
          color: ${backgroundColor(400)};

          > svg {
            fill: #fff;
            stroke: #fff;
          }
        `}}

  > svg {
    stroke-width: .5px;
    min-width: 11px;
    height: 11px;
    margin-right: 9px;
  }

  :hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;
