import styled from 'styled-components';
import arrowIcon from '@assets/icons/buttons/arrow-next.svg';
import { Button } from '@styles/shared';
import { backgroundColor, mediaMax } from '@styles/utils';

export const EventDetailBookingsRowContainer = styled(Button)`
  width: 100%;
  padding: 15px 34px 9px 34px;
  background: transparent;
  border-radius: 0;

  :hover {
    background: transparent;
  }

  :active {
    background-color: ${backgroundColor(300)};
  }

  @media screen and (${mediaMax.tablet}) {
    padding-left: 25px;
  }

  @media screen and (${mediaMax.mobile}) {
    padding-left: 18px;
    padding-right: 15px;
  }
`;

export const EventDetailBookingsRowHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  text-align: start;

  :after {
    content: '';
    margin-right: 0;
    margin-left: 10px;
    min-width: 25px;
    height: 25px;
    background: url(${arrowIcon}) no-repeat center;
  }
`;

export const EventDetailBookingsRowContent = styled.ul`
  > li:not(:first-child) {
    padding-top: 13px;
  }
`;

export const EventDetailBookingsRowContentItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    line-height: 1;
    text-align: start;
  }
`;

export const EventDetailBookingTicketStatusWrapper = styled.div`
  flex: 0 0 auto;
  margin-left: 10px;

  > span {
    margin-left: auto;
  }
`;
