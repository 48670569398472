import styled from 'styled-components';
import { backgroundColor, fontWeight, primaryColor } from '@styles/utils';

export const EventDetailControlScanProgressContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75px;
  padding: 13px 13px 20px 29px;
  background-color: ${backgroundColor(400)};

  > div:first-child {
    display: flex;
    justify-content: space-between;
    padding: 0 7px 9px;

    > div {
      display: flex;
      align-items: center;

      > img {
        margin-right: 7px;
      }
    }
  }

  p {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  span {
    font-size: 16px;
    font-weight: ${fontWeight.bold};
    padding: 0 3px;
  }
`;

export const EventDetailControlScanProgressBarEmpty = styled.div`
  position: relative;
  width: 100%;
  height: 17px;
  background-color: ${backgroundColor(200)};
  border-radius: 7px;
`;

export const EventDetailControlScanProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${primaryColor(500)};
  border-radius: 7px;
`;
