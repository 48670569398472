import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';
import * as Styled from './SearchFilter.styles';
import { useDebouncedCallback } from 'use-debounce';
import { filterEmptyStringToNullable } from '@shared/utils/string';

interface SearchFilterProps {
  defaultValue: string | null;
  onChange: (value: string | null) => void;
  className?: string;
  placeholder?: string;
}

const SearchFilter: FC<SearchFilterProps> = ({ defaultValue, onChange, placeholder, className }) => {
  const [value, setValue] = useState<string>(() => defaultValue ?? '');

  useEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  const debouncedOnChange = useDebouncedCallback((search: string | null) => onChange(search), 400);

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;

    setValue(value);
    debouncedOnChange(filterEmptyStringToNullable(value));
  };

  const handleDelete = () => {
    setValue('');
    onChange(null);
  };

  return (
    <Styled.SearchFilterContainer className={className}>
      <Styled.SearchFilterInput value={value} placeholder={placeholder ?? 'Rechercher'} onChange={handleChange} />
      {value.length > 0 && <Styled.SearchFilterInputDelete onClick={handleDelete} />}
    </Styled.SearchFilterContainer>
  );
};

export default SearchFilter;
