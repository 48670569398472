import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { CityMagPlatform } from '@shared/modules/platform/model';
import { getCurrentPlatform } from '@shared/modules/platform/utils';

export interface DescriptorContextValue {
  platform: CityMagPlatform;
}

const DescriptorContext = createContext<DescriptorContextValue>({
  platform: CityMagPlatform.WEB,
});

export const DescriptorProvider: FC<PropsWithChildren> = ({ children }) => {
  const platform = useMemo(getCurrentPlatform, []);

  return <DescriptorContext.Provider value={{ platform }}>{children}</DescriptorContext.Provider>;
};

export const useDescriptorContext = () => useContext(DescriptorContext);
export const usePlatform = () => useDescriptorContext().platform;
