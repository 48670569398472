import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { fontSize, fontWeight, mediaMax, secondaryColor, tertiaryColor } from '../../utils';

const FormFieldContainer = styled.div<{ error: boolean }>`
  &:not(:last-child) {
    padding-bottom: 15px;
  }

  > label {
    display: block;
    padding-bottom: 3px;
    font-size: ${fontSize.standard};
    font-weight: ${fontWeight.semiBold};
    color: ${tertiaryColor(600)};

    &[for] {
      cursor: pointer;
    }

    ${props =>
      props.error &&
      css`
        color: ${secondaryColor(400)};
      `};
  }
`;

const FormFieldError = styled.p`
  margin-top: 5px;
  font-size: ${fontSize.small};
  color: ${secondaryColor(400)};
`;

interface FormFieldProps {
  label: string;
  htmlFor?: string;
  error?: boolean | string;
  required?: boolean;
  className?: string;
}

export const FormField: FC<PropsWithChildren<FormFieldProps>> = ({
  label,
  htmlFor,
  error,
  required,
  className,
  children,
}) => (
  <FormFieldContainer error={!!error} className={className}>
    <label htmlFor={htmlFor}>
      {label} {required ? ' *' : null}
    </label>
    {children}
    {typeof error === 'string' && <FormFieldError>{error}</FormFieldError>}
  </FormFieldContainer>
);

export const DoubleFormField = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  > div:not(:last-child) {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    padding-bottom: 15px;
  }

  @media screen and (${mediaMax.tablet}) {
    grid-template-columns: 1fr;

    > div:not(:last-child) {
      padding-bottom: 15px;
    }
  }
`;
