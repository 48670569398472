import styled from 'styled-components';
import { Paragraph } from '@styles/shared';
import { fontSize, fontWeight, tertiaryColor } from '@styles/utils';

export const LoginFormDescription = styled(Paragraph)`
  padding-top: 12px;
`;

export const LoginFormInputs = styled.form`
  padding-top: 30px;
  max-width: 440px;

  > div:not(:first-child) {
    padding-top: 15px;
  }
`;

export const LoginOauthContainer = styled.div`
  padding-bottom: 30px;

  > p {
    margin-bottom: 20px;
  }
`;

export const LoginOauthSeparator = styled.div`
  position: relative;
  margin-top: 30px;

  > span {
    display: inline-block;
    position: relative;
    padding-right: 18px;
    font-size: ${fontSize.large};
    font-weight: ${fontWeight.bold};
    line-height: 1;
    color: ${tertiaryColor(500)};
    background-color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
    z-index: 1;
  }

  &:before {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    height: 1px;
    width: 100%;
    background: ${tertiaryColor(200)};
  }
`;

export const LoginFormForgotPasswordContainer = styled.div`
  max-width: 440px;
  text-align: right;
  margin-bottom: 10px;
  margin-top: -25px;

  > button {
    :hover {
      background: none;
    }
  }
`;

export const LoginFormActions = styled.div`
  padding: 15px 0 20px;
`;
