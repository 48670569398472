import { DateFormat } from './model';
import { format, isValid, parse, parseISO } from 'date-fns';

import fr from 'date-fns/locale/fr';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { filterEmptyStringToOption } from '../../utils/string';

export function formatDate(date: Date | number, formatStr: string = DateFormat.Local): string {
  return format(date, formatStr, {
    locale: fr,
  });
}

export function formatNullableDate(
  date: Date | number | null | undefined,
  formatStr: string = DateFormat.Local,
): string | null {
  return pipe(
    O.fromNullable(date),
    O.map(date => formatDate(date, formatStr)),
    O.toNullable,
  );
}

export function parseDate(date: string | null | undefined, formatStr: string = DateFormat.Local): O.Option<Date> {
  return pipe(
    filterEmptyStringToOption(date),
    O.map(date => parse(date, formatStr, new Date(), { locale: fr })),
    O.filter(isValid),
  );
}

export function parseDateToNullable(
  date: string | null | undefined,
  formatStr: string = DateFormat.Local,
): Date | null {
  return O.toNullable(parseDate(date, formatStr));
}

export function parseDateToUndefined(
  date: string | null | undefined,
  formatStr: string = DateFormat.Local,
): Date | undefined {
  return O.toUndefined(parseDate(date, formatStr));
}

export function parseAndFormatDate(
  date: string | null | undefined,
  parseFormat: string = DateFormat.Local,
  formatStr: string = DateFormat.Local,
): O.Option<string> {
  return pipe(
    parseDate(date, parseFormat),
    O.map(date => formatDate(date, formatStr)),
  );
}

export function parseAndFormatDateToNullable(
  date: string | null | undefined,
  parseFormat: string = DateFormat.Local,
  formatStr: string = DateFormat.Local,
): string | null {
  return O.toNullable(parseAndFormatDate(date, parseFormat, formatStr));
}

export function parseAndFormatISODate(
  date: string | null | undefined,
  formatStr: string = DateFormat.Local,
): O.Option<string> {
  return pipe(
    filterEmptyStringToOption(date),
    O.map(parseISO),
    O.filter(isValid),
    O.map(date => formatDate(date, formatStr)),
  );
}
