import React, { FC } from 'react';
import * as Styled from './EventDetailControlBody.styles';
import { Paragraph } from '@styles/shared';
import { CalendarEventRegistrationPriceId, ScanCalendarEventSummary } from '@modules/events/model';
import { renderOptional } from '@shared/utils/render';
import { flow, pipe } from 'fp-ts/function';
import { sequenceS } from 'fp-ts/Apply';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import { formatPrice } from '@shared/utils/number';

interface EventDetailControlBodyProps {
  summary: ScanCalendarEventSummary;
}

const EventDetailControlBody: FC<EventDetailControlBodyProps> = ({ summary }) => {
  const getScannedByPrice = (priceId: CalendarEventRegistrationPriceId) =>
    pipe(
      O.fromNullable(summary.scannedByPrice),
      O.chain(
        flow(
          R.toArray,
          A.findFirst(([id]) => priceId === id),
          O.map(([, number]) => number),
        ),
      ),
    );

  const getTotalByPrice = (priceId: CalendarEventRegistrationPriceId) =>
    pipe(
      O.fromNullable(summary.totalByPrice),
      O.chain(
        flow(
          R.toArray,
          A.findFirst(([id]) => priceId === id),
          O.map(([, number]) => number),
        ),
      ),
    );

  const pricesInfos = pipe(
    summary.prices,
    A.filterMap(price =>
      pipe(
        sequenceS(O.Apply)({
          scanned: getScannedByPrice(price.id),
          total: getTotalByPrice(price.id),
        }),
        O.map(result => ({ ...result, ...price })),
      ),
    ),
  );

  return (
    <Styled.EventDetailControlBodyContainer>
      <Styled.EventDetailControlBodyList>
        <Styled.EventDetailControlBodyListHead free={A.isEmpty(pricesInfos)}>
          <div>
            <Paragraph size="small" color="tertiary" colorKey={600}>
              <span>{summary.scanned}</span> / {summary.total}
            </Paragraph>
            <Paragraph size="small" color="tertiary" colorKey={600}>
              Reste : <span>{summary.total - summary.scanned}</span>
            </Paragraph>
          </div>
          <Styled.EventDetailControlBodyProgressBarEmpty>
            <Styled.EventDetailControlBodyProgressBar
              style={{ width: `${(summary.scanned * 100) / summary.total}%` }}
            />
          </Styled.EventDetailControlBodyProgressBarEmpty>
        </Styled.EventDetailControlBodyListHead>
        {renderOptional(NEA.fromArray(pricesInfos), prices => (
          <Styled.EventDetailControlBodyListContent>
            {pipe(
              prices,
              A.map(({ title, price, scanned, total, id }) => (
                <Styled.EventDetailControlBodyListElement key={id}>
                  <div>
                    <Paragraph size="large" color="tertiary" colorKey={600} style={{ paddingBottom: 5 }}>
                      {title}
                    </Paragraph>
                    <Paragraph size="small" color="tertiary" colorKey={600}>
                      {price === 0 ? 'Gratuit' : formatPrice(price)}
                    </Paragraph>
                  </div>
                  <Paragraph size="small" color="tertiary" colorKey={600}>
                    <span>{scanned}</span> / {total}
                  </Paragraph>
                </Styled.EventDetailControlBodyListElement>
              )),
            )}
          </Styled.EventDetailControlBodyListContent>
        ))}
      </Styled.EventDetailControlBodyList>
    </Styled.EventDetailControlBodyContainer>
  );
};
export default EventDetailControlBody;
