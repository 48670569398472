import React, { FC, ReactNode } from 'react';
import ReactModal from 'react-modal';

import * as Styled from './Modal.styles';
import { ModalActions } from './Modal.styles';

export interface ModalProps extends ReactModal.Props {
  title: ReactNode;
  width?: number;
  onRequestClose?: () => void;
}

const Modal: FC<ModalProps> = ({ children, title, width, style, onRequestClose, ...props }) => {
  const modalStyle: ReactModal.Styles = {
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '27px',
      zIndex: 10000,
      ...style?.overlay,
    },
    content: {
      position: 'relative',
      inset: 0,
      border: 'none',
      borderRadius: '20px',
      padding: '22px 17px 26px 22px',
      width: width ?? 500,
      maxWidth: '100%',
      maxHeight: '100%',
      boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.1)',
      outline: 'none',
      ...style?.content,
    },
  };

  return (
    <ReactModal onRequestClose={onRequestClose} style={modalStyle} {...props}>
      <Styled.ModalContainer>
        <Styled.ModalContent>{children}</Styled.ModalContent>
      </Styled.ModalContainer>
    </ReactModal>
  );
};

export default Modal;

export { ModalActions };
