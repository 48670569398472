import styled from 'styled-components';
import { Button } from '@styles/shared';

import facebookIcon from '@assets/icons/auth/facebook.svg';
import googleIcon from '@assets/icons/auth/google.svg';
import appleIcon from '@assets/icons/auth/apple.svg';
import { fontWeight } from '@styles/utils';
import { OAuthProvider } from '@modules/auth/model';

const providerIcon: Record<OAuthProvider, string> = {
  facebook: facebookIcon,
  google: googleIcon,
  apple: appleIcon,
  'apple-web': appleIcon,
};

export const SocialSignInButton = styled(Button)<{ provider: OAuthProvider }>`
  display: block;
  width: 280px;
  margin-bottom: 5px;
  padding: 12px 20px 12px 78px;
  background: url(${props => providerIcon[props.provider]}) no-repeat 10px;
  border: solid 1px #dadce0;
  color: #3c4043;
  font-size: 13px;
  font-weight: ${fontWeight.medium};
  text-align: left;

  &:hover {
    background-color: rgba(66, 133, 244, 0.04);
  }
`;
