import { CalendarDate, CalendarDateType } from '@shared/modules/calendar/model';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';
import { endOfDay, formatDistanceToNow, isAfter, isSameDay } from 'date-fns';
import * as O from 'fp-ts/Option';
import * as D from 'fp-ts/Date';
import { sequenceT } from 'fp-ts/Apply';
import { flow, pipe, tupled } from 'fp-ts/function';
import { fr } from 'date-fns/locale';

export const getScanDisplayDifference = (start: Date, end?: Date) => {
  const now = D.create();

  return pipe(
    O.fromNullable(end),
    O.chain(
      flow(
        O.fromPredicate(end => isAfter(now, end)),
        O.map(() => 'Terminé'),
        O.alt(() =>
          pipe(
            start,
            O.fromPredicate(() => isAfter(now, start)),
            O.map(() => 'En cours'),
          ),
        ),
      ),
    ),
    O.alt(() =>
      pipe(
        start,
        O.fromPredicate(() => isAfter(now, endOfDay(start))),
        O.map(() => 'Terminé'),
        O.alt(() =>
          pipe(
            start,
            O.fromPredicate(() => isSameDay(now, start)),
            O.map(() => 'En cours'),
          ),
        ),
      ),
    ),
    O.getOrElse(() => `dans ${formatDistanceToNow(start, { locale: fr })}`),
  );
};

export const getScanDisplayDateStart = (d: CalendarDate) => {
  switch (d.type) {
    case CalendarDateType.Date:
      return pipe(
        parseDate(d.value, DateFormat.Local),
        O.map(d => formatDate(d, 'EEEE dd MMMM')),
      );
    case CalendarDateType.DateTime:
      return pipe(
        parseDate(d.value, DateFormat.LocalDateTime),
        O.map(d => formatDate(d, 'EEEE dd MMMM à HH:mm')),
      );
    case CalendarDateType.DateRange:
      return pipe(
        parseDate(d.start, DateFormat.Local),
        O.map(d => formatDate(d, 'EEEE dd MMMM')),
      );
    case CalendarDateType.DateTimeRange:
      return pipe(
        parseDate(d.start, DateFormat.LocalDateTime),
        O.map(d => formatDate(d, 'EEEE dd MMMM à HH:mm')),
      );
  }
};

export const getScanDisplayDateLast = (d: CalendarDate) => {
  switch (d.type) {
    case CalendarDateType.Date:
      return pipe(parseDate(d.value, DateFormat.Local), O.map(getScanDisplayDifference));
    case CalendarDateType.DateTime:
      return pipe(parseDate(d.value, DateFormat.LocalDateTime), O.map(getScanDisplayDifference));
    case CalendarDateType.DateRange:
      return pipe(
        sequenceT(O.Apply)(parseDate(d.start, DateFormat.Local), parseDate(d.end, DateFormat.Local)),
        O.map(tupled(getScanDisplayDifference)),
      );
    case CalendarDateType.DateTimeRange:
      return pipe(
        sequenceT(O.Apply)(parseDate(d.start, DateFormat.LocalDateTime), parseDate(d.end, DateFormat.LocalDateTime)),
        O.map(tupled(getScanDisplayDifference)),
      );
  }
};
