import { z } from 'zod';
import { UserId } from '@shared/modules/users/model';
import { emailZodSchema } from '@shared/schemas/email';

export interface Profile {
  id: UserId;
  email: string;
  firstName: string;
  lastName: string;
}

export const authenticateSchema = z.object({
  email: emailZodSchema.nonempty('Champs requis'),
  password: z.string().nonempty('Champs requis'),
});

export type AuthenticateParams = z.infer<typeof authenticateSchema>;

export type OAuthProvider = 'google' | 'facebook' | 'apple-web' | 'apple';

export interface OAuthParams {
  token: string;
  firstName?: string;
  lastName?: string;
}
