import styled from 'styled-components';
import { Paragraph } from '@styles/shared';
import { backgroundColor, tertiaryColor } from '@styles/utils';

export const EventDetailControlScanPermissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${backgroundColor(400)};
  padding: 45px 20px 0;
  row-gap: 10px;
`;

export const EventDetailControlScanPermissionsSeparator = styled(Paragraph)`
  position: relative;
  padding: 10px 0;
  width: 100%;

  > span {
    position: relative;
    display: inline-block;
    padding-right: 10px;
    background: ${backgroundColor(400)};
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 49%;
    height: 1px;
    width: 100%;
    background: ${tertiaryColor(100)};
  }
`;
