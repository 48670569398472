import { HttpRange, httpService, HttpTask } from '@core/http';
import { RangeCursor } from '@shared/modules/range';
import {
  BatchCheckInResult,
  CalendarEventBookingTicketId,
  CalendarEventId,
  ScanCalendarEventBookingRangeItem,
  ScanCalendarEventRangeItem,
  ScanCalendarEventSummary,
  ScanResultAlreadyScanned,
  ScanResultSuccess,
} from '@modules/events/model';
import { SearchFilter } from '@shared/modules/filter';

const URI = '/calendar';

export function getScanCalendarEventItems(
  cursor: RangeCursor,
  filter?: SearchFilter,
): HttpRange<ScanCalendarEventRangeItem, SearchFilter> {
  return httpService.getRange(`${URI}/events`, cursor, filter);
}

export function getScanCalendarEventSummary(id: CalendarEventId): HttpTask<ScanCalendarEventSummary> {
  return httpService.get(`${URI}/events/${id}/summary`);
}

export function getScanCalendarEventBookingItems(
  id: CalendarEventId,
): (cursor: RangeCursor, filter?: SearchFilter) => HttpRange<ScanCalendarEventBookingRangeItem, SearchFilter> {
  return (cursor, filter) => httpService.getRange(`${URI}/events/${id}/tickets`, cursor, filter);
}

export function scanCalendarEvent(
  id: CalendarEventId,
): (params: string) => HttpTask<ScanResultSuccess, ScanResultAlreadyScanned> {
  return params =>
    httpService.post(`${URI}/events/${id}/tickets/scan`, params, { headers: { 'content-type': 'application/json' } });
}

export function checkInTicket(id: CalendarEventBookingTicketId): HttpTask<ScanResultSuccess, ScanResultAlreadyScanned> {
  return httpService.post(`${URI}/tickets/${id}/check`);
}

export function checkInTicketBatch(tickets: Array<CalendarEventBookingTicketId>): HttpTask<BatchCheckInResult> {
  return httpService.post(`${URI}/tickets/check/batch`, { tickets });
}
