import { OAuthProvider } from '@modules/auth/model';

export enum CityMagPlatform {
  TWA = 'twa',
  IOS = 'ios',
  WEB = 'web',
}

export const iosUserAgentCodeRegex = /Citymag\/\S+/;

export const iosPlatformCode = 'event-scanner-ios';

export const twaReferrerCodeRegex = /android-app:\/\/\S+\//;

export const twaPackageName = 'info.citymag.myeventscanner';

export type IOSEventType = 'authenticationSuccessful' | 'scanSuccessful';

export enum IOSInternalLinks {
  FacebookAuthentication = '/ios/authenticate/facebook',
  GoogleAuthentication = '/ios/authenticate/google',
  AppleAuthentication = '/ios/authenticate/apple',
}

export interface IOSAuthenticationSuccessFullPayload {
  provider: OAuthProvider;
  token: string;
  user?: {
    firstname?: string;
    lastname?: string;
  };
}

export const IOSScanResultLink = '/ios/scan';

export interface IOSScanSuccessPayload {
  code: string;
}

export class IOSCustomEvent extends CustomEvent<string> {
  constructor(type: IOSEventType, detail: string, init?: EventInit) {
    super(type, { detail, ...init });
  }
}

export type IOSCustomEventMap = {
  [key in IOSEventType]: IOSCustomEvent;
};
