import { Newtype } from 'newtype-ts';
import { SharedImgixAndSourceProps } from 'react-imgix';

export type BucketResourceId = Newtype<{ readonly ID: unique symbol }, string> & string;

export interface BucketResource {
  id: BucketResourceId;
  name: string;
  type: BucketResourceType;
  mime: string;
  url: string;
  original: string;
}

export enum BucketResourceType {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  Pdf = 'pdf',
  Other = 'other',
}

export interface ProcessableBucketResource {
  id: BucketResourceId;
  processUrl: string | null;
}

export interface ProcessableBucketResourceFormBody extends BucketResource {
  processUrl: string | null;
}

export interface UpdateBucketResourceParams {
  name: string;
}

export const bucketResourceTypeAccept: { [key in BucketResourceType]?: string | Array<string> } = {
  [BucketResourceType.Image]: ['image/png', 'image/jpeg'],
  [BucketResourceType.Video]: 'video/*',
  [BucketResourceType.Audio]: 'audio/*',
  [BucketResourceType.Pdf]: 'application/pdf',
};

export type BucketResourceDisplayStrategy = 'crop' | 'fill';

export const imgixParamsByStrategy: Record<BucketResourceDisplayStrategy, SharedImgixAndSourceProps['imgixParams']> = {
  crop: {
    fit: 'crop',
    crop: 'faces,edges',
  },
  fill: {
    fit: 'fill',
    fill: 'solid',
    'fill-color': '#ffffff',
  },
};
