import React, { FC } from 'react';
import { useAnimateRefresh } from '@shared/modules/refresh/hooks';
import * as Styled from './TouchRefresh.styles';
import refreshIcon from '@assets/icons/shared/refresh.svg';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';

interface TouchRefreshProps {
  element: HTMLElement;
  handleRefresh: TE.TaskEither<unknown, unknown>;
  height?: number;
}

const TouchRefresh: FC<TouchRefreshProps> = ({ element, handleRefresh, height = 42 }) => {
  const handleAction = pipe(
    handleRefresh,
    T.chainIOK(() => resetProgress),
  );

  const [progress, resetProgress] = useAnimateRefresh(element, handleAction, 200);

  return (
    <Styled.TouchRefreshContainer style={{ height: progress * height }} loading={progress === 1}>
      <img src={refreshIcon} alt="Chargement" />
    </Styled.TouchRefreshContainer>
  );
};

export default TouchRefresh;
