import React, { FC } from 'react';
import * as Styled from './EventDetailBookingsRow.styles';
import { EventDetailBookingsRowHeader } from './EventDetailBookingsRow.styles';

import {
  CalendarEventBookingId,
  CalendarEventBookingTarget,
  CalendarEventRegistrationPrice,
  CalendarEventRegistrationPriceId,
  ScanCalendarEventBookingTicket,
} from '@modules/events/model';
import { Paragraph } from '@styles/shared';
import { useHashedModal } from '@shared/components/modal/hooks';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import EventDetailBookingTicketStatus from '@modules/events/components/detail/bookings/status/EventDetailBookingTicketStatus';
import EventDetailBookingTicketModal from '@modules/events/components/detail/bookings/modal/EventDetailBookingTicketModal';
import { renderOptional } from '@shared/utils/render';

interface EventDetailBookingsRowParams {
  id: CalendarEventBookingId;
  user: CalendarEventBookingTarget;
  tickets: Array<ScanCalendarEventBookingTicket>;
  prices: Array<CalendarEventRegistrationPrice>;
  handleRefresh: TE.TaskEither<unknown, void>;
}

const EventDetailBookingsRow: FC<EventDetailBookingsRowParams> = ({ id, user, tickets, prices, handleRefresh }) => {
  const [isOpen, openModal, closeModal] = useHashedModal(id);

  const getPriceInfos = (priceId: CalendarEventRegistrationPriceId | null) =>
    pipe(
      O.fromNullable(priceId),
      O.chain(priceId =>
        pipe(
          prices,
          A.findFirst(({ id }) => id === priceId),
        ),
      ),
    );

  return (
    <>
      <Styled.EventDetailBookingsRowContainer onClick={openModal}>
        <EventDetailBookingsRowHeader>
          <Paragraph size="large" color="tertiary" colorKey={500} weight="bold">
            {user.lastName} {user.firstName}
          </Paragraph>
        </EventDetailBookingsRowHeader>
        <Styled.EventDetailBookingsRowContent>
          {pipe(
            tickets,
            A.map(({ id, code, status, priceId }) => (
              <Styled.EventDetailBookingsRowContentItem key={id}>
                <div>
                  {renderOptional(getPriceInfos(priceId), ({ title }) => (
                    <Paragraph size="large" color="tertiary" colorKey={600} style={{ paddingBottom: 4 }}>
                      {title}
                    </Paragraph>
                  ))}
                  <Paragraph size="small" color="tertiary" colorKey={300} uppercase>
                    N°{code}
                  </Paragraph>
                </div>
                <Styled.EventDetailBookingTicketStatusWrapper>
                  <EventDetailBookingTicketStatus status={status} />
                </Styled.EventDetailBookingTicketStatusWrapper>
              </Styled.EventDetailBookingsRowContentItem>
            )),
          )}
        </Styled.EventDetailBookingsRowContent>
      </Styled.EventDetailBookingsRowContainer>
      <EventDetailBookingTicketModal
        user={user}
        tickets={tickets}
        prices={prices}
        isOpen={isOpen}
        onRequestClose={closeModal}
        handleRefresh={handleRefresh}
      />
    </>
  );
};

export default EventDetailBookingsRow;
