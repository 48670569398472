import React, { FC, PropsWithChildren } from 'react';
import { LayoutContextProvider } from './context';

import * as Styled from './Layout.styles';

const Layout: FC<PropsWithChildren> = ({ children }) => (
  <Styled.LayoutContainer>
    <LayoutContextProvider>
      <Styled.LayoutContent>{children}</Styled.LayoutContent>
    </LayoutContextProvider>
  </Styled.LayoutContainer>
);

export default Layout;
