import { ParsedQuery } from 'query-string';

import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import * as NEA from 'fp-ts/NonEmptyArray';
import { pipe } from 'fp-ts/function';
import { ActiveFilter, ActiveFilters, Filter } from './model';
import { parseDate } from '../dates';

export function isFilterEmpty(filter: Filter): boolean {
  return pipe(
    filter,
    R.filter(value => value !== null),
    R.isEmpty,
  );
}

function getQueryValue<T extends string = string>(query: ParsedQuery, key: string): O.Option<T> {
  return pipe(
    O.fromNullable(query[key] as T | T[] | null),
    O.chain(value => (Array.isArray(value) ? A.head(value) : O.some(value))),
  );
}

function getArrayQueryValue<T extends string = string>(
  query: ParsedQuery,
  key: string,
): O.Option<NEA.NonEmptyArray<T>> {
  return pipe(
    O.fromNullable(query[key] as T | T[] | null),
    O.chain(value => (Array.isArray(value) ? NEA.fromArray(value) : O.some([value]))),
  );
}

export function getStringQuery<T extends string = string>(query: ParsedQuery, key: string): T | null {
  return O.toNullable(getQueryValue(query, key));
}

export function getSearchQuery(query: ParsedQuery): string | null {
  return getStringQuery(query, 'search');
}

export function getEnumQuery<E extends string>(
  query: ParsedQuery,
  enumeration: Record<string, E>,
  key: string,
): E | null {
  return pipe(
    getQueryValue(query, key),
    O.filterMap(value => (Object.values(enumeration).includes(value as E) ? O.some(value as E) : O.none)),
    O.toNullable,
  );
}

export function getStringArrayQuery<T extends string = string>(
  query: ParsedQuery,
  key: string,
): NEA.NonEmptyArray<T> | null {
  return O.toNullable(getArrayQueryValue(query, key));
}

export function getEnumArrayQuery<E extends string>(
  query: ParsedQuery,
  enumeration: Record<string, E>,
  key: string,
): NEA.NonEmptyArray<E> | null {
  return pipe(
    getArrayQueryValue(query, key),
    O.chain(values =>
      pipe(
        values,
        NEA.filter(value => Object.values(enumeration).includes(value as E)),
      ),
    ),
    O.toNullable,
  ) as NEA.NonEmptyArray<E> | null;
}

export function getLocalDateQuery(query: ParsedQuery, key: string): string | null {
  return pipe(
    getQueryValue(query, key),
    O.filter(date => pipe(parseDate(date), O.isSome)),
    O.toNullable,
  );
}

export function mergeActiveFilters<F extends Filter>(...filters: Array<ActiveFilters<F>>): ActiveFilters<F> {
  return A.flatten(filters);
}

export function getActiveFilterFromNullable<F extends Filter, T>(
  value: T | null,
  onSome: (value: T) => ActiveFilter<F> | null,
): ActiveFilters<F> {
  return pipe(
    O.fromNullable(value),
    O.chainNullableK(value => onSome(value)),
    O.fold(
      () => [],
      value => [value],
    ),
  );
}

export function getActiveFilterFormArray<F extends Filter, T>(
  value: Array<T> | null,
  mapFn: (item: T) => ActiveFilter<F> | null,
): ActiveFilters<F> {
  return pipe(
    O.fromNullable(value),
    O.fold(
      () => [],
      array =>
        pipe(
          array,
          A.map(item => O.fromNullable(mapFn(item))),
          A.compact,
        ),
    ),
  );
}

export function nullableActiveFilterDeleter<F extends Filter>(key: keyof F): (filter: F) => F {
  return filter => ({
    ...filter,
    [key]: null,
  });
}

export function removeNonEmptyArrayFilter<T extends string | number>(
  array: NEA.NonEmptyArray<T> | null,
  value: T,
): NEA.NonEmptyArray<T> | null {
  return O.toNullable(NEA.fromArray(array?.filter(v => v !== value) ?? []));
}
