import { backgroundColor } from '@styles/utils';
import styled from 'styled-components';

export const EventDetailControlContainer = styled.div`
  padding-top: 157px;
`;

export const EventDetailControlBodyTop = styled.div`
  position: fixed;
  top: 110px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 47px;
  padding: 16px 18px;
  background-color: ${backgroundColor(200)};

  > div {
    display: flex;

    > img {
      margin-right: 7px;
    }
  }
`;
