import React, { PureComponent, ReactNode } from 'react';

import { ActiveFilter, ActiveFiltersGetter, Filter, FilterChildren, FilterQueryParser, SearchFilter } from '../model';

import * as Styled from './Filters.styles';

import { isFilterEmpty } from '../utils';
import { renderNullable, renderOptional } from '@shared/utils/render';

import ActiveFiltersList from './active/ActiveFilter';
import FiltersModal from './modal/FiltersModal';
import SearchFilterComponent from './search/SearchFilter';

import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import * as NEA from 'fp-ts/NonEmptyArray';
import { NonEmptyArray } from 'fp-ts/NonEmptyArray';
import { pipe } from 'fp-ts/function';
import isEqual from 'lodash.isequal';
import { parseQueries, stringifyQueries } from '@shared/utils/queries';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

interface RouteComponentProps {
  navigate: NavigateFunction;
  location: Location;
}

interface FiltersProps<F extends Filter> {
  filter: F;
  defaultFilter: F;
  parser: FilterQueryParser<F>;
  search?: F extends SearchFilter ? boolean : never;
  placeholder?: string;
  activeFilterGetter?: ActiveFiltersGetter<F>;
  onFilter: (asd: F) => void;
  children?: FilterChildren<F>;
}

interface FiltersPropsState<F extends Filter> {
  filterModalOpen: boolean;
  activeFilter: O.Option<NonEmptyArray<ActiveFilter<F>>>;
}

class Filters<F extends Filter> extends PureComponent<FiltersProps<F> & RouteComponentProps, FiltersPropsState<F>> {
  state: FiltersPropsState<F> = {
    filterModalOpen: false,
    activeFilter: O.none,
  };

  componentDidMount() {
    const currentFilter = this.parseFilter(this.props.location.search);

    if (!isFilterEmpty(this.props.defaultFilter) && isFilterEmpty(currentFilter)) {
      this.handleFilter(this.props.defaultFilter);
    } else {
      this.props.onFilter(currentFilter);
    }

    this.getActiveFilters();
  }

  componentDidUpdate(
    prevProps: Readonly<FiltersProps<F> & RouteComponentProps>,
    prevState: Readonly<FiltersPropsState<F>>,
  ) {
    if (this.props.location.search !== prevProps.location.search) {
      this.props.onFilter(this.parseFilter(this.props.location.search));
    }

    if (
      !isEqual(this.props.filter, prevProps.filter) ||
      !isEqual(this.props.activeFilterGetter, prevProps.activeFilterGetter)
    ) {
      this.getActiveFilters();
    }
  }

  private getActiveFilters() {
    const activeFilter = pipe(
      O.fromNullable(this.props.activeFilterGetter),
      O.chain(getter => NEA.fromArray(getter(this.props.filter))),
    );

    this.setState({ activeFilter });
  }

  private parseFilter = (search: string): F => this.props.parser(parseQueries(search));

  private handleFilter = (filter: F) =>
    this.props.navigate({
      pathname: this.props.location.pathname,
      search: stringifyQueries(filter),
    });

  private handleSearch = (search: string | null) => this.handleFilter({ ...this.props.filter, search });

  private openFilterModal = () => this.setState({ filterModalOpen: true });
  private closeFilterModal = () => this.setState({ filterModalOpen: false });

  private handleDeleteFilter = (fn: (filter: F) => F) => this.handleFilter(fn(this.props.filter));

  private handleClearFilter = () => {
    const emptyFilters = pipe(
      this.props.filter,
      R.map(() => null),
    ) as F;

    this.handleFilter(emptyFilters);
  };

  render() {
    const { children, search, filter, placeholder } = this.props;

    return (
      <Styled.FiltersContainer>
        <Styled.FiltersContent>
          {children && <Styled.FiltersButton onClick={this.openFilterModal}>Filtrer</Styled.FiltersButton>}

          <Styled.FiltersActiveContainer>
            {renderOptional(this.state.activeFilter, activeFilter => (
              <ActiveFiltersList
                activeFilter={activeFilter}
                onDeleteFilter={this.handleDeleteFilter}
                onDeleteAllFilter={this.handleClearFilter}
              />
            ))}
          </Styled.FiltersActiveContainer>
        </Styled.FiltersContent>

        {search && (
          <SearchFilterComponent
            defaultValue={filter.search as string}
            onChange={this.handleSearch}
            placeholder={placeholder}
          />
        )}

        {renderNullable(children, children => (
          <FiltersModal
            isOpen={this.state.filterModalOpen}
            filter={filter}
            onClose={this.closeFilterModal}
            onFilter={this.handleFilter}
          >
            {children}
          </FiltersModal>
        ))}
      </Styled.FiltersContainer>
    );
  }
}

const FiltersWrapper = <F extends Filter>(props: FiltersProps<F> & { children?: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return <Filters {...props} navigate={navigate} location={location} />;
};

export default FiltersWrapper as <F extends Filter>(props: FiltersProps<F>) => JSX.Element;
