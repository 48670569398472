import styled from 'styled-components';
import { fontSize, fontWeight, mediaMax } from '@styles/utils';
import myEventScannerLogo from '@assets/logo/logo-myeventscanner.svg';
import askIcon from '@assets/icons/auth/ask.svg';

export const LoginPageContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 50% 50%;
  overflow-y: auto;

  @media screen and (${mediaMax.tablet}) {
    background: center center no-repeat linear-gradient(42deg, #132d90, #ff6065);
    grid-template-rows: min-content auto;
    grid-template-columns: 1fr;
    z-index: 1;
  }
`;

export const LoginPageLeft = styled.div`
  position: relative;
  background: center center no-repeat linear-gradient(42deg, #132d90, #ff6065);
  color: ${props => props.theme.colors.white};

  @media screen and (${mediaMax.tablet}) {
    background: none;
  }
`;

export const LoginPageLeftHelp = styled.a`
  position: absolute;
  z-index: 10;
  top: 19px;
  right: 21px;
  width: 28px;
  height: 28px;
  padding: 0;
  border-radius: 100%;
  background: url(${askIcon}) no-repeat center;
`;

export const LoginPageLeftContent = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  overflow-y: auto;

  @media screen and (${mediaMax.tablet}) {
    position: inherit;
    width: 100%;
    padding-bottom: 9px;
  }
`;

export const LoginPageLeftLogo = styled.div`
  display: flex;
  padding-top: 50px;
  padding-left: 60px;

  :after {
    content: '';
    width: 137px;
    height: 53px;
    background: url(${myEventScannerLogo}) no-repeat center;
    background-size: contain;
  }

  @media screen and (${mediaMax.tablet}) {
    justify-content: center;
    padding: 38px 20px 0;

    :after {
      width: 137px;
      height: 53px;
      background: url(${myEventScannerLogo}) no-repeat center;
    }
  }
`;

export const LoginPageLeftDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  @media screen and (${mediaMax.tablet}) {
    flex: none;
  }
`;

export const LoginPageLeftImage = styled.img`
  width: 100%;

  @media screen and (${mediaMax.tablet}) {
    display: none;
  }
`;

export const LoginPageLeftDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 20px 0 20px;
  justify-content: space-between;

  @media screen and (${mediaMax.tablet}) {
    margin-top: 12px;
    padding: 14px 20px 0;
    align-items: flex-start;
  }
`;

export const LoginPageLeftDescriptionText = styled.p`
  font-size: ${fontSize.standard};
  text-align: center;
  margin-top: 15px;

  @media screen and (${mediaMax.tablet}) {
    display: none;
  }
`;

export const LoginPageLeftLinkContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /*flex: 1 1 auto;*/

  @media screen and (${mediaMax.tablet}) {
    display: none;
  }
`;

export const LoginPageLeftLink = styled.a`
  position: absolute;
  bottom: 40px;
  text-decoration: underline;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
  text-align: center;

  @media screen and (${mediaMax.tablet}) {
    display: none;
  }
`;

export const LoginPageRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;

  @media screen and (${mediaMax.tablet}) {
    border-radius: 37px 37px 0 0;
  }
`;

export const LoginPageRightForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  padding: 30px;

  @media screen and (${mediaMax.tablet}) {
    align-items: start;
    padding: 35px 37px;
    border-radius: 37px 37px 0 0;
  }
`;

export const LoginPageRightFormContent = styled.div`
  max-width: 580px;
`;
