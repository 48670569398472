interface SoundOptions {
  volume?: number;
}

export default function useSound(src: string, { volume = 1 }: SoundOptions) {
  const sound = new Audio(src);
  sound.volume = volume;

  const stop = () => {
    sound.pause();
    sound.currentTime = 0;
  };

  const replay = () => {
    stop();
    sound.play();
  };

  return { play: sound.play, pause: sound.pause, stop, replay };
}
