import styled from 'styled-components';
import { Button, Input } from '@styles/shared';

import searchIcon from '@assets/icons/filter/search.svg';
import deleteIcon from '@assets/icons/filter/close.svg';
import { backgroundColor } from '@styles/utils';

export const SearchFilterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 500px;
  padding: 12px 18px;
`;

export const SearchFilterInput = styled(Input)`
  min-height: 100%;
  border-radius: 18px;
  background: url(${searchIcon}) no-repeat center right 15px ${backgroundColor(400)};
  padding-right: 45px;

  :focus {
    background: ${backgroundColor(400)};
  }

  :valid {
    transition: none;
  }
`;

export const SearchFilterInputDelete = styled(Button)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: url(${deleteIcon}) no-repeat center;

  :hover {
    background: url(${deleteIcon}) no-repeat center;
  }
`;
