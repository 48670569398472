import styled from 'styled-components';
import { Button } from '@styles/shared';
import { backgroundColor, fontSize, fontWeight, tertiaryColor } from '@styles/utils';
import dotsIcon from '@assets/icons/menu/dots.svg';
import logoutIcon from '@assets/icons/menu/logout.svg';

export const CalendarEventGridHeaderContainer = styled.header`
  position: fixed;
  z-index: 10;
  isolation: isolate;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  padding: 14px 5px 14px 40px;
  background: center center no-repeat linear-gradient(79deg, #132d90 -23%, #ff6065 100%);
`;

export const CalendarEventGridHeaderLogo = styled.img`
  height: 100%;
`;

export const CalendarEventGridHeaderRight = styled(Button)`
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0;

  > p {
    padding-right: 9px;
  }

  :hover {
    background: transparent;
  }

  :after {
    content: '';
    background: url(${dotsIcon}) no-repeat center;
    width: 32px;
    height: 32px;
  }
`;

export const CalendarEventGridHeaderPopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${backgroundColor(400)};
  border-radius: 15px;

  p,
  a,
  button {
    line-height: 1;
    font-size: ${fontSize.large};
    color: ${tertiaryColor(600)};
  }

  a {
    font-weight: ${fontWeight.regular};
    text-decoration: none;
  }

  > *:not(div) {
    width: 100%;
    padding: 6px 17px;
    text-align: start;

    :first-child {
      padding-top: 16px;
      padding-bottom: 13px;
    }

    :last-child {
      padding-top: 14px;
      padding-bottom: 20px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    border-top: 1px solid ${tertiaryColor(100)};
    border-bottom: 1px solid ${tertiaryColor(100)};

    > a {
      padding: 12px 17px;
      :hover {
        color: ${tertiaryColor(600)};
        background-color: ${tertiaryColor(100)};
      }
    }
  }

  > button {
    display: flex;
    align-items: center;
    background: transparent;
    color: ${tertiaryColor(600)};
    border-radius: 0 0 15px 15px;
    font-weight: ${fontWeight.regular};

    :hover {
      background: ${tertiaryColor(100)};
    }

    :after {
      content: '';
      background: url(${logoutIcon}) no-repeat center;
      width: 16px;
      height: 16px;
      margin-left: 7px;
    }
  }
`;
