import { Newtype } from 'newtype-ts';
import { Address } from '@shared/model';
import { SearchFilter } from '@shared/modules/filter';
import { backgroundColor, primaryColor, tertiaryColor } from '@styles/utils';
import { DefaultTheme, ThemeProps } from 'styled-components';
import { CalendarDate } from '@shared/modules/calendar/model';
import { BucketResource } from '@shared/modules/bucket/model';

export type CalendarEventId = Newtype<{ readonly ID: unique symbol }, string> & string;
export type CalendarEventRegistrationPriceId = Newtype<{ readonly ID: unique symbol }, string> & string;
export type CalendarEventBookingTicketId = Newtype<{ readonly ID: unique symbol }, string> & string;
export type CalendarEventBookingId = Newtype<{ readonly ID: unique symbol }, string> & string;

export interface ScanLocalResultSuccess {
  status: ScanResultStatus.Success;
  result: ScanResultSuccess;
}

export interface ScanLocalResultAlreadyScanned {
  status: ScanResultStatus.AlreadyScanned;
  result: ScanResultAlreadyScanned;
}

export interface ScanLocalResultNotFound {
  status: ScanResultStatus.NotFound;
}

export type ScanLocalResult = ScanLocalResultSuccess | ScanLocalResultAlreadyScanned | ScanLocalResultNotFound;

export enum ScanResultStatus {
  NotFound = 'not-found',
  AlreadyScanned = 'already-scanned',
  Success = 'success',
}

export const scanResultStatusLabel: Record<ScanResultStatus, string> = {
  [ScanResultStatus.AlreadyScanned]: 'Ticket déjà scanné',
  [ScanResultStatus.NotFound]: 'Ticket introuvable',
  [ScanResultStatus.Success]: 'Le billet présenté est valide',
};

export interface ScanResultAlreadyScanned {
  bookingId: CalendarEventBookingId;
  ticketId: CalendarEventBookingTicketId;
  ticketCode: string;
  priceId: CalendarEventRegistrationPriceId | null;
  user: CalendarEventBookingTarget;
  scannedAt: string | null; // LocalDateTime (ISO-8601)
  scannedBy: string | null;
}

export interface ScanResultSuccess {
  bookingId: CalendarEventBookingId;
  ticketId: CalendarEventBookingTicketId;
  ticketCode: string;
  priceId: CalendarEventRegistrationPriceId | null;
  user: CalendarEventBookingTarget;
  scannedAt: string | null; // LocalDateTime (ISO-8601)
  scannedBy: string | null;
}

export interface ScanCalendarEventSummary {
  source: ScanCalendarSource;
  event: CalendarEventDetail;
  prices: Array<CalendarEventRegistrationPrice>;
  total: number;
  scanned: number;
  totalByPrice: Record<CalendarEventRegistrationPriceId, number> | null;
  scannedByPrice: Record<CalendarEventRegistrationPriceId, number> | null;
}

export interface ScanCalendarEventBookingRangeItem {
  id: CalendarEventBookingId;
  user: CalendarEventBookingTarget;
  tickets: Array<ScanCalendarEventBookingTicket>;
}

export interface ScanCalendarEventBookingTicket {
  id: CalendarEventBookingTicketId;
  code: string;
  priceId: CalendarEventRegistrationPriceId | null;
  status: CalendarEventBookingTicketScanStatus;
  scannedAt: string | null;
  scannedBy: string | null;
}

export enum CalendarEventBookingTicketScanStatus {
  Generated = 'generated',
  Scanned = 'scanned',
  Checked = 'checked',
}

export interface BatchCheckInBody {
  id: CalendarEventBookingTicketId;
  status: CalendarEventBookingTicketScanStatus;
}

export interface BatchCheckInResult {
  success: Array<ScanResultSuccess>;
  alreadyScanned: Array<ScanResultAlreadyScanned>;
}

export const ticketScanStatusLabel: Record<CalendarEventBookingTicketScanStatus, string> = {
  [CalendarEventBookingTicketScanStatus.Generated]: 'Non contrôlé',
  [CalendarEventBookingTicketScanStatus.Scanned]: 'Scanné',
  [CalendarEventBookingTicketScanStatus.Checked]: 'Contrôle manuel',
};

export const ticketScanStatusBackground: Record<
  CalendarEventBookingTicketScanStatus,
  (props: ThemeProps<DefaultTheme>) => string
> = {
  [CalendarEventBookingTicketScanStatus.Generated]: backgroundColor(200),
  [CalendarEventBookingTicketScanStatus.Scanned]: primaryColor(300),
  [CalendarEventBookingTicketScanStatus.Checked]: primaryColor(300),
};

export const ticketScanStatusColor: Record<
  CalendarEventBookingTicketScanStatus,
  (props: ThemeProps<DefaultTheme>) => string
> = {
  [CalendarEventBookingTicketScanStatus.Generated]: tertiaryColor(600),
  [CalendarEventBookingTicketScanStatus.Scanned]: primaryColor(500),
  [CalendarEventBookingTicketScanStatus.Checked]: primaryColor(500),
};

export interface CalendarEventBookingTarget {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
}

export interface CalendarEventRegistrationPrice {
  id: CalendarEventRegistrationPriceId;
  title: string;
  price: number;
  description: string | null;
}

export interface ScanCalendarEventRangeItem {
  source: ScanCalendarSource;
  event: CalendarEventDetail;
}

export const defaultScanCalendarEventFilter: SearchFilter = {
  search: null,
};

export const defaultScanBookingsFilter: SearchFilter = {
  search: null,
};

export interface CalendarEventDetail {
  id: CalendarEventId;
  title: string;
  date: CalendarDate;
  address: Address;
  image: BucketResource;
}

export interface CommunityScanCalendarSource {
  type: 'community';
  name: string;
  logo: string | null;
}

export interface ShopScanCalendarSource {
  type: 'shop';
  name: string;
  logo: string | null;
}

export interface CityScanCalendarSource {
  type: 'city';
  name: string;
  logo: string | null;
}

export type ScanCalendarSource = CommunityScanCalendarSource | ShopScanCalendarSource | CityScanCalendarSource;
