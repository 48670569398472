import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useHashedModal(hash: string): [boolean, () => void, () => void] {
  const [isOpen, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const openModal = useCallback(() => {
    navigate({ ...location, hash });
  }, [hash, location, navigate]);

  const closeModal = useCallback(() => {
    navigate({ ...location, hash: undefined });
  }, [location, navigate]);

  useEffect(() => {
    setOpen(location.hash === `#${hash}`);
  }, [hash, location.hash]);

  return [isOpen, openModal, closeModal];
}
