import styled, { css } from 'styled-components';

export const QrReaderContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100% - 75px);
  width: 100%;
  height: 100%;

  > button {
    margin-top: 20px;
    width: fit-content;
  }
`;

export const QrReaderContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const QrReaderVideo = styled.video<{ facingMode?: ConstrainDOMString }>`
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  overflow: hidden;

  ${({ facingMode }) =>
    facingMode === 'user' &&
    css`
      transform: scaleX(-1);
    `};

  @media (orientation: landscape) {
    width: 100%;
    height: auto;
  }
`;
