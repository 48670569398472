import React, { FC } from 'react';
import * as IO from 'fp-ts/IO';
import * as Styled from './EventDetailControlScanExit.styles';
import { ScanResultStatus } from '@modules/events/model';

interface EventDetailControlScanExitProps {
  onRequestClose: IO.IO<void>;
  status?: ScanResultStatus;
}

const EventDetailControlScanExit: FC<EventDetailControlScanExitProps> = ({ onRequestClose, status }) => {
  return (
    <Styled.EventDetailControlScanExitContainer onClick={onRequestClose} status={status}>
      <svg xmlns="http://www.w3.org/2000/svg" width="10.706" height="10.706" viewBox="0 0 10.706 10.706">
        <path d="m17.522 8.523-1-1-4 4-4-4-1 1 4 4-4 4 1 1 4-4 4 4 1-1-4-4z" transform="translate(-7.17 -7.17)" />
      </svg>
      Quitter
    </Styled.EventDetailControlScanExitContainer>
  );
};
export default EventDetailControlScanExit;
