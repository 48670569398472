import { useHideScrollBar } from '@shared/hooks/scroll';
import React, { FC } from 'react';

import ReactModal from 'react-modal';
import { ModalCloseButton } from '../Modal.styles';

interface FullModalProps extends ReactModal.Props {
  onRequestClose?: () => void;
}

const FullModal: FC<FullModalProps> = ({ children, onRequestClose, isOpen, ...props }) => {
  useHideScrollBar(isOpen);

  const fullModalStyle: ReactModal.Styles = {
    overlay: {
      background: 'none',
      zIndex: 10000,
    },
    content: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      border: 'none',
      borderRadius: 0,
      padding: 0,
    },
  };

  return (
    <ReactModal onRequestClose={onRequestClose} style={fullModalStyle} isOpen={isOpen} {...props}>
      {children}
      {onRequestClose && <ModalCloseButton onClick={onRequestClose} style={{ top: 15, right: 15 }} />}
    </ReactModal>
  );
};

export default FullModal;
