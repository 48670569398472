import React, { CSSProperties, FC } from 'react';
import * as Styled from './EventDetailControlScanResult.styles';
import successIcon from '@assets/icons/scan/check-success.svg';
import successTicket from '@assets/icons/scan/ticket-success.svg';
import alreadyIcon from '@assets/icons/scan/check-already-scan.svg';
import alreadyTicket from '@assets/icons/scan/ticket-already-scan.svg';
import errorIcon from '@assets/icons/scan/check-error.svg';
import errorTicket from '@assets/icons/scan/ticket-error.svg';
import touchIcon from '@assets/icons/scan/touch-scan.svg';
import { Paragraph, Title } from '@styles/shared';
import {
  CalendarEventRegistrationPrice,
  ScanLocalResult,
  ScanLocalResultAlreadyScanned,
  ScanLocalResultSuccess,
  ScanResultStatus,
} from '@modules/events/model';
import * as IO from 'fp-ts/IO';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { flow, identity, pipe } from 'fp-ts/function';
import { renderOptional } from '@shared/utils/render';
import { DateFormat, parseAndFormatDate } from '@shared/modules/dates';
import { useSummaryContext } from '@modules/events/components/detail/control/context';
import * as TH from 'fp-ts/These';

const truncatedName: CSSProperties = {
  width: 190,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const renderScanResult = (result: ScanLocalResult, prices: Array<CalendarEventRegistrationPrice>) => {
  const getCategory = (result: ScanLocalResultSuccess | ScanLocalResultAlreadyScanned) =>
    pipe(
      O.fromNullable(result.result.priceId),
      O.chain(priceId =>
        pipe(
          prices,
          A.findFirst(({ id }) => id === priceId),
        ),
      ),
    );

  switch (result.status) {
    case ScanResultStatus.Success:
      return (
        <>
          <img src={successIcon} alt="Succès" />
          <Title level="h4" color="primary" colorKey={500} style={{ padding: '14px 0 19px' }}>
            Valide
          </Title>
          <Styled.EventDetailControlScanResultTicket>
            <div>
              <Paragraph size="medium" weight="medium" color="white">
                Numéro
              </Paragraph>
              <Paragraph size="large" weight="bold" color="white">
                N°{result.result.ticketCode}
              </Paragraph>
            </div>
            <div>
              <Paragraph size="medium" weight="medium" color="white">
                Prénom Nom
              </Paragraph>
              <Paragraph size="large" weight="bold" color="white" style={truncatedName}>
                {result.result.user.firstName} {result.result.user.lastName}
              </Paragraph>
            </div>
            <img src={successTicket} alt="Ticket" />
            {renderOptional(getCategory(result), ({ title }) => (
              <div>
                <Paragraph size="medium" weight="medium" color="white">
                  Catégorie
                </Paragraph>
                <Paragraph size="large" weight="bold" color="white" style={truncatedName}>
                  {title}
                </Paragraph>
              </div>
            ))}
          </Styled.EventDetailControlScanResultTicket>
        </>
      );
    case ScanResultStatus.AlreadyScanned:
      return (
        <>
          <img src={alreadyIcon} alt="Déjà Contrôlé" />
          <Title level="h4" style={{ padding: '14px 0 19px', color: '#fd8d00' }}>
            Déjà Contrôlé
          </Title>
          <Styled.EventDetailControlScanResultTicket>
            <div>
              <Paragraph size="medium" weight="medium" color="white">
                Numéro
              </Paragraph>
              <Paragraph size="large" weight="bold" color="white">
                N°{result.result.ticketCode}
              </Paragraph>
            </div>
            <div>
              <Paragraph size="medium" weight="medium" color="white">
                Prénom Nom
              </Paragraph>
              <Paragraph size="large" weight="bold" color="white" style={truncatedName}>
                {result.result.user.firstName} {result.result.user.lastName}
              </Paragraph>
            </div>
            <img src={alreadyTicket} alt="Ticket" />
            {renderOptional(getCategory(result), ({ title }) => (
              <div>
                <Paragraph size="medium" weight="medium" color="white">
                  Catégorie
                </Paragraph>
                <Paragraph size="large" weight="bold" color="white" style={truncatedName}>
                  {title}
                </Paragraph>
              </div>
            ))}
          </Styled.EventDetailControlScanResultTicket>
          {renderOptional(
            TH.fromOptions(
              parseAndFormatDate(result.result.scannedAt, DateFormat.LocalDateTime, 'dd/MM à HH:mm'),
              O.fromNullable(result.result.scannedBy),
            ),
            flow(
              TH.fold(
                date => `Le ${date}`,
                identity,
                (date, by) => `Le ${date} par ${by}`,
              ),
              content => (
                <Paragraph size="small" weight="semiBold" style={{ paddingTop: 5, color: '#fd8d00' }}>
                  {content}
                </Paragraph>
              ),
            ),
          )}
        </>
      );
    default:
      return (
        <>
          <img src={errorIcon} alt="Invalide" />
          <Title level="h4" color="secondary" colorKey={600} style={{ padding: '14px 0 19px' }}>
            Invalide
          </Title>
          <Styled.EventDetailControlScanResultTicket>
            <img src={errorTicket} alt="Ticket" />
            <Paragraph size="large" weight="bold" color="white" style={{ position: 'absolute', paddingRight: 15 }}>
              Invalide
            </Paragraph>
          </Styled.EventDetailControlScanResultTicket>
        </>
      );
  }
};

interface EventDetailControlScanResultParams {
  result: ScanLocalResult;
  handleReloadScan: IO.IO<void>;
}

export const EventDetailControlScanResult: FC<EventDetailControlScanResultParams> = ({ result, handleReloadScan }) => {
  const { summary } = useSummaryContext();

  return (
    <Styled.EventDetailControlScanResultContainer status={result.status} onClick={handleReloadScan}>
      {renderScanResult(result, summary.prices)}
      <Styled.EventDetailControlScanResultContinue style={{ marginTop: 42 }}>
        <Paragraph size="large" color="tertiary" colorKey={600}>
          Toucher l'écran pour scanner
        </Paragraph>
      </Styled.EventDetailControlScanResultContinue>
      <img src={touchIcon} alt="Scanner" style={{ marginTop: 22 }} />
    </Styled.EventDetailControlScanResultContainer>
  );
};

export default EventDetailControlScanResult;
