import styled from 'styled-components';
import {
  CalendarEventBookingTicketScanStatus,
  ticketScanStatusBackground,
  ticketScanStatusColor,
} from '@modules/events/model';
import { fontSize, fontWeight } from '@styles/utils';

export const EventDetailBookingTicketStatusContainer = styled.span<{ status: CalendarEventBookingTicketScanStatus }>`
  display: flex;
  align-items: center;
  width: fit-content;
  min-height: 24px;
  padding: 0 10px;
  border-radius: 4px;
  background-color: ${props => ticketScanStatusBackground[props.status](props)};
  color: ${props => ticketScanStatusColor[props.status](props)};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
`;
