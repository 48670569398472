import React, { FC } from 'react';
import * as Styled from './EventDetailControlScanPermissions.styles';
import { Button, Paragraph, Title } from '@styles/shared';
import * as IO from 'fp-ts/IO';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import { PermissionDisplay } from '@modules/events/components/detail/control/scan/web/EventDetailControlScanWeb';
import AnimatedIcon from '@modules/events/components/animated-icon/AnimatedIcon';
import deviceNotFoundAnimate from '@assets/animated/device-not-found.json';
import cameraPermissionAnimate from '@assets/animated/camera-permission.json';
import deniedPermissionAnimate from '@assets/animated/denied-permission.json';
import { ModalCloseButton } from '@shared/components/modal/Modal.styles';
import { usePlatform } from '@modules/descriptor/context';
import { CityMagPlatform } from '@shared/modules/platform/model';

interface EventDetailControlScanPermissionsProps {
  promptPermission: IO.IO<void>;
  onRequestClose: IO.IO<void>;
  permissionState: Exclude<PermissionDisplay, 'granted'>;
}

const EventDetailControlScanPermissions: FC<EventDetailControlScanPermissionsProps> = ({
  promptPermission,
  permissionState,
  onRequestClose,
}) => {
  const platform = usePlatform();
  const renderPermissionState = () => {
    switch (permissionState) {
      case 'not-found':
        return (
          <>
            <AnimatedIcon
              animationData={deviceNotFoundAnimate}
              settings={{ loop: false }}
              style={{ width: 157, margin: 'auto' }}
            />
            <Title level="h2" style={{ paddingTop: 30 }}>
              Appareil non compatible
            </Title>
            <Paragraph color="tertiary" colorKey={500} weight="bold" size="large" style={{ paddingTop: 40 }}>
              Veuillez utiliser un autre appareil pour scanner les billets
            </Paragraph>
            <Styled.EventDetailControlScanPermissionsSeparator color="tertiary" colorKey={600} uppercase>
              <span>ou</span>
            </Styled.EventDetailControlScanPermissionsSeparator>
            <Paragraph color="tertiary" colorKey={500} weight="bold" size="large">
              Utiliser le contrôle manuel depuis la liste ‘Réservations’.
            </Paragraph>
            <Button onClick={onRequestClose} style={{ marginTop: 20 }}>
              Ok
            </Button>
          </>
        );
      case 'prompt':
        return (
          <>
            <ModalCloseButton onClick={onRequestClose} style={{ top: 15, right: 19 }} />
            <AnimatedIcon
              animationData={cameraPermissionAnimate}
              settings={{ loop: false }}
              style={{ width: 200, margin: 'auto' }}
            />
            <Title level="h2" style={{ paddingTop: 10 }}>
              Autorisation de la caméra
            </Title>
            <Paragraph color="tertiary" colorKey={500} weight="bold" size="large" style={{ paddingTop: 40 }}>
              Pour scanner un E-billet il est nécessaire d’activer la caméra.
            </Paragraph>
            <Paragraph color="tertiary" colorKey={600} style={{ paddingTop: 8 }}>
              Merci d’accepter la demande d’autorisation.
            </Paragraph>
            <Button onClick={promptPermission} style={{ marginTop: 20 }}>
              Accepter
            </Button>
          </>
        );
      case 'denied':
        return (
          <>
            <AnimatedIcon
              animationData={deniedPermissionAnimate}
              settings={{ loop: false }}
              style={{ width: 175, margin: 'auto' }}
            />
            <Title level="h2" style={{ paddingTop: 20 }}>
              Impossible de démarrer la caméra
            </Title>
            <Paragraph color="tertiary" colorKey={500} weight="bold" size="large" style={{ paddingTop: 25 }}>
              L’autorisation nécessaire pour activer la caméra est refusée.
            </Paragraph>
            <Paragraph color="tertiary" colorKey={600} style={{ paddingTop: 10 }}>
              Merci de vous rendre dans les paramètres de votre{' '}
              {platform === CityMagPlatform.WEB ? 'navigateur' : 'application'} pour la modifier.
            </Paragraph>
            <Button onClick={onRequestClose} style={{ marginTop: 13 }}>
              Ok
            </Button>
          </>
        );
      case 'loading':
        return <DebouncedLineLoader />;
    }
  };
  return (
    <Styled.EventDetailControlScanPermissionsContainer>
      <div>{renderPermissionState()}</div>
    </Styled.EventDetailControlScanPermissionsContainer>
  );
};
export default EventDetailControlScanPermissions;
